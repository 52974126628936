<template>
  <BotChat v-show="!is_maintainence && is_shown"></BotChat>
</template>
<script>
import "./assets/style.scss";
import BotChat from "./components/BotChat.vue";
// import checkout from "./assets/checkout.js";
import axios from "axios";
import api_calls from "./api_calls";

export default {
  name: "app",
  components: {
    BotChat
  },
  data(){
    return {
      is_maintainence: true,
      is_shown: false
    };
  },
  beforeCreate(){
    axios.get(api_calls.maintainence_check_endpoint(), {
      params: {
        is_get: true
      }
    })
    .then((response) => {
      if(response.data.message.MSG_CODE === 10001421){
        this.is_maintainence = response.data.data.under_deployment;
        this.$root.$emit('maintainence', this.is_maintainence);
      } else {
        console.log(response.data.message.MSG);
      }
    }).catch(()=> {
      this.is_maintainence = true;
    })
  },
  mounted(){
    this.$root.$on("bot_shown_value", (data) => {
      this.is_shown = data;
    })
  }
};
</script>

<style>
</style>
