<template>
  <div v-show="chat_widget.bot_shown_on_website && show_bot && !hide_bot">
    <div class="chat-master" id="cense_app" v-if="show_widget && !hide_bot" :style="cssProps">
      <div
        ref="chat-wrap"
        class="chat-wrap"
        :class="chat_widget.is_wider_view?'wider_view':'narrow_view'"
        v-show="load_chat_window && show_widget && bot_channel_type.supported_channel_type == 'website'"
        :style="chat_background_image_and_widget_position"
      >
      <div class="spinner" v-if="spinnerOn"></div>
  
        <div class="chat-head">
          <div  class="media">
            <img
              :src="chat_widget.logo"
              :style="chat_widget.style"
              class="rounded img-responsive mx-auto"
              alt="..."
            />
            <div class="close_bot_widget"  @click="load_widget('close')">
            </div>
          </div>
        </div>
        <div
          style="position: absolute; z-index: 1000; top: 80%; left: 90%; cursor: pointer;"
          id="to_scroll"
          class="scroll_bounce"
          @click="animate_chat_body_scroll(1, 50)"
        >
          <img v-if="to_scroll" />
        </div>
         <div v-if="live_chat_on" class="live-agent-window fadeInDown">
            <div class="d-flex">
              <img src="@/assets/images/user-blue.png" alt="">
              <span class="bot-font-style" v-if="support_agent_info" style="margin-top: 14px;">Agent {{support_agent_info.first_name}} is online</span>
              <span class="bot-font-style" v-else style="margin-top: 14px;">Connecting to our Support Agent ...</span>
            </div>
          </div>
        <div
          id="widget_chat_body_id"
          class="chat-body"
          @scroll="scroll_down"
        >
          <div
            class="chat-inner"
            v-for="(single, item) in chat"
            :key="item"
            :id="'chat' + item"
          >
            <div
              class="buy-products"
              style="display: flex; overflow: auto hidden"
              @mousewheel="scroll_div"
              v-if="single.isproduct"
            >
              <div
                class="image-container"
                v-for="(product, index) in single.elements.products"
                :key="index"
              >
                <img :src="product['Product Image Url']" class="image-box" />
                <p>{{ product["Product Name"] }}</p>
                <p>{{ product["UnitPrice"] }}</p>
                <p
                  v-if="single.isshopify && product.available == false"
                  style="
                    font-weight: 800;
                    color: #000;
                    font-size: 12px;
                    margin-top: -5%;
                  "
                >
                  Sold Out
                </p>
                <p
                  style="
                    font-weight: 800;
                    color: #283879;
                    font-size: 12px;
                    cursor: pointer;
                    margin-top: -5%;
                  "
                  @click="generate_payment(product, product.price)"
                  v-if="single.isshopify && product.available == true"
                >
                  Add to Cart
                </p>
                <p
                  style="
                    font-weight: 800;
                    color: #283879;
                    font-size: 12px;
                    cursor: pointer;
                    margin-top: -5%;
                  "
                  click="generate_payment(product, product.price)"
                  v-if="!single.isshopify"
                >
                  Buy Now
                </p>
              </div>
            </div>
            <div
              :class="Boolean(single.products_list.length > 1)?'d-flex':''"
              v-if="single.new_products && show_custom_cart"
            >
              <button 
                class="btn mr-2 product_scroll_btn"
                style="border-radius:35px 0px 0px 35px !important"
                @click="scroll_div_left(item)"
                v-if="Boolean(single.products_list.length > 1)"
              >
                <span class="product_scroll_btn_icon">
                  <svg 
                    style="height: 22px !important;width: 22px !important;"
                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-left" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
                  </svg>
                </span>
              </button>
              <div
                class="buy-products d-flex"
                :id="'buy_product_id_' + item"
                style="overflow: auto hidden; height: auto;"
                @mousewheel="scroll_div1(item)"
              >
                <div
                  class="image-container-woocommerce"
                  v-for="(product, index) in single.products_list"
                  :key="index"
                  :style="Boolean(single.products_list.length > 1)? '':'margin-left:8% !important'"
                ><a :href="product.permalink" target="_blank">
                  <img v-if="product.img_url != null" :src="product.img_url" class="image-box-woocommerce" alt="Product Image" />
                  <img v-else src="https://storage.cense.ai/codearray/blank_product_img.png" class="image-box-woocommerce" style="background-color: white; text-align: center;" alt="Product Image" />
                </a>
                <div class="product-title-woocommerce">
                      <p class="textMaxLine bot-font-style m-0" style="min-height: 36px;" :title="product.title">{{ product.title }}</p>
                      <p class="product-variant-title bot-font-style" v-if="product.variant_title" :title="product.variant_title">{{ product.variant_title }}</p>
                </div>
                  <div class="d-flex" style="justify-content: center;">
                  <p class="product-price-woocommerce bot-font-style mt-0">{{retail_shop_currency}} {{parseInt(product.price).toLocaleString()}}.00</p>
                  <p v-if="product.price != product.regular_price && (product.regular_price != '' && product.regular_price != null)" class="product-price-woocommerce bot-font-style mt-0" 
                        style="font-size: 10px;
                              text-decoration: line-through;
                              padding-left: 5px;
                              padding-top: 2px;
                              opacity: 1;">
                        {{retail_shop_currency}} {{parseInt(product.regular_price).toLocaleString()}}.00</p>
                  </div>
                  <div class="bot-font-style" v-if="product.order_qty === 2  && product.stock_quantity != null" style="color: red; font-size: 12px;">
                    Maximum limit {{available_qty(product.stock_quantity, product.id)}}
                  </div>
                  <div v-if="Boolean(custom_product_button_key.length)" class="product-variation" style="position: relative;" >
                    <div v-for="(product_button, index) in custom_product_button_key"
                      :key="index">
                    <select class="select-product-variation bot-font-style" 
                      v-model="product.variant_title" 
                      @change="update_variation(item, index, product.variant_title)"
                      :disabled="product_button.on_value == product[product_button.on_key]"
                      :style="product_button.on_value == product[product_button.on_key] ? 'background: #dddddd; cursor: not-allowed;' : ''"
                    >
                      <option v-for="(variation, var_index) in product.variations"
                      :key="var_index"
                      :value="variation.variant_title">{{variation.variant_title}}</option>
                    </select>
                    <svg class="select-icon" data-element="option.selectIcon" viewBox="0 0 24 24"><path d="M21 5.176l-9.086 9.353L3 5.176.686 7.647 12 19.382 23.314 7.647 21 5.176z"></path></svg>
                    </div>
                  </div>
                  <div v-else class="product-variation" style="position: relative;" >
                    <select class="select-product-variation bot-font-style" 
                      v-model="product.variant_title" 
                      @change="update_variation(item, index, product.variant_title)"
                      :disabled="!product.variations || product.variations.length <= 1"
                      :style="!product.variations || product.variations.length <= 1 ? 'background: #dddddd; cursor: not-allowed;' : ''"
                    >
                      <option v-for="(variation, var_index) in product.variations"
                      :key="var_index"
                      :value="variation.variant_title">{{variation.variant_title}}</option>
                    </select>
                    <svg class="select-icon" data-element="option.selectIcon" viewBox="0 0 24 24"><path d="M21 5.176l-9.086 9.353L3 5.176.686 7.647 12 19.382 23.314 7.647 21 5.176z"></path></svg>
                  </div>
                  <div v-if="Boolean(custom_product_button_key.length)">
                    <div class="product-qty" v-for="(product_button, index) in custom_product_button_key"
                      :key="index">
                      <button
                        type="button"
                        class="quantity-btn minus"
                        :disabled="product_button.on_value == product[product_button.on_key]"
                        :style="product_button.on_value == product[product_button.on_key] ? 'background: #dddddd; cursor: not-allowed;' : ''"
                        @click="update_quantity('-', item, index, product)"
                      >
                      <span>-</span>
                      </button>
                      <input
                        type="number"
                        min="1"
                        class="bot-font-style quantity-btn"
                        style="
                          text-align: center; 
                          width: 45px;
                          background-color: #ffffff; 
                          border-right: 0; 
                          border-left: 0;"
                        v-model.number="product.buy_qty"
                        :style="product_button.on_value == product[product_button.on_key] ? 'background: #dddddd; cursor: not-allowed;' : ''"
                        @focusout="update_quantity('manual', item, index, product)"
                        :disabled="product_button.on_value == product[product_button.on_key]"
                        name="quantity"
                        title="Qty"
                      />
                      <button
                        type="button"
                        class="quantity-btn plus"
                        @click="update_quantity('+', item, index, product)"
                        :disabled="product_button.on_value == product[product_button.on_key]"
                        :style="product_button.on_value == product[product_button.on_key] ? 'background: #dddddd; cursor: not-allowed;' : ''"
                      >
                      <span>+</span>
                      </button>
                    </div>
                  </div>
                  <div v-else class="product-qty">
                    <button
                      type="button"
                      class="quantity-btn minus"
                      @click="update_quantity('-', item, index, product)"
                    >
                    <span>-</span>
                    </button>
                    <input
                      type="number"
                      min="1"
                      class="bot-font-style quantity-btn"
                      style="
                        text-align: center; 
                        width: 45px;
                        background-color: #ffffff; 
                        border-right: 0; 
                        border-left: 0;"
                      v-model.number="product.buy_qty"
                      @focusout="update_quantity('manual', item, index, product)"
                      name="quantity"
                      title="Qty"
                    />
                    <button
                      type="button"
                      class="quantity-btn plus"
                      @click="update_quantity('+', item, index, product)"
                    >
                    <span>+</span>
                    </button>
                  </div>
                  <div v-if="Boolean(custom_product_button_key.length)">
                    <div v-for="(product_button, index) in custom_product_button_key"
                      :key="index">
                      <a v-if="product_button.function == 'permalink' && product_button.on_value == product[product_button.on_key]" 
                        :href="product.permalink" target="_blank">
                      <div 
                        class="btn add-to-cart"
                        style="
                          font-size: 13px; 
                          padding: 12px 0px; 
                          line-height: normal; 
                          box-shadow: none;
                          width: 100% !important;"
                      >
                        {{product_button.name}}
                      </div>
                      </a>
                      <div 
                        class="btn add-to-cart"
                        v-else-if="product.stock_status != 'outofstock'"
                        style="
                          font-size: 13px; 
                          padding: 12px 0px; 
                          line-height: normal; 
                          box-shadow: none;
                          width: 100% !important;"
                        @click="
                          addproduct(product)
                        "
                      >
                        ADD TO CART
                      </div>
                      <div
                        v-else
                        class="btn add-to-cart"
                        style="
                          font-size: 13px; 
                          padding: 12px 0px; 
                          line-height: normal; 
                          box-shadow: none;
                          width: 100% !important;
                          cursor: not-allowed;"
                        disabled
                      >
                        OUT OF STOCK
                      </div>
                    </div>
                  </div>
                  <div 
                    class="btn add-to-cart"
                    v-else-if="product.stock_status != 'outofstock'"
                    style="
                      font-size: 13px; 
                      padding: 12px 0px; 
                      line-height: normal; 
                      box-shadow: none;"
                    @click="
                      addproduct(product)
                    "
                  >
                    ADD TO CART
                  </div>
                  <div
                    v-else
                    class="btn add-to-cart"
                    style="
                      font-size: 13px; 
                      padding: 12px 0px; 
                      line-height: normal; 
                      box-shadow: none;
                      cursor: not-allowed;"
                    disabled
                  >
                    OUT OF STOCK
                  </div>
                </div>
              </div>
              <button 
                style="border-radius:0px 35px 35px 0px !important;"
                class="btn ml-2 product_scroll_btn"
                @click="scroll_div_right(item)"
                v-if="Boolean(single.products_list.length > 1)"
              >
                <span class="product_scroll_btn_icon">
                  <svg 
                    style="height: 22px !important;width: 22px !important;"
                    xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                    <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </span>
              </button>
            </div>
            <div
              class="buy-products d-flex"
              style="overflow: auto hidden; height: auto"
              @mousewheel="scroll_div"
              v-else-if="Boolean(single.is_products_list)"
            >
              <div
                :id="`product_${product_item.id}_`+item"
                v-for="(product_item, index) in single.products_list"
                :key="index"
                class="m-3"
                style="min-width: 200px"
              ></div>
            </div>
            <div class="chat-row receiver" v-if="single.product_out_of_stock">
              <div class="d-flex" style="position: relative;">
                <div class="bot-img" :style="bot_img_icon"></div>
                  <div
                    class="chat bot-font-receiver"
                  >
                    Some products you searched for are out of stock, if you want to be notified via email when they are back in stock,
                    <a
                      href="#"
                      style="cursor: pointer;color: #283677 !important"
                      @click="add_to_out_of_stock_list(single.product_out_of_stock_list)"
                      data-toggle="modal"
                      data-target="#notifyemail"
                      >
                      click here
                    </a>            
                </div>
              </div>
            </div>
            <div class="chat-row receiver" v-if="single.show_banner_img_url">
              <div class="d-flex" style="position: relative;">
                <div v-if="single.show_bot_img" class="bot-img" :style="bot_img_icon"></div>
                <br />
                <a 
                  style="width: -webkit-fill-available !important;" 
                  :href="single.banner_img_link ? single.banner_img_link: 'javascript:void(0);'"
                  :target="single.banner_img_link ? '_blank' : ''"
                >
                  <img
                    :src="single.banner_img_url"
                    :class="(Boolean(single.show_bot_img) == true)?'banner_img_url_id':'banner_img_url_only_id'"
                  />
                </a>
              </div>
                <div class="chat-his" v-if="single.time != null">
                <span class="time" style="color: #000">{{ single.time }}</span>
              </div>
            </div>
            <div class="chat-row receiver" v-if="single.show_banner_description">
              <div class="d-flex" style="position: relative;">
                <div v-if="single.show_bot_img" class="bot-img" :style="bot_img_icon"></div>
                <div
                  class="chat bot-font-receiver"
                  :style="(Boolean(single.show_bot_img) == true) ? '' : 'margin-left: 8% !important;'"
                  v-html="single.banner_description"
                >
                  {{ single.banner_description }}
                </div>
              </div>
                <div class="chat-his" v-if="single.time != null">
                <span class="time" style="color: #000">{{ single.time }}</span>
              </div>
            </div>
            <div class="chat-row receiver" v-if="single.show_banner_video_url">
              <div class="d-flex" style="position: relative;">
                <div v-if="single.show_bot_img" class="bot-img" :style="bot_img_icon"></div>
                <br />
                <div
                  class="plyr__video-embed"
                  id="player"
                >
                  <iframe
                    :src="single.banner_video_url"
                    :class="(Boolean(single.show_bot_img) == true )?'banner_video_url_id':'banner_video_url_only_id'"
                  ></iframe>
                </div>
              </div>
                <div class="chat-his" v-if="single.time != null">
                <span class="time" style="color: #000">{{ single.time }}</span>
              </div>
            </div>
            <div class="chat-row receiver" v-if="single.is_review_rating_product">
              <div class="d-flex" style="position: relative;">
                <div class="bot-img" :style="bot_img_icon"></div>
                <div 
                  class="p-3 my-3 chat"
                  style="box-sizing: border-box !important;"
                >
                  <div class="col-sm-12 d-flex p-0">
                    <div class="col-sm-8 p-0">
                      <div class="bot-font-receiver" style="width: 100% !important;">
                        <label style="text-align: justify !important;" ><strong><b> {{single.not_reviewed_product_details.name}}</b></strong></label>
                        <div
                          style="
                            width: 100% !important;
                            text-align: justify !important;"
                        >
                          {{single.review_message_first}}
                        </div>
                        <div
                          style="
                            width: 100% !important;
                            text-align: justify !important;"
                        >
                          {{single.review_message_second}}
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-4 pl-2 pr-0">
                      <div style="text-align: end !important; contain: content !important;">
                        <img :src="single.product_review_img_url" class="img-fluid" />
                      </div>
                    </div>
                  </div>
                  <div class="card p-3 my-2">
                    <div 
                      class="card-body bot-font-receiver p-0" 
                      style="min-height: 10vh !important;"
                    >
                      <form @submit.prevent="submit_review_rating(item)">
                        <div class="d-flex flex-wrap">
                          <label class="mr-4 my-auto"><strong><b>Rating:</b></strong></label>
                          <star-rating 
                            v-model="single.product_rating"
                            v-bind:star-size="17"
                            :showRating= false
                          >
                          </star-rating>
                        </div>
                        <br/>
                        <label><strong><b>Review:</b></strong></label>
                        <textarea
                          required
                          rows="3"
                          v-model.trim="single.product_review"
                          class="form-control" 
                          type="text"
                          style="border: 1px solid #ced4da;
                            border-radius: .25rem; fon-size: 14px !important;
                            padding: 5px !important; resize: none !important;
                            width:100% !important; height:auto !important;
                            box-sizing: border-box !important;"
                        />
                        <hr>
                        <div class="d-block d-flex flex-wrap buttons-parent buttons-alignment mt-1">
                          <button 
                            class="btn custom-buttons bot-font-buttons mt-3"
                            style="text-align: center !important; 
                              font-size: 14px !important;
                              font-family: inherit !important;"  
                            type="submit"
                          >
                          <strong>Submit Your Review</strong> 
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
              <div class="chat-his" v-if="single.time != null">
                <span class="time" style="color: #000">{{ single.time }}</span>
              </div>
            </div>
            <div class="chat-row receiver" v-if="single.show_messaging_platforms">
              <div v-if="single.show_messaging_platforms" class="d-flex" style="justify-content: center;">
                <div class="px-2" v-for="(plt,plt_index) in single.messaging_platforms_data" :key="plt_index">
                  <div v-if="Boolean(plt.TELEGRAM) === true">
                    <span @click="open_messaging_framework_integration(plt.TELEGRAM)">
                    <img :src="'https://storage.cense.ai/codearray/Telegram_logo.png'" alt="Telegram" class="image-box-msg-frmw" style="cursor:pointer;background: inherit;">
                    </span>
                  </div>
                  <div v-if="Boolean(plt.WHATSAPP) === true">
                    <span @click="open_messaging_framework_integration(plt.WHATSAPP)">
                    <img :src="'https://storage.cense.ai/codearray/whatsapp-logo.png'" alt="WhatsApp" class="image-box-msg-frmw" style="cursor:pointer;background: inherit;">
                    </span>
                  </div>
                  <div v-if="Boolean(plt.FACEBOOK) === true">
                    <span @click="open_messaging_framework_integration(plt.FACEBOOK)">
                    <img :src="'https://storage.cense.ai/codearray/logo-256.png'" alt="Facebook" class="image-box-msg-frmw" style="cursor:pointer;background: inherit;">
                    </span>
                  </div>
                  <div v-if="Boolean(plt.INSTAGRAM) === true">
                    <span @click="open_messaging_framework_integration(plt.INSTAGRAM)">
                    <img :src="'https://storage.cense.ai/codearray/instagram_logo.png'" alt="Instagram" class="image-box-msg-frmw" style="cursor:pointer;background: inherit;">
                    </span>
                  </div>
                </div>
              </div>
              <div class="chat-his" v-if="single.time != null">
                <span class="time" style="color: #000">{{ single.time }}</span>
              </div>
            </div>
            <div
              class="chat-row sender"
              v-if="single.sending && single.conversation_only"
            >
              <div class="chat bot-font-sender">{{ single.sent }}
                      <!-- <div  class="speaker-receive speaker" 
                       >
                        <img   @click="pauseAudio(item , 'widgetTextStop' , 'widgetTextPlay')" :id="'widgetTextStop' + item" class="stop" src="@/assets/images/stop_white.png" style="width:16px;display:none ;   margin-top: 3px;margin-right:5px" alt="speaker">
                        <img  :id="'widgetTextPlay' + item"  @click="text_to_speech(single.sent , item , 'widgetTextStop' , 'widgetTextPlay','widgetTextAudio')" class="play" src="@/assets/images/speaker.png" style="width:16px;   margin-top: 3px;margin-right:5px" alt="speaker">
                      </div> -->
                      <audio ref="audio" class="audio" :id="'widgetTextAudio' + item" @ended="audioEnded(item , 'widgetTextAudio','widgetTextStop', 'widgetTextPlay')"></audio>
              <div class="chat-his" style="left: 15px !important; right: 0px !important; bottom: -1px !important;">
                <span class="time" style="color: #FFF !important">{{ single.time }}</span>
                <!-- <span class="del-note delivered">Sent</span> -->
              </div>
              </div>
            </div>
            <div
              class="chat-row receiver"
              v-if="single.receiving && single.conversation_only"
              :class="{ 'pr-0': single.fetch_shopify_details }"
            >
              <div class="d-flex" style="position: relative;">
                <div v-if="!(Boolean(single.received) == false &&
                    (Boolean(single.image) == true ||
                    Boolean(single.custom_buttons) == true ||
                    Boolean(single.videos) == true))"
                    class="bot-img" :style="bot_img_icon">
                </div>
                <div
                  class="chat bot-font-receiver"
                  v-if="single.is_custom_form == null && Boolean(single.received) == true"
                >
                <div  :id="'recieved'+item" style="white-space:pre-line" v-html="single.received"></div>
                  <!-- <div  class="speaker-receive speaker" 
                       >
                        <img   @click="pauseAudio(item , 'widgetTextStop' , 'widgetTextPlay')" :id="'widgetTextStop' + item" class="stop" src="@/assets/images/stop_blue.png" style="width:16px;display:none ;   margin-top: 3px;" alt="speaker">
                        <img  :id="'widgetTextPlay' + item"  @click="text_to_speech(single.received , item , 'widgetTextStop' , 'widgetTextPlay','widgetTextAudio')" class="play" src="@/assets/images/speaker_blue.png" style="width:16px" alt="speaker">
                      </div> -->
                      <audio ref="audio" class="audio" :id="'widgetTextAudio' + item" @ended="audioEnded(item , 'widgetTextAudio','widgetTextStop', 'widgetTextPlay')"></audio>
                  <div class="chat-his" v-if="single.time != null">
                      <span class="time">{{ single.time }}</span>
                  </div>
                </div>
                <div
                  class="chat bot-font-receiver"
                  v-else-if="single.is_custom_form == true && Boolean(single.received) == true"
                  style="background: none !important"
                  v-html="single.received"
                ></div>
              </div>
              <template
                v-if="
                  single.fetch_shopify_details &&
                  !retail_is_token_valid(item, single.is_refund)
                "
              >
                <form
                  id="shopify_login_form"
                  enctype="multipart/form-data"
                  @submit.prevent="
                    retail_create_customer_token(item, single.is_refund)
                  "
                >
                  <div class="form-group">
                    <input
                      :placeholder="retail_order_retreival_only_email_required ? 'Enter your Email or Phone number' : 'Enter your Email'"
                      name="email"
                      class="form-control"
                      id="email"
                      v-model="single.customer_email_id"
                      required
                      type="text"
                      @focus="retail_form_focus(item)"
                    />
                  </div>
                  <div class="form-group" v-if="!retail_order_retreival_only_email_required">
                    <input
                      placeholder="Enter your Password"
                      name="password"
                      class="form-control"
                      v-model="single.customer_password"
                      id="password"
                      required
                      type="password"
                      @focus="retail_form_focus(item)"
                    />
                  </div>
                  <button type="submit" class="btn btn-primary bot-font-buttons dynamic-button" style="font-size: initial;">
                    Login
                  </button>
                </form>
                <!-- <div v-else class="bot-font-receiver">
                  {{change_chat_text_to_login_redirect(item)}}
                </div> -->
              </template>
              <div
                v-if="single.refund_orders_list"
                style="
                  background: #ffffff;
                  text-align: center;
                  margin: 10px 0px;
                  padding: 10px;
                  font-size: initial;
                  font-weight: initial;
                  letter-spacing: initial;
                "
              >
                <div
                  v-if="!single.refund_order_view_details.line_items"
                  style="display: grid"
                >
                <div>
  
                </div>
                <div
                    class="d-flex bot-font-receiver"
                  >
                     <p
                      style="font-weight:500; margin: 0;"
                      >Order#</p>
                    <p style="font-weight:500; margin: 0;">Order Date</p>
                    <p style="font-weight:500; margin: 0;">Order Status</p>
                    <p
                      v-if="retail_web_framework != 'shopify'" 
                      style="font-weight:500; margin: 0;">Notes</p>
                  </div>
                  <div
                    v-for="(order, orderIndex) in single.refund_orders_list"
                    :key="orderIndex"
                    class="d-flex bot-font-receiver"
                  >
                    <a
                      class="bot-font-receiver"
                      href="javascript:void(0)"
                      @click="
                        view_order_details(
                          item,
                          orderIndex,
                          order.line_items,
                          order.refunds,
                        )
                      "
                      >{{ order.name }}</a
                    >
                    <a 
                      class="bot-font-receiver"
                      href="javascript:void(0)"
                      @click="
                        view_order_details(
                          item,
                          orderIndex,
                          order.line_items,
                          order.refunds,
                        )
                      "
                      >{{ order.created_at }}</a>
                    <a 
                      class="bot-font-receiver"
                      href="javascript:void(0)"
                      @click="
                        view_order_details(
                          item,
                          orderIndex,
                          order.line_items,
                          order.refunds,
                        )
                      "
                    >{{ order.fulfillment_status }}</a>
                    <a
                      class="bot-font-receiver"
                      v-if="retail_web_framework != 'shopify'"
                      href="#"
                      @click="order_notes_data = order.order_notes; selected_order_name = order.name"
                      data-toggle="modal"
                      data-target="#ordernotesmodel"
                      title="View Notes"
                    >View Notes</a>
                  </div>
                </div>
                <div v-else-if="single.refund_order_view_details.line_items"
                class="bot-font-receiver">
                  <div class="mb-3 text-left">
                    <a
                      class="bot-font-receiver"
                      href="javascript:void(0)"
                      @click="refresh_or_remove_order_details(item, 'remove')"
                      >&lt;&lt; Back to Orders</a
                    >
                    <a href="javascript:void(0)" class="bot-font-receiver ml-2">{{
                      single.refund_order_view_details.name
                    }}</a>
                  </div>
                  <div>
                    <p class="w-auto text-left">
                      To Return, follow these steps: 
                      <ol>
                        <li>
                          Select the checkbox for the items to be returned
                        </li>
                        <li>
                          Update the quantity
                        </li>
                        <li>
                          Click on Proceed button
                        </li>
                      </ol>
                    </p>
                  </div>
                  <div
                    v-for="(
                      lineItem, lineItemIndex
                    ) in single.refund_order_view_details.line_items"
                    :key="lineItemIndex"
                    class="mb-0 shopify-return-line-items"
                    style="text-align: left;"
                  >
                    <div class="col-sm-12 p-0 px-2" style="display: inline-flex !important; width: 100%;">
                      <div class="col-sm-1 p-0 pl-2">
                        <div class="form-check checkbox checkbox-success">
                        <label class="form-check-label"
                          :title="((lineItem.qty_available_for_refund) <= 0) ?'No product quantity available for refund':null"
                          :style="((lineItem.qty_available_for_refund) <= 0) ?'cursor: not-allowed !important;':'cursor: pointer !important;'"
                        >
                        <input
                          type="checkbox"
                          v-model="single.refund_selected_items"
                          :value="retail_web_framework == 'magento' ? lineItem.product_id : lineItem.id"
                          style="display:none;position: inherit; margin: 0; box-sizing: border-box;padding: 0;"
                          :disabled="(lineItem.qty_available_for_refund) <= 0"
                        />
                        <span class="icn" 
                                  style="
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    width: 18px;
                                    height: 18px;
                                    border-radius: 3px;
                                    background: #fff;
                                    display: inline-block;
                                    width: 18px;
                                    height: 18px;
                                    border: solid 1px #283677;" >
                                    </span>
                              </label>
                        </div>
                      </div>
                      <div class="col-sm-5 p-0 pl-2">
                        <p class="bot-font-receiver" style="width: 100% !important;text-align: center !important;">
                          {{ lineItem.name }}
                        </p>
                      </div>
                      <div class="col-sm-3 p-0 pl-2" style="flex: content !important;">
                        <p class="bot-font-receiver" style="width: 100% !important;">
                          {{ single.refund_order_view_details.presentment_currency }}
                          {{ lineItem.price }}
                        </p>
                      </div>
                      <div class="col-sm-3 p-0 pl-2" style="flex: content !important;">
                        <div class="">
                          <template>
                            <div class="input-group margin-bottom">
                              <vue-numeric-input
                                :title="((lineItem.qty_available_for_refund) <= 0) ?'No product quantity available for refund':null"
                                id="return_increment_numeric_input"
                                style="width: fit-content !important;"  
                                v-model.number="lineItem.selected_quantity" 
                                :min="1" 
                                :max="lineItem.qty_available_for_refund" 
                                :step="1"
                                controls-type="updown"
                                :disabled="(lineItem.qty_available_for_refund) <= 0"
                                :style="((lineItem.qty_available_for_refund) <= 0) ?'cursor: not-allowed !important':null"
                              >
                                <span class="input-group-addon addon-inline input-source-observer">days</span>
                              </vue-numeric-input>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 p-0 px-2 d-block d-flex shopify-return-line-items"> 
                    <div class="col-sm-5 p-0 bot-font-receiver">
                      <label class="bot-font-receiver text-left">
                        Reason for Return*
                      <br>
                      <sup v-if="show_shopify_reason_for_refund_error" style="color:red">
                        ( This cannot be empty!!)
                      </sup>
                      </label>
                    </div> 
                    <div class="col-sm-7 p-0">
                      <div class="form-group">
                        <multiselect
                          v-model="single.shopify_reason_for_refund_list"
                          class="bot-font-receiver"
                          id="shopify_reason_for_refund_id"
                          :options="shopify_reason_for_refund_option"
                          :searchable="true"
                          :close-on-select="true"
                          :show-labels="false"
                          @input="refresh_or_remove_order_details(item)"
                          placeholder="Select Reason"
                          :max-height="100"
                        >
                        </multiselect>
                      </div>
                      <div class="bot-font-receiver" style="display: grid !important;" v-if="single.shopify_reason_for_refund_list === 'Others'">
                        <textarea
                          class="form-group"
                          style="border: 1px solid #ced4da;
                          border-radius: .25rem; fon-size: 16px;
                          padding: 5px !important; resize: none !important;
                          color: #495057 !important;
                          outline-color: rgb(0 123 255 / 25%)!important;"
                          rows="3"
                          type= 'text'
                          v-model.trim="single.shopify_reason_for_refund"
                          id="product_refund_txt_area"
                        />
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-block d-flex flex-wrap buttons-parent buttons-alignment mt-1"
                  >
                    <button
                      :title="((single.refund_selected_items.length) <= 0) ?'Select the checkbox for the items to be returned':null"
                      class="btn custom-buttons bot-font-buttons"
                      :disabled="(single.refund_selected_items.length) <= 0"
                      :style="((single.refund_selected_items.length) <= 0) ?'cursor: not-allowed !important':null"
                      style="font-size: 16px !important;"
                      @click="
                        submit_refund_request(
                          single.refund_order_view_details, item
                        )
                      "
                    >
                      Proceed
                    </button>
                  </div>
                </div>
              </div>
              <div
                class="bot-font-receiver"
                v-if="single.show_all_orders_list"
                style="
                  background: #ffffff;
                  text-align: center;
                  margin: 10px 0px;
                  padding: 10px;
                  font-size: initial;
                "
              >
              <div
                class="d-flex"
              >
                <p
                  style="font-weight:500; margin: 0;"
                  >Order#</p
                >
                <p style="font-weight:500; margin: 0;">Order Date</p>
                <p style="font-weight:500; margin: 0;">Order Status</p>
                <p v-if="retail_web_framework != 'shopify'" style="font-weight:500; margin: 0;">Notes</p>
              </div>
              <div
                  v-for="(order, orderIndex) in single.all_orders_list"
                  :key="orderIndex"
                  class="d-flex"
                >
                  <a
                    class="bot-font-buttons"
                    :href="order.status_url"
                    target="_blank"
                  >{{ order.name }}</a
                  >
                  <a
                    class="bot-font-buttons" 
                    :href="order.status_url"
                    target="_blank"
                  >{{ order.processed_date }}</a>
                  <a
                    class="bot-font-buttons" 
                    :href="order.status_url"
                    target="_blank"
                  >{{ order.fulfillment_status }}</a>
                  <a
                    class="bot-font-buttons"
                    v-if="retail_web_framework != 'shopify'"
                    href="#"
                    @click="order_notes_data = order.order_notes; selected_order_name = order.name"
                    data-toggle="modal"
                    data-target="#ordernotesmodel"
                    title="View Notes"
                  >View Notes</a>
                </div>
              </div>
              <template v-if="Boolean(single.image)">
                <div class="d-flex" style="position: relative;">
                  <div v-if="(Boolean(single.received) == false &&
                        Boolean(single.image) == true)" class="bot-img" :style="bot_img_icon"></div>
                  <br />
                  <img
                    :class="!(Boolean(single.received) == false &&
                    Boolean(single.image) == true)? 'img_only_id':'img_id'"
                    :src="single.image"
                  />
                </div>
              </template>
              <div class="chat-row receiver" v-if="single.show_buttons">
                <button
                  class="btn custom-buttons"
                  @click="call_support('Yes')"
                  style="background: #1daae1; font-size: 13px"
                >
                  Yes
                </button>
                <button
                  class="btn custom-buttons"
                  @click="call_support('No')"
                  style="
                    background: #1daae1;
                    font-size: 13px;
                    margin-left: 2%;
                  "
                >
                  No
                </button>
              </div>
              <div
                v-if="single.custom_buttons"
              >
                <div :class="(Boolean(single.received) == false &&
                      Boolean(single.custom_buttons) == true)?'d-flex':null" style="align-items: center; position: relative;">
                  <div v-if="(Boolean(single.received) == false &&
                    Boolean(single.custom_buttons) == true)" class="bot-img" :style="bot_img_icon">
                  </div>
                  <div
                    class="d-block d-flex flex-wrap buttons-parent buttons-alignment mt-1"
                    :style="(Boolean(single.received) == false &&
                    Boolean(single.custom_buttons) == true)?'width: -webkit-fill-available !important;margin-left:30px;': null"
                  >
                    <button
                      :style="(Boolean(single.received) == false &&
                      Boolean(single.custom_buttons) == true)?'margin-top:1% !important; margin-bottom:2% !important': null"
                      class="btn custom-buttons"
                      @click="custom_button_click(button, item)"
                      v-for="(button, index) in single.buttons_list"
                      :disabled="input_disabled"
                      :key="index"
                    >
                      {{ button.title }}
                    </button>
                  </div>
                </div>
              </div>
              <div
                v-if="Boolean(single.is_order_status)"
                class="chat"
                style="margin-left: 33px; margin-top: 10px"
                v-html="single.order_status"
              ></div>
                <div v-if="Boolean(single.offers)" class="d-flex" style="position: relative;">
                      <div class="bot-img" :style="bot_img_icon"></div>
                <div
                  v-if="Boolean(single.offers)"
                  class="chat bot-font-receiver"
                  style="margin-top: 10px; position: relative;"
                  >
                  <span
                          v-for="(offer, offerIndex) in single.offers"
                          :key="offerIndex"
                        >
                        <span><strong style="padding-right:0.25rem">{{offerIndex+1}}:</strong></span>
                        <span v-if="offer.offer != null" v-html="offer.offer +'<br>' " style="margin-bottom: 1rem"></span>
                        <!-- <div  class="speaker-receive speaker" 
                       >
                        <img   @click="pauseAudio(offerIndex , 'offerStop' , 'offerPlay')" :id="'offerStop' + offerIndex" class="stop" src="@/assets/images/stop_blue.png" style="width:16px;display:none ;   margin-top: 3px;" alt="speaker">
                        <img  :id="'offerPlay' + offerIndex"  @click="text_to_speech(offer.offer , offerIndex , 'offerStop' , 'offerPlay' , 'offerAudio')" class="play" src="@/assets/images/speaker_blue.png" style="width:16px" alt="speaker">
                      </div> -->
                      <audio ref="audio" class="audio" :id="'offerAudio' + offerIndex" @ended="audioEnded(offerIndex , 'offerAudio', 'offerStop' , 'offerPlay')"></audio>
                        </span>
                  <div class="chat-his" v-if="single.time != null">
                      <span class="time">{{ single.time }}</span>
                  </div>
                </div>
              </div>
              <template v-if="Boolean(single.videos)">
                <div class="d-flex" style="position: relative;">
                  <div v-if="(Boolean(single.received) == false &&
                    Boolean(single.videos) == true)" class="bot-img" :style="bot_img_icon"></div>
                  <br />
                  <div
                    class="plyr__video-embed"
                    id="player"
                    style="ma"
                  >
                    <iframe
                      :src="single.videos"
                      allowfullscreen
                      allowtransparency
                      allow="autoplay"
                      :class="!(Boolean(single.received) == false &&
                        Boolean(single.videos) == true)? 'video_url_only_id':'video_url_id'"
                    ></iframe>
                  </div>
                </div>
              </template>
            </div>
            <div
              v-if="single.carousel_card_list"
              :id="'carousel_card' + item"
              class="carousel_card " data-ride="carousel"
              style=" position: relative !important;"
            >
              <div :class="check_carousel_length(single.carousel_card_list.length)"
              >
                <a class="carousel_scroll_btn1 ml-1" @click="scroll_card_left(item)" type="button"
                  v-if="single.carousel_card_list.length > 1"
                  :id="'carousel_scroll_btn1_' + item"
                  style="display: none;"
                >
                  <svg 
                      style="transform: rotateY(180deg) !important"
                      xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right product_scroll_btn_icon1" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                  </svg>
                </a>
                <div 
                  :id="'carousel_cards_id_'+item"
                  class="cards_carsol d-flex"
                > 
                  <div 
                  v-for="(carousel, index) in single.carousel_card_list"
                  :key="index"
                  class="carousel_cards"
                  >
                  <div class="carousel_card_single"> 
                    <div v-if="carousel.image">
                      <img :src =carousel.image  alt="carousel Image">
                    </div>
                    <div v-if="carousel.video">
                        <iframe
                          :src="carousel.video"
                          allowfullscreen
                          allowtransparency
                          allow="autoplay"
                        ></iframe>
                    </div>
                    <div class="carousel_card_single_content">
                      <h4 v-if="carousel.title">{{carousel.title}}</h4>
                      <div class="carousel_card_single_para">
                        <div v-if="carousel.text">
                          <p><span v-html = "carousel.text"></span></p>
                        </div>
                      </div>
                      <div>
                        <div v-for="(bttn,indx) in carousel.buttons" :key="indx" class="carousel_card_button">
                          <div v-if="Boolean(bttn.title) && Boolean(bttn.value)">
                            <a
                              type="button"
                              @click="custom_button_click(bttn, item)"
                              :disabled="input_disabled"
                              :key="index"
                            >
                              {{bttn.title}}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>  
                  </div>
                </div>
                <a class="carousel_scroll_btn2 ml-1" @click="scroll_card_right(item)" type="button"
                  v-if="single.carousel_card_list.length > 1"
                  :id="'carousel_scroll_btn2_' + item"
                >
                <svg
                  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-right product_scroll_btn_icon1" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                </svg>
                </a>
              </div>
            </div>
            <template v-if="single.is_table == true">
              <div class="chat-row prompt">
                <span style="margin-left: 4%">
                  {{ single.table_title }}
                  <br />
                </span>
                <div
                  class="chat"
                  style="width: auto; margin-left: 4%; margin-top: 2%"
                >
                  <table>
                    <tr
                      v-for="(currency, index) in single.table_list"
                      :key="index"
                      style="line-height: 9.5px"
                    >
                      <th
                        v-show="currency.type == 'THEAD'"
                        style="text-align: left; width: auto"
                      >
                        {{ currency.country_name }}
                      </th>
                      <th
                        v-show="currency.type == 'THEAD'"
                        style="text-align: left"
                      >
                        {{ currency.currency_name }}
                      </th>
                      <td
                        v-show="currency.type == 'TR'"
                        style="font-weight: bolder"
                      >
                        {{ currency.currency_title }}
                      </td>
                      <td
                        v-show="currency.type == 'TR'"
                        style="font-weight: bolder"
                      >
                        {{ currency.currency_value }}
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </template>
            <div class="chat-row receiver" style="position: relative;" v-if="single.url != undefined">
              <div class="d-flex" style="position: relative;">
              <div class="bot-img" :style="bot_img_icon"></div>
              <div class="chat bot-font-receiver">
              <span style="width: max-content">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path
                    d="M6 2c-1.1 0-1.99.9-1.99 2L4 20c0 1.1.89 2 1.99 2H18c1.1 0 2-.9 2-2V8l-6-6H6zm7 7V3.5L18.5 9H13z"
                  />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
                <a
                  href="#"
                  @click="download_pdf(single.url, single.file_name)"
                  style="color: #fff"
                >
                  <span>{{ single.file_name }}</span>
                  <i class="fa fa-download" aria-hidden="true"></i>
                </a>
              </span>
              <div class="chat-his">
                <span class="time" style="color: #000">{{ single.time }}</span>
                <!-- <span class="del-note delivered">Delivered</span> -->
              </div>
              </div>
            </div>
            </div>
            <template v-if="single.is_leaf_note == false">
              <div
                class="chat-row prompt"
                @click="click_prompt(prompt)"
                v-for="(prompt, index) in single.bot_response"
                :key="index"
              >
                <div style="cursor: pointer" :value="single.level" class="chat">
                  {{ prompt }}
                </div>
                <div class="chat-row receiver" v-if="single.buttons">
                  <button
                    class="btn"
                    @click="prompt_dropdown('Yes')"
                    style="background: #1daae1; color: white; font-size: 13px"
                  >
                    Yes
                  </button>
                  <button
                    class="btn"
                    @click="prompt_dropdown('No')"
                    style="background: #1daae1; color: white; font-size: 13px"
                  >
                    No
                  </button>
                </div>
              </div>
            </template>
            <div v-if="single.is_dropdown">
              <select
                class="form-control"
                value
                @change="sub_leaf_node_call('isdropdown')"
              >
                <option value>Select A Currency</option>
                <option
                  v-for="(country, index) in single.dropdown_list"
                  :key="index"
                  :value="country.Value"
                >
                  {{ country.Title }}
                </option>
              </select>
            </div>
          </div>
          <transition name="slide-fade">
            <cense-cart 
              :addtoCart="addtoCartdata"
              :retail_currency="retail_shop_currency"
              :retail_framework="retail_web_framework"
              :shop_url="(retail_web_framework === 'shopify') ? shopify_retail_shop_name: shop_url"
              @close_cart="closecart"
              @cart_data="cart_communication"
              v-if="is_cense_cart && !input_disabled "/>
          </transition>
          <div id="cartDiv"></div>
  
        </div>
      <!-- <div class="custom-integration-btn d-flex" v-if="chat_widget.WidgetIntegrationButtons.length > 0" style="justify-content:space-evenly;">
        <button
          style="align-self:center;"
          class="btn custom-buttons bot-font-buttons"
          @click="custom_button_click(button)"
          v-for="(button, index) in chat_widget.WidgetIntegrationButtons"
          :key="index"
        >
          <img :src="button.icon_url" style="width:20px;height:21px;padding-right:3px;margin-top: -3px;" alt />{{ button.title }}
        </button>
      </div> -->
        <div class="col-sm-12 d-flex px-0" style="min-height:57px;" v-if="show_custom_cart || (Boolean(chat[greeting_buttons_position]) && Boolean(chat[greeting_buttons_position].greeting_button))">
          <div id="custom-cart" v-if="show_custom_cart" class="cart-btn-div col-sm-1 px-0" style="align-self:center;">
            <div
            id="cense-cart-btn"
            type="button" class="btn cense-cart-btn"
            style="padding: 0px; min-width: 37px; max-height: 37px;"
            @click="update_cense_cart"
            >
            <div id="cart-qty-num" 
                class="cart-qty-num bot-font-buttons"
                v-if="total_products_qty.toLocaleString() < 100"
                >{{parseInt(total_products_qty).toLocaleString()}}
            </div>
            <div id="cart-qty-num" 
                  class="cart-qty-num bot-font-buttons"
                  style="margin-bottom: 20px; margin-left: -2px; margin-right: -2px;"
                  v-else>
                    {{99}}<sup style="font-size: 85%;">+</sup> 
            </div>
              <span
                id="cart-img" 
                v-html="cart_icon_svg"
                style="height: 20px; width: 20px;">
                </span>
            </div>
          </div>
          <div
            class="custom-btn-div"
            id="custom-btn-div"
            :class="custom_greetings_buttom_alignment(show_custom_cart)"
            v-if="Boolean(chat[greeting_buttons_position]) && Boolean(chat[greeting_buttons_position].greeting_button)"
            style="align-self:center;"
          >
            <div
              class="d-block d-flex flex-wrap buttons-alignment"
              id="custom-btn-subdiv"
              v-if="Boolean(chat[greeting_buttons_position]) && Boolean(chat[greeting_buttons_position].greeting_button === true) && chat_widget.WidgetIntegrationButtons.length !== 0"
            >
              <button
                :disabled="input_disabled"
                :style="input_disabled ? 'cursor: not-allowed' : null" 
                style="align-self:center;"
                class="btn custom-buttons bot-font-buttons"
                @click="custom_button_click(button, greeting_buttons_position)"
                v-for="(button, index) in chat[greeting_buttons_position].buttons_list"
                :key="index"
              >
                <img v-if="button.icon_url !== undefined" :src="button.icon_url" style="width:20px;height:21px;padding-right:3px;margin-top: -3px;" alt />{{ button.title }}
              </button>
            </div>
            <div
              class="d-block d-flex flex-wrap buttons-alignment"
              v-if="Boolean(chat[greeting_buttons_position]) && Boolean(chat[greeting_buttons_position].greeting_button === true) && chat_widget.WidgetIntegrationButtons.length === 0"
            >
              <button
                :disabled="input_disabled"
                :style="input_disabled ? 'cursor: not-allowed' : null" 
                style="align-self:center;"
                class="btn custom-buttons bot-font-buttons"
                @click="custom_button_click(button, greeting_buttons_position)"
                v-for="(button, index) in chat[greeting_buttons_position].buttons_list"
                :key="index"
              >
                {{ button.title }}
              </button>
            </div>
            <div
              class="d-block d-flex flex-wrap buttons-alignment"
              v-if="!Boolean(chat[greeting_buttons_position]) && !Boolean(chat[greeting_buttons_position].greeting_button === true) && chat_widget.WidgetIntegrationButtons.length !== 0"
            >
              <button
                :disabled="input_disabled"
                :style="input_disabled ? 'cursor: not-allowed' : null" 
                style="align-self:center;"
                class="btn custom-buttons bot-font-buttons"
                @click="custom_button_click(button)"
                v-for="(button, index) in chat_widget.WidgetIntegrationButtons"
                :key="index"
              >
                <img v-if="button.icon_url !== undefined" :src="button.icon_url" style="width:20px;height:21px;padding-right:3px;margin-top: -3px;" alt />{{ button.title }}
              </button>
            </div>
          </div>
          <!-- <div
            :class="custom_greetings_buttom_alignment(show_custom_cart)"
            v-else
            style="align-self:center;"
          >
          <div
              class="d-block d-flex flex-wrap buttons-alignment"
              v-if="chat_widget.WidgetIntegrationButtons.length !== 0"
            >
              <button
                :disabled="input_disabled"
                :style="input_disabled ? 'cursor: not-allowed' : null" 
                style="align-self:center;"
                class="btn custom-buttons bot-font-buttons"
                @click="custom_button_click(button)"
                v-for="(button, index) in chat_widget.WidgetIntegrationButtons"
                :key="index"
              >
                <img v-if="button.icon_url !== undefined" :src="button.icon_url" style="width:20px;height:21px;padding-right:3px;margin-top: -3px;" alt />{{ button.title }}
              </button>
            </div>
          </div> -->
        </div>
        <div class="chat-footer">
          <div 
            class="typing-indicator" 
            :class="{
              'd-block': is_typing_indicator_on,
              'd-none': !is_typing_indicator_on,
            }"
          >
            <p v-if="waiting_for_response == false"></p>
            <p v-if="waiting_for_response == true">waiting for response</p>
            <p style="font-size: 15px; font-weight: 500" v-show="isRecording && !isSpeechSpinner">
                  Recording audio
                </p>
                <p style="font-size: 15px; font-weight: 500" v-show="isSpeechSpinner">
                  Processing
                </p>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div class="chat-txt">
          <div class="input-group mb-0"
          :style="chat_widget.is_wider_view? 'width:97%;':'width:94%'">
            <input
              type="text"
              class="form-control"
              id="response_bot_text"
              @input="button_fill"
              @keyup.enter="send_message"
              v-model.trim="to_send"
              :disabled="input_disabled || isSpeechSpinner ||isRecording"
              :placeholder="Boolean(is_retail_bot === true)? 'Search products or type your message here ...': 'Type your message here ...'"
            /> 
            <div class="pr-2 pl-2" title="Stop chat" style="background-color: #fff;" v-if="live_chat_on">
              <img src="https://storage.cense.ai/codearray/stop-icon.png" 
              style="padding-top: 5px; height: 26px; cursor: pointer; title: 'Stop Chat'"
              @click="disconnect_support_chat" alt="">
            </div>
            <div class="input-group-append"
                :style="isRecording ? 'border-radius: 0 10px 10px 0;':''"
                v-if="isSpeechSubscribed"
                >
                 <div class="timer"   v-if="isRecording && !isSpeechSpinner">{{ timer }}s</div>
                 <img src="@/assets/images/stop.png" @click="stopRecording"  v-if="isRecording && !isSpeechSpinner" class="stop_img" alt="stop"/>
              <div class="box box--bottom" v-if="isSpeechError">
                {{ speechError }}
              </div>
                  <button
                    class="btn voice-button"
                    type="button"
                    v-if="!isRecording && !isSpeechSpinner"
                    @click="startRecording"
                    :disabled="input_disabled"
                  >
                    <!-- <i class="fa fa-microphone" id="microphone"
                    :class="isSpeechSpinner?'disable_mic':''"></i> -->
                    <svg xmlns="http://www.w3.org/2000/svg"  :class="isSpeechSpinner?'disable_mic':''" id="microphone" width="24" height="24" fill="currentColor" class="bi bi-mic-fill fa fa-microphone" viewBox="0 0 16 16">
  <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z"/>
  <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"/>
</svg>
                  </button>
                  <button
                    class="btn voice-button"
                    type="button"
                    v-if="isRecording && !isSpeechSpinner"
                    @click="stopRecording"
                  >
                    <!-- <i class="fa fa-microphone voice-on" id="microphone"
                    :class="isSpeechSpinner?'disable_mic':''"></i> -->
                    <svg xmlns="http://www.w3.org/2000/svg" id="microphone"
                    :class="isSpeechSpinner?'disable_mic':''" width="24" height="24" fill="currentColor" class="bi bi-mic-fill fa fa-microphone voice-on" viewBox="0 0 16 16">
  <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z"/>
  <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"/>
</svg>
                  </button>
                  <button
                    class="btn button1 active pushed"
                    type="button"
                    id="pushed"
                    v-if="isSpeechSpinner"
                  >
                          <svg xmlns="http://www.w3.org/2000/svg" id="microphone"
                    :class="isSpeechSpinner?'disable_mic':''" width="24" height="24" fill="#003080" class="bi bi-mic-fill fa fa-microphone voice-on" viewBox="0 0 16 16">
  <path d="M5 3a3 3 0 0 1 6 0v5a3 3 0 0 1-6 0V3z"/>
  <path d="M3.5 6.5A.5.5 0 0 1 4 7v1a4 4 0 0 0 8 0V7a.5.5 0 0 1 1 0v1a5 5 0 0 1-4.5 4.975V15h3a.5.5 0 0 1 0 1h-7a.5.5 0 0 1 0-1h3v-2.025A5 5 0 0 1 3 8V7a.5.5 0 0 1 .5-.5z"/>
</svg>
                  </button>
                </div>
            <div class="input-group-append" id="send_message" v-if="!isRecording">
              <button
                class="btn"
                type="button"
                id="button-addon2"
                @click="send_message('clicked')"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  :class="to_send.length === 0 ? 'no_color_send' : 'color_send'"
                  id="send_button"
                  width="40"
                  height="25"
                  viewBox="0 0 24 24"
                >
                  <path d="M2.01 21L23 12 2.01 3 2 10l15 2-15 2z" />
                  <path d="M0 0h24v24H0z" fill="none" />
                </svg>
              </button>
            </div>
          </div>
        </div>
        <div class="chat-cense-footer">
          <span 
            v-show="this.chat_widget.is_powered_by_cense"
            class="powered-by" style="display: flex;">
            Powered by &nbsp;
            <a href="https://cense.ai" target="_blank"
              title="click here to visit"
              class="open_cense_website"><img :src="powered_by" style="height: 20px"/></a>
          </span>
        </div>
      </div>
      <!-- bot_channel_type -->
      <div
        class="chat-act"
        @click="load_widget"
        v-show="show_widget && bot_channel_type.supported_channel_type == 'website'"
        :style="widget_icon_position"
      >
        <a href="javascript:void(0)" id="button_class" class="chat-btn" style="outline: none;" :style="chat_widget_icon_title" :title="chat_widget_title"></a>
      </div>
      <div
        class="floating"
        v-show="show_widget && bot_channel_type.supported_channel_type == 'whatsapp'"
      >
        <a :href="bot_channel_type.link" target="_blank">
        <img src='https://storage.cense.ai/codearray/whatsapp_icon.png' style="width:65px" alt />
        </a>
      </div>
      <div
        class="floating"
        v-show="show_widget && bot_channel_type.supported_channel_type == 'messenger'"
      >
        <a :href="bot_channel_type.link" target="_blank">
          <img src='https://storage.cense.ai/codearray/messenger_icon.png' style="width:65px" alt />
       </a>
      </div>
      <div
        id="feedbackquestionsmodel"
        class="modal fade is-hidden is-visuallyHidden"
        data-backdrop="static"
        data-keyboard="false"
        role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-scrollable" role="document">
          <div class="modal-content" style="border-radius: 10px;">
            <div class="modal-header">
              <div class="modal-head d-flex align-items-center">
                <h5 style="" class="modal-header-style">Please provide us with this feedback to help you serve better!</h5>
                <button style="padding-top: 0px;" type="button" class="close feedback-close"
                 data-dismiss="modal" @click="get_feedback_questions">
                &times;</button>
              </div>
            </div>
            <div class="modal-body">
              <div v-for="(questions, key) in feedback_question_list" :key="key" style="margin-left: -20px;">
                <div class="radio" style="margin-bottom: 8px;">
                <!-- <p style="font-size: 14px;" class="faq-style"> -->
                  <ul style="margin-bottom: 0px;"><li style="font-size: 14px;" class="faq-style">{{questions.question}}</li></ul>
                  <!-- </p> -->
                
                  <span v-for="(option, key) in questions.options" :key="key"
                    style="margin-left: 28px;">
                    <label style="padding-right: 0px;">
                      <input type="radio" :value="option.value" id="feedback_value"
                      :name="questions.question" @change="ratingAdd(option.value,questions.question)">
                    <span v-if="option.value !== ''" class="icn radio-btn"></span>{{option.value}}
                    </label>
                  </span>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="btn-wrap text-right mt-0 py-0 border-top-0">
                <button
                  type="submit"
                  class="darkblue-btn send-btn"
                  id="res_n_int_save"
                  @click="send_feedback"
                >
                  Send
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        id="ordernotesmodel"
        class="modal fade dash-modal"
        role="dialog"
        data-backdrop="static"
        data-keyboard="false"
        style="z-index: 2001 !important;"
        >
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-body">
              <div class="modal-head d-flex align-items-center">
                <div class="head-icn"
                style="width: 50px;
                  height: 50px;
                  background: rgba(39, 54, 121, 0.1);
                  margin-right: 20px;
                  border-radius: 50%;"
                >
                  <img src="@/assets/images/msg-blue.png" alt />
                </div>
                <h3 style="text-transform: none; font-size: 24px; font-weight: 400; margin: 0; color: #494D69;">Notes for Order No. {{selected_order_name}}</h3>
                 <button 
                type="button" 
                class="close" 
                data-dismiss="modal" 
                style="position: absolute;
                  top: 30px;
                  right: 30px;
                  opacity: 1;"
              >
                <img src='@/assets/images/close-icn.png' alt />
              </button>
              </div>
              <div v-if="order_notes_data.length > 0">
              <div v-for="(note ,index) in order_notes_data"
              :key="index">
                <ul style="list-style-type: disc; padding-left: 20px; margin-top: 20px;">
                  <li><p> <strong>{{note.date_created}}</strong>  : {{note.note}}</p></li>
                </ul>
              </div>
              </div>
              <div v-else>
                <p>No updates</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="chat-master" id="cense_app" :style="cssProps">
      <div
        id="notifyemail"
        class="modal fade dash-modal"
        role="dialog"
        data-backdrop="static"
        data-keyboard="false"
        aria-hidden="true"
        aria-labelledby="notifyemailtitle"
        style="z-index: 2001"
      >
        <div class="modal-dialog modal-lg" role="document" style="font-size: initial !important; padding-right: 15rem;">
          <div class="modal-content" style="flex-direction: inherit !important">
            <div class="modal-body" style="padding: 30px;background-color: white !important;; text-align: left !important;">
              <div class="modal-head d-flex align-items-center" style="margin: 0 0 30px;">
                <div class="head-icn" 
                  style="width: 50px;
                    height: 50px;
                    background: rgba(39, 54, 121, 0.1);
                    margin-right: 20px;
                    border-radius: 50%;"
                  >
                  <img src='@/assets/images/msg-blue.png' alt />
                </div>
                <h3 style="font-size: 24px; font-weight: 400; margin: 0; color: #494D69;">Out of Stock products</h3>
                <button 
                  type="button" 
                  class="close" 
                  data-dismiss="modal" 
                  style="position: absolute;
                    top: 30px;
                    right: 30px;
                    opacity: 1;"
                >
                  <img src='@/assets/images/close-icn.png' alt />
                </button>
              </div>
              <div>
                <div
                  class="p-0"
                  style="overflow-y: auto"
                >
                <label class="label_class" style="font-weight: 400;">Please check the products regarding which you want notification</label>
                  <div class="table-box">
                    <div class="table-responsive">
                      <table class="table" style="margin-bottom: 0rem;">
                        <thead>
                          <tr>
                            <th scope="col" 
                              style="
                                width: 38px;
                                border-left: solid 1px #DBE2E8;
                                border-left-color: rgb(219, 226, 232);
                                border-left-width: 1px;
                                background: #F7F8FB;
                                border-color: #DBE2E8;
                                border-left-color: rgb(219, 226, 232);
                                border-left-color: rgb(219, 226, 232);
                                border-width: 1px;
                                border-left-width: 1px;
                                border-left-width: 1px;
                                font-size: 14px;
                                color: #9499AE;
                                font-weight: 400;"
                              >
                              <div class="form-check checkbox checkbox-success">
                                <label class="form-check-label">
                                  <input
                                    class="form-check-input"
                                    id="selectall"
                                    type="checkbox"
                                    style="display:none;position: inherit; margin: 0;"
                                    @change="selected_product('all')"
                                  />
                                  <span style="cursor: pointer;    
                                          position: absolute;
                                          left: 0;
                                          top: 0;
                                          width: 18px;
                                          height: 18px;
                                          border-radius: 3px;
                                          background: #fff;
                                          display: inline-block;
                                          width: 18px;
                                          height: 18px;
                                          border: solid 1px #283677;" 
                                  class="icn"></span>
                                </label>
                              </div>
                            </th>
                            <th
                              scope="col"
                              class="text-left"
                              style="
                                font: normal normal normal 16px/22px Roboto;
                                letter-spacing: 0px;
                                color: #414141;
                                border-left: 0;
                                background: #F7F8FB;
                                border-color: #DBE2E8;
                                border-right-color: rgb(219, 226, 232);
                                border-left-color: rgb(219, 226, 232);
                                border-right-color: rgb(219, 226, 232);
                                border-left-color: rgb(219, 226, 232);
                                border-width: 1px;
                                border-right-width: 1px;
                                border-left-width: 1px;
                                border-right-width: 1px;
                                border-left-width: 1px;
                                border-right: solid 1px #DBE2E8;
                                border-radius: 0 4px 4px 0;
                                vertical-align: middle !important;
                              "
                            >
                              Select All
                            </th>
                          </tr>
                        </thead>
                        <tbody style="border-collapse: collapse;">
                          <tr style="box-sizing: border-box;">
                          <tr
                            v-for="(product, index) in product_out_of_stock_list"
                            :key="index"
                            :class="check_current_product_active(index)"
                            style="box-sizing: border-box;"
                          >
                            <th scope="col" 
                              style="border-color: #DBE2E8;
                                font-size: 15px;
                                color: #494D69;
                                font-weight: 400;
                                vertical-align: middle;
                                width: 38px;
                                padding: .8rem;">
                              <div class="form-check checkbox checkbox-success" style="padding: 0; margin: 0;">
                                <label class="form-check-label"
                                  style="
                                    width: 18px;
                                    height: 18px;
                                    position: relative;
                                    display: block;
                                    line-height: 20px;
                                    color: #9499AE;
                                    padding-left: 28px;
                                    margin: 0;
                                    cursor: pointer;"
                                >
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    :id="'product_checkbox_'+product.product_id"
                                    @change="
                                      selected_product(product)
                                    "
                                    style="display:none;position: inherit; margin: 0; box-sizing: border-box;padding: 0;"
                                  />
                                  <span class="icn" 
                                    style="
                                      position: absolute;
                                      left: 0;
                                      top: 0;
                                      width: 18px;
                                      height: 18px;
                                      border-radius: 3px;
                                      background: #fff;
                                      display: inline-block;
                                      width: 18px;
                                      height: 18px;
                                      border: solid 1px #283677;" >
                                      </span>
                                </label>
                              </div>
                            </th>
                            <td
                              style="
                                  border-color: #DBE2E8;
                                  font-size: 15px;
                                  color: #494D69;
                                  font-weight: 400;
                                  vertical-align: middle;"
                              @click="show_product(index)"
                              :title="product.product_title"
                            >
                              <template>
                                <p style="margin-bottom: 0; margin-top:0;">
                                  {{ product.product_title }} {{product.variant_title}}
                                </p>
                              </template>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <hr>
              <div class="form-group">
                <input
                  type="text"
                  class="form-control out_of_stock_input"
                  style="border: 1px solid #ced4da;
                    border-radius: .25rem; fon-size: 16px;
                    font-weight: 300;
                    box-shadow: none;
                    outline: none;
                    margin: 0;
                    background-position: 15px 15px !important;
                    display: block;
                    width: 90%;
                    height: calc(2.25rem + 2px);
                    padding: .375rem .75rem;
                    font-size: 1rem;
                    line-height: 1.5;
                    color: #495057;
                    background-color: #fff;
                    background-clip: padding-box;
                    border: 1px solid #ced4da;
                    border-radius: .25rem;
                    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
                    font-size: initial;
                    box-sizing: initial; !important"
                  v-model="customer_email"
                  placeholder="Enter Your Email Id"
                />
              </div>
              <div style="text-align: center;">
              <button
                  type="submit"
                  class="darkblue-btn"
                  id="res_n_int_save"
                  @click="submit_out_of_stock_products()"
                >
                  Notify Me
              </button>
              </div>
                </div>
              <!-- </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </template>
  
  <script>
  import countries from "../assets/countries";
  import axios from "axios";
  import fingerprint from "../assets/fingerprint";
  import { Socket } from "phoenix";
  import api_calls from "../api_calls";
  import { eventBus } from "../main";
  import jQuery from "jquery";
  import moment from "moment";
  import { setTimeout } from "timers";
  import swal from "sweetalert2";
  import Cookies from "js-cookie";
  import StarRating from "vue-star-rating";
  import Multiselect from "vue-multiselect";
  import CenseCart from "./CenseCart.vue";
  import VueNumericInput from "vue-numeric-input";
  import debounce from "vue-debounce/dist/debounce.min.js";
  import sound from "@/assets/audio/mixkit-add-to-cart.wav";
  
  const $ = jQuery;
  export default {
    components: {
      StarRating,
      Multiselect,
      CenseCart,
      VueNumericInput,
    },
    data() {
      return {
        show_bot:false,
        show_widget: false,
        hide_bot:false,
        to_send: "",
        powered_by: "https://storage.cense.ai/codearray/cense_image.png",
        chat: [],
        user_data: {},
        user_name: "",
        level: 0,
        today_date: "",
        first_click: false,
        live_chat_on: false,
        form_payload: null,
        channel: null,
        socket: null,
        to_scroll: false,
        load_chat_window: false,
        refreshed_or_closed: true,
        country_list: null,
        chat_group_name: null,
        bot_token_response_value: null,
        live_chat_token: "",
        company_name: "",
        presence_object: null,
        welcome_message_not_yet_received: true,
        waiting_for_response: false,
        chat_widget: {
          logo: "",
          title: "",
          style: "",
          bot_theme: "",
          button_border_theme: "#283677",
          user_response_bg_color: "#283677",
          bot_response_bg_color: "#ffffff",
          delivery_note_color: "#283677",
          button_theme: "#283677",
          bot_icon: "",
          button_alignment: "",
          background_image: "",
          button_horizontal_spacing: "",
          button_vertical_spacing: "",
          left_position: null,
          right_position: 30,
          bottom_position: 12,
          bot_font_style: "",
          bot_font_color_buttons: "",
          bot_font_color_receiver: "",
          bot_font_color_sender: "",
          bot_open_by_default: false,
          bot_shown_on_website: false,
          is_powered_by_cense: true,
          show_bot_history: true,
          bot_active_button_img: '',
          bot_inactive_button_img: '',
          WidgetIntegrationButtons:[],
          is_wider_view:false,
        },
        is_typing_indicator_on: false,
        initial_bot_greetings: null,
        shopify_ui: null,
        shopify_customer_id: null,
        refund_order_view_details: {},
        refund_selected_items: [],
        shopify_retail_shop_name: null,
        is_retail_bot: false,
        shopify_reason_for_refund: null,
        show_shopify_reason_for_refund_error: false,
        bot_templates_details: null,
        support_channel: null,
        is_support_agent_present: false,
        support_agent_info: null,
        retail_order_retreival_only_email_required: false,
        retail_shop_currency: null,
        feedback_question_order: [
          "Did you find what you were looking for?",
          "Any feedback on the pricing?",
        ],
        feedback_question_list: [],
        feedback_data_list: [],
        rating: [],
        product_list: [],
        product_out_of_stock_list: [],
        checked_list: [],
        current_product: "",
        customer_email: "",
        reg_email:
          /^([0-9a-zA-Z]([-.\w]*[0-9a-zA-Z])*@([0-9a-zA-Z][-\w]*[0-9a-zA-Z]\.)+[a-zA-Z]{2,9})$/,
        spinnerOn: false,
        data_loaded: false,
        scrollPosition: 0,
        visible_custom_greetings_buttom: false,
        check_storefront_token: null,
        review_message_first: "",
        cart_custom_product_details: [],
        // custom_product_button_key: [{'name': 'customize it', 'function':'route', 'on_key': 'product_type', 'on_value':'custom']}],
        custom_product_button_key: [],
        review_message_second: "",
        contact_help_email: "",
        review_response_message: "",
        shop_url: "",
        shopify_reason_for_refund_list: null,
        shopify_reason_for_refund_option: [
          "Ordered by mistake",
          "Ordered wrong product",
          "Order not Received",
          "No longer needed",
          "Better price available",
          "Received damaged products",
          "Others",
        ],
        is_cense_cart: false,
        show_custom_cart: false,
        total_products_qty: 0,
        retail_web_framework: "",
        addtoCartdata: this.$session.has("store_cart_data")
          ? this.$session.get("store_cart_data")
          : [],
        over_qty_warning: false,
        order_notes_data: [],
        selected_order_name: "",
        display_products_on_landing: false,
        visitors_current_location: null,
        is_display_banner_on_landing: false,
        visitors_current_location_url: `https://ipgeolocation.abstractapi.com/v1/?api_key=${process.env.VUE_APP_CURRENT_LOCATION_TOKEN}`,
        license_key_invalid: false,
        greeting_buttons_position: null,
        bot_channel_type: {
          'supported_channel_type': '',
          'link': ''
        },
        initial:true,
        first:true,
        previous_length:0,
        to_be_called : false,
        l_n:'',
      audioChunks: [],
      mediaRecorder:{},
      isRecording:false,
      timer: 60, // Initialize the timer variable
      timerInterval: null,
      isSpeechError:false,
      isSpeechSubscribed:true,
      speechError:'',
      isSpeechSpinner:false,
      audio:{},
      cachedData:[],
      isAudioPaused:false,
      autoAudioEnabled:false,

      };
    },
    beforeDestroy() {
      if (this.live_chat_on) this.disconnect_support_chat();
    },
    created(){

    },
    mounted() {
      var vm = this;
      document.addEventListener(
        "scroll",
        function (ev) {
          if (ev.target.id === "widget_chat_body_id") {
            vm.scrollPosition = ev.target["scrollTop"];
          }
        },
        true
      );
      this.get_widget_style_from_session();
      this.show_widget = true;
      this.country_list = countries;
      this.fingerprint = fingerprint.toString();
      eventBus.$on("New_Widget_parameters", (data1) => {
        this.user_data = data1;
        if (this.user_data.username == undefined) {
          this.user_data.username = this.fingerprint;
        }
        this.user_data.host = window.location.origin;
        this.check_bot_login_token();
        this.save_retail_cart_info();
        setTimeout(()=>{
      },0)
      });
      eventBus.$on("Wrong_Widget_parameters", (data1) => {
        this.license_key_invalid = true;
      });
      $(window).on("shown.bs.modal", ".modal", function () {
        $(".modal").removeAttr("tabindex");
      });
      //To hide cense cart if clicked outside
      window.addEventListener(
        "click",
        function (e) {
          if (e.target.closest("#cart-box") || e.target.closest("#custom-cart")) {
          } else {
            vm.is_cense_cart = false;
          }
        },
        true
      );
      if (this.$session.get("session_retail_web_framework") === "shopify") {
        this.get_shopify_website_cart();
      } else if (vm.$session.has("store_cart_data")) {
        for (var j in vm.addtoCartdata) {
          vm.total_products_qty =
            this.total_products_qty + this.addtoCartdata[j].order_qty;
        }
      }
      let doc = document.getElementsByClassName('custom-btn-div')
      setInterval(()=>{
      this.cachedData = []
    },1000*60*10)
    },
    computed: {
      cart_button_alignment() {
        if (this.is_retail_bot) {
          return "col-sm-2 px-0";
        } else {
          return null;
        }
      },
      input_disabled() {
        return this.refreshed_or_closed || this.welcome_message_not_yet_received;
      },
      cart_icon_svg() {
        return `<svg class="btn-chatbot-svg" xmlns="http://www.w3.org/2000/svg" version="1.0" width="16.000000pt" height="16.000000pt" style="margin-top:9px;" viewBox="0 0 512.000000 512.000000" preserveAspectRatio="xMidYMid meet"><g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)" fill="${this.chat_widget.bot_font_color_buttons}" stroke="none"><path d="M81 5002 c-100 -50 -106 -186 -12 -249 33 -23 39 -23 317 -23 l284 0 5 -22 c3 -13 172 -745 375 -1628 279 -1209 376 -1612 392 -1636 47 -69 -72 -64 1599 -64 1367 0 1514 2 1545 16 37 18 68 62 79 111 9 45 -24 117 -67 143 -33 20 -45 20 -1475 20 -1364 0 -1442 1 -1447 18 -12 37 -106 454 -106 468 0 12 206 14 1503 14 1472 0 1503 0 1534 20 18 10 39 33 48 50 26 50 468 2030 461 2068 -7 44 -39 86 -80 106 -31 15 -208 16 -2014 16 l-1980 0 -27 118 c-73 319 -94 395 -117 419 -49 53 -51 53 -430 53 -316 0 -357 -2 -387 -18z m4702 -869 c2 -5 -81 -381 -184 -838 -104 -456 -188 -831 -189 -833 0 -1 -656 -1 -1457 0 l-1458 3 -192 830 c-105 457 -192 833 -192 838 -1 9 3667 9 3672 0z"/><path d="M2038 1165 c-190 -48 -356 -216 -398 -400 -15 -64 -15 -186 0 -250 31 -137 151 -290 277 -354 173 -87 387 -78 538 23 219 146 306 407 216 647 -55 144 -181 268 -326 320 -78 27 -227 34 -307 14z m217 -295 c48 -19 104 -71 129 -121 111 -223 -147 -449 -354 -310 -176 118 -130 382 78 437 45 12 105 9 147 -6z"/><path d="M3806 1164 c-133 -32 -278 -146 -343 -270 -113 -213 -75 -462 97 -634 213 -213 542 -214 754 -4 35 35 78 88 95 118 38 66 71 190 71 266 0 76 -33 200 -71 266 -17 30 -60 83 -95 118 -134 133 -323 185 -508 140z m195 -288 c48 -8 112 -56 146 -108 25 -38 28 -52 28 -128 0 -76 -3 -90 -27 -127 -51 -78 -118 -115 -209 -115 -109 0 -203 69 -233 173 -20 65 -20 73 0 138 16 56 69 123 117 147 32 17 104 33 127 29 8 -2 31 -6 51 -9z"/></g></svg>`;
      },
      cssProps() {
        let botAttributes = {
          head_color: this.chat_widget.header_background,
        };
        return {
          "--bot-head-color": botAttributes.head_color,
          "--bot-sender-color": this.chat_widget.bot_theme,
          "--bot-button-color": this.chat_widget.bot_theme,
          "--bot-link-color": this.chat_widget.bot_theme,
          "--bot-button-border": this.chat_widget.button_border_theme,
          "--user-response-bg-color": this.chat_widget.user_response_bg_color,
          "--bot-response-bg-color": this.chat_widget.bot_response_bg_color,
          "--delivery-note-color": this.chat_widget.delivery_note_color,
          "--bot-button-background": this.chat_widget.button_theme,
          "--bot-buttons-alignment": this.chat_widget.button_alignment.value,
          "--bot-buttons-vertical-spacing": `${this.chat_widget.button_vertical_spacing}%`,
          "--bot-buttons-horizontal-spacing": `${this.chat_widget.button_horizontal_spacing}%`,
          "--bot-font-style": this.chat_widget.bot_font_style.value,
          "--bot-font-color-sender": this.chat_widget.bot_font_color_sender,
          "--bot-font-color-receiver": this.chat_widget.bot_font_color_receiver,
          "--bot-font-color-buttons": this.chat_widget.bot_font_color_buttons,
          "--bot-button-active-img": `url(${this.chat_widget.bot_active_button_img})`,
          "--bot-button-inactive-img": `url(${this.chat_widget.bot_inactive_button_img})`
        };
      },
      bot_img_icon() {
        return {
          "background-image": `url(${this.chat_widget.bot_icon})`,
        };
      },
      chat_background_image_and_widget_position() {
        var chat_widget_bottom_position = 13 ;
        if (this.chat_widget.background_image === '') {
          return {
            'background': '#F0F4FF',
            // left: `${this.chat_widget.left_position}` + "px !important",
            right: `35` + "px",
            bottom: `${parseInt(chat_widget_bottom_position)}` + "px !important",
            "min-width": "0px !important",
            "max-width": "calc(90% - 20px) !important",
            "z-index": "2147483647 !important",
          };
        }
        return {
          "background": `url(${this.chat_widget.background_image})`,
          // left: `${this.chat_widget.left_position}` + "px",
          right: `35` + "px",
            bottom: `${parseInt(chat_widget_bottom_position)}` + "px !important",
          "min-width": "0px !important",
          "max-width": "calc(90% - 20px) !important",
          "z-index": "2147483647 !important",
        };
      },
      widget_icon_position() {
        var add =  13;
        // console.log(add);
        return {
          left: `${this.chat_widget.left_position}` + "px !important",
          right: `${parseInt(this.chat_widget.right_position) - 9}` + "px ",
          bottom: `${parseInt(this.chat_widget.bottom_position) + add}` + "px !important",
          "z-index": "2147483647 !important",
        };
      },
      chat_widget_title() {
        if (this.license_key_invalid) {
          return "Please verify your license key or contact support@cense.ai";
        } else {
          return "";
        }
      },
      chat_widget_icon_title() {
        if (this.license_key_invalid) {
          return "cursor: not-allowed;";
        }
      },
    },
    watch: {
      timer(){
      this.calculate_percantage()
      this.$forceUpdate()
    },
      scrollPosition() {
        if (this.scrollPosition > 150) {
          this.visible_custom_greetings_buttom = true;
        } else if (this.scrollPosition < 150) {
          this.visible_custom_greetings_buttom = false;
        }
      },
      to_send() {
        this.button_fill();
      },
      chat() {
        if(this.to_be_called){

          let indexes = []
          let chatfiltered = this.chat.filter((ch,index)=>{
            if(ch.receiving === true){
              indexes.push(index)
            }
            return ch.receiving === true
          })
          length = chatfiltered.length

          this.getText("recieved"+indexes[indexes.length - 1],indexes.length - 1,chatfiltered , length -1)
          this.to_be_called = false
        }
      this.scroll_down_bottom();
      },
      load_chat_window(newVal) {
        if (newVal === false) {
          this.take_user_feedback();
        }
      },
      data_loaded(newVal) {
        if (newVal === false) {
          this.take_user_feedback();
        }
      },
     
    },
    beforeDestroy() {},
    methods: {


      pauseAudio(index ,stopp , playy) {
  // console.log("Audio" , audio.paused , audio.ended);
  // If audio is not undefined and if is playing, pause it
  let audios = document.querySelectorAll('.audio')
    let stops = document.querySelectorAll('.stop')
  stops.forEach(stop=>{
    stop.style.display = "none"
  })
  let plays = document.querySelectorAll('.play')
  plays.forEach(play=>{
    play.style.display = "block"
  })

  audios.forEach(audio=>{

    if (audio && audio.paused!== undefined) {
      audio.pause();
      audio.currentTime = 0; // Rewind track to beginning (is you need this)
      this.isAudioPaused = true
    }
  })
  let playId = playy + index
    let stopId = stopp + index 

    let play = document.getElementById(playId);
    let stop = document.getElementById(stopId);
    if(play && stop){
      play.style.display = 'block';
      stop.style.display = 'none'
    }

 },

  playAudio(src , index ,stop , play , audioId) {
    this.pauseAudio(index , stop , play);             // Pause any currently playing
    // this.audio = new Audio();  
    // this.audio.preload = 'metadata'    // Save a reference
    let id = audioId + index
    let audio= document.getElementById(id)
    audio.src = src;
    audio.play();
    let playId = play + index
    let stopId = stop + index 

    let playy = document.getElementById(playId);
    let stopp = document.getElementById(stopId);
    playy.style.display = 'none';
    stopp.style.display = 'block'
    this.isAudioPaused = false
//     var au = document.getElementByTagName("audio");
// au.onloadedmetadata = function() {
//     console.log(au.duration)
// };
  },
  audioEnded(index , audio , stopp , playy){
    let playId = playy + index
    let stopId = stopp + index 

    let play = document.getElementById(playId);
    let stop = document.getElementById(stopId);
    play.style.display = 'block';
    stop.style.display = 'none'
    this.isAudioPaused = false

  },
  text_to_speech(chat , index , stop , play , audioId , res){
    let docs = document.querySelectorAll('.blink')
    docs.forEach(doc=>{
      doc.classList.remove('blink')
    })
    let id = play+index
    let speaker = document.getElementById(id)
    if(speaker){
      speaker.classList.add('blink')
    }
     let filteredAudio = this.cachedData.filter(data=>{
      return data.text === chat
     })
    if(filteredAudio.length > 0 && !this.autoAudioEnabled){
      if(speaker){

      speaker.classList.remove('blink')
      }
      this.playAudio(filteredAudio[0].objUrl , index , stop , play , audioId);
    }else{

      axios
           .post(api_calls.speech_synthesis(), {
            license_key:  this.user_data.license_key,
            token:  this.$session.get("bot_login_token"),
            username:this.user_data.username,
            source: "Web",
            text:chat,
           },{ responseType: 'blob' })
           .then((response) => {
            if(res){
              this.chat.push(res)
            }
            if(speaker){

              speaker.classList.remove('blink')
            }
   
 
 let  blob = new Blob([response.data]);
 let blobUrl = URL.createObjectURL(blob);
 if(blobUrl.length > 0){

   let audioObj = [{
     'text':chat ,
     'objUrl':blobUrl,
   },...this.cachedData] 
   // this.cachedData.push(audioObj)
   this.cachedData = [...audioObj] 
 }
 setTimeout(()=>{

   this.playAudio(blobUrl , index ,stop , play, audioId);
 },100)
             if(this.api_status_code.INTERNAL_SERVER_ERROR.MSG_CODE === response.data.message.MSG_CODE){
             swal({
                 text: 'Internal server error',
                 toast: true,
                 showCancelButton: false,
                 showConfirmButton: false,
                 type: "warning",
                 timer: 2000,
                 position:'top-end'
               });
           }
           if(this.api_status_code.TEXT_FIELD_NONE.MSG_CODE === response.data.message.MSG_CODE){
 
             swal({
                 text: 'Text field not selected',
                 toast: true,
                 showCancelButton: false,
                 showConfirmButton: false,
                 type: "warning",
                 timer: 2000,
                 position:'top-end'
               });
               return
           }
 
           
           }).catch((e) => {
             if (e.response) {
             if (
               e.response.status === 410 ||
               e.response.status === 440 ||
               e.response.status === 409
             ) {
               this.$root.$emit("Session_Expired", e.response.data);
             }
           }
           });
    }
  },
    calculate_percantage(){
      const hundred = 100;
      let updatedProgress = (hundred * this.timer)/60 ;
      return (hundred * this.timer)/60 ; 
    },
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.timer--; // Increment the timer every second
        if (this.timer <= 0) {
          this.stopRecording(); // Stop recording after 60 seconds
        }
      }, 1000); // Update the timer every 1 second (1000 milliseconds)
    },
    stopTimer() {
      clearInterval(this.timerInterval); // Stop the timer
    },
    saveAudio() {
      if (this.audioChunks.length === 0) {
        console.error("No audio recorded to save.");
        return;
      }

      // Combine all audio chunks into a single Blob
      const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });

      // Create a temporary URL for the Blob
      const audioURL = URL.createObjectURL(audioBlob);

      // Create an anchor element to trigger the download
      const a = document.createElement('a');
      a.href = audioURL;
      var today = new Date();
var time = today.getHours() + "_" + today.getMinutes() + "_" + today.getSeconds();
      a.download = `recorded-audio-${time}.wav`;
      document.body.appendChild(a);

      // Trigger the download
      a.click();

      // Clean up
      window.URL.revokeObjectURL(audioURL);
      document.body.removeChild(a);
    },
    startRecording() {
      this.isSpeechError = false
      this.is_typing_indicator_on = true
      this.audioChunks = [];
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then((stream) => {
          this.isRecording = true;
          this.mediaRecorder = new MediaRecorder(stream);
          // console.log("🚀 ~ file: ResponseBot.vue:2088 ~ .then ~ this.mediaRecorder:", this.mediaRecorder)
          this.mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              // console.log("🚀 ~ file: ResponseBot.vue:2091 ~ .then ~ event.data:", event.data)
              const blobSize = event.data.size;
              // console.log("🚀 ~ file: ResponseBot.vue:2095 ~ .then ~ blobSize:", blobSize)
    const blobType = event.data.type;
    // console.log("🚀 ~ file: ResponseBot.vue:2096 ~ .then ~ blobType:", blobType)

    // Access the Blob's binary content (audio or video data)
    const blobData = event.data;
    // console.log("🚀 ~ file: ResponseBot.vue:2099 ~ .then ~ blobData:", blobData)

    // Perform actions with the Blob data, e.g., save to an array
    this.audioChunks.push(blobData);
    // console.log("🚀 ~ file: ResponseBot.vue:2102 ~ .then ~ audioChunks:", this.audioChunks , this.audioChunks[0])

    // You can also create a URL for the Blob to play it in an audio or video element
    // const blobURL = URL.createObjectURL(blobData);
    // console.log(this.timer)
      this.sendAudio(this.timer)
            // console.log("🚀 ~ file: ResponseBot.vue:2105 ~ .then ~ blobURL:", blobURL)
            // this.logAudio()
            // this.logAudioContent()
            // this.to_send = 'recorded'
            //   this.send_message()
            // this.saveAudio()
            }
          };
          this.mediaRecorder.start();
          this.timer = 60;
      this.startTimer();
          setTimeout(()=>{
            this.stopRecording();
          },1000*60)
        })
        .catch((error) => {
          console.error('Error accessing microphone:', error);
        });
    },
    stopRecording() {
      if (this.mediaRecorder && this.isRecording) {
        this.mediaRecorder.stop();
        this.countDown = 60
        // console.log(" this.audioChunks" ,  this.audioChunks)
        // Stop the timer
      this.stopTimer();
      }
    },
    async sendAudio(time) {
      if (this.audioChunks.length === 0) {
        swal({
                text: 'No audio to send',
                toast: true,
                showCancelButton: false,
                showConfirmButton: false,
                type: "warning",
                timer: 2000,
                position:'top-end'
              });
        return;
      }
      let duration = 60 - time
      const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
        
        const fd1 = new FormData();
              //
         fd1.append('license_key',this.user_data.license_key)
         fd1.append('token',this.bot_token_response_value)
         fd1.append('username',this.user_data.username)
         fd1.append('source','Web')
         fd1.append('audio_duration',duration)

        //  fd1.append('email',this.email)
          fd1.append('audio', audioBlob, 'audio.wav');
        //  console.log(fd , "==-=-=-")
         this.isSpeechSpinner = true
         axios
         .post(api_calls.speech_recognition(), fd1, {
           retry:3 , retryDelay:3000
          }).then(({data}) => {
          //   return {
          // }
          this.isSpeechSpinner = false
          this.isRecording = false;
          this.is_typing_indicator_on = false


          if(10000000 === data.message.MSG_CODE){
            this.isSpeechError = true;
            this.speechError = 'Try again!'
            setTimeout(()=>{
              this.isSpeechError = false
              this.speechError = ''
            },2000)
            swal({
                text: 'Please try again',
                toast: true,
                showCancelButton: false,
                showConfirmButton: false,
                type: "warning",
                timer: 2000,
                position:'top-end'
              });
          }else if(10000001 === data.message.MSG_CODE){
            this.to_send = data.data.transcript
            this.send_message()
          }else if(10001920 === data.message.MSG_CODE){
            this.isSpeechError = true;
            this.speechError = 'Temporary unavailable!'
            setTimeout(()=>{
              this.isSpeechSubscribed = false
              this.isSpeechError = false
              this.speechError = ''
            },2000)
            swal({
                text: 'Temporary unavailable!',
                toast: true,
                showCancelButton: false,
                showConfirmButton: false,
                type: "warning",
                timer: 2000,
                position:'top-end'
              });
          }
          }).catch(e=>{
            this.isSpeechSpinner = false
            console.log(e);
          if (e.response) {
            if (
              e.response.status === 410 ||
              e.response.status === 440 ||
              e.response.status === 409
            ) {
              this.$root.$emit("Session_Expired", e.response.data);
            }
          }
          })

     
    },
      // ************ Login Initials ***************
  
      get_bot_channel() {
      
        axios
            .post(api_calls.get_bot_channel(), {
              license_key: this.user_data.license_key,
              username: this.user_data.username,
              token: this.bot_token_response_value,
            })
            .then((response) => {
              if (
                response.data.message.MSG_CODE === 10000010
              ) {
                this.$session.remove("BotResponse_Conversation");
                this.chat_widget_login();
              } else if(response.data.message.MSG_CODE === 10000001) {
                this.bot_channel_type = {
                  'supported_channel_type' : response.data.data.supported_channel_type,
                  'link' : response.data.data.link
                } 
                this.hide_bot = response.data.data.hide_bot
              }else if(response.data.message.MSG_CODE === 10000470) {
                this.bot_channel_type = {
                  'supported_channel_type' : '',
                  'link' : ''
                } 
                this.hide_bot = true
              } else if(response.data.message.MSG_CODE === 10000000) {
                this.bot_channel_type = {
                  'supported_channel_type' : '',
                  'link' : ''
                } 
                this.hide_bot = true
              } else {
                // console.log(response.data.message.MSG);
              }
            });
      },
      save_retail_cart_info(){
        if(Boolean(this.get_woocommerce_store_window_customer_email()) === true){
          let curr = this.user_data.woo_current_user;
          axios
            .post(api_calls.save_retail_cart_info(), {
              license_key: this.user_data.license_key,
              token: this.$session.get("bot_login_token"),
              username: this.user_data.username,
              user_data: {
                user_email: curr.data.user_email,
                ID: curr.data.ID,
                display_name: curr.data.display_name,
              },
              cart_contents : this.user_data.woo_current_user_cart.cart_contents
            });
        }
      },
      get_widget_style_from_session() {
        let chat_widget_styling_cache = this.$session.get("chat_widget_style");
        if (Boolean(chat_widget_styling_cache) === true) {
          this.chat_widget = chat_widget_styling_cache;
          this.contact_help_email = this.$session.get("contact_help_email");
          this.retail_web_framework = this.$session.get(
            "session_retail_web_framework"
          );
          this.bot_templates_details = this.$session.get(
            "session_bot_templates_details"
          );
          this.review_message_first = this.$session.get(
            "session_review_message_first"
          );
          this.review_message_second = this.$session.get(
            "session_review_message_second"
          );
          this.cart_custom_product_details = this.$session.get(
            "session_cart_custom_product_details"
          );
          this.custom_product_button_key = this.$session.get(
            "session_custom_product_button_key"
          );
          this.review_response_message = this.$session.get(
            "session_review_response_message"
          );
          this.shopify_retail_shop_name = this.$session.get(
            "session_shopify_retail_shop_name"
          );
          this.retail_shop_currency = this.$session.get(
            "session_retail_shop_currency"
          );
          this.retail_order_retreival_only_email_required = this.$session.get(
            "session_retail_order_retreival_only_email_required"
          );
          this.is_display_banner_on_landing = this.$session.get(
            "session_is_display_banner_on_landing"
          );
        }
      },
      check_bot_login_token() {

            
            let bot_login_token = this.$session.get("bot_login_token")
            if (!Boolean(bot_login_token)) {
              this.chat_widget_login();
            } else {
              this.bot_token_response_value = this.$session.get("bot_login_token");
              this.get_bot_channel();
              this.methods_to_call_after_chat_widget_login();
              this.show_widget = true;
              axios
                .post(api_calls.chatbot_response(), {
                  host: this.user_data.host,
                  license_key: this.user_data.license_key,
                  username: this.user_data.username,
                  source: "Web",
                  token: this.bot_token_response_value,
                  chat: "",
                  refreshed_or_closed: this.refreshed_or_closed,
                })
                .then((response) => {
                  if (
                    response.data.responses[0].text.includes(
                      "Token is missing or invalid"
                    )
                  ) {
                    this.$session.remove("BotResponse_Conversation");
                    this.chat_widget_login();
                  }
                });
            }
      },
      chat_widget_login() {
        axios
          .post(api_calls.chat_widget_login(), this.user_data)
          .then((response) => {
            this.bot_token_response_value = response.data.data;
            this.$session.set("bot_login_token", this.bot_token_response_value);
            this.show_widget = true;
            
  
            axios
              .post(api_calls.chatbot_response(), {
                host: this.user_data.host,
                license_key: this.user_data.license_key,
                username: this.user_data.username,
                token: this.bot_token_response_value,
                chat: "",
                source: "Web",
                refreshed_or_closed: this.refreshed_or_closed,
              })
              .then((response) => {
                this.methods_to_call_after_chat_widget_login();
  
              });
          });
      },
      get_widget_theme(license_key) {


  
            this.show_bot = false;
            axios
              .post(api_calls.get_widget_theme(), {
                license_key
              })
              .then((response) => {
                this.chat_widget.WidgetIntegrationButtons = [];
                if (response.data.message.MSG_CODE === 10000480) {
                  this.chat_widget.title = "Hi. How may I help you?";
                } else if(response.data.message.MSG_CODE === 10000001){
                  this.chat_widget.title = response.data.data.Title;
                  this.chat_widget.logo = response.data.data.ImageUrl;
                  this.chat_widget.bot_theme = response.data.data.BotTheme;
                  this.chat_widget.button_theme = response.data.data.ButtonTheme;
                  this.chat_widget.button_border_theme =
                    response.data.data.ButtonBorderTheme === null
                      ? this.chat_widget.button_border_theme
                      : response.data.data.ButtonBorderTheme;
                  this.chat_widget.user_response_bg_color =
                    response.data.data.UserResponseBgColor === null
                      ? this.chat_widget.user_response_bg_color
                      : response.data.data.UserResponseBgColor;
                  this.chat_widget.bot_response_bg_color =
                    response.data.BotResponseBgColor === null
                      ? this.chat_widget.bot_response_bg_color
                      : response.data.data.BotResponseBgColor;
                  this.chat_widget.delivery_note_color =
                    response.data.data.DeliveryNoteColor === null
                      ? this.chat_widget.delivery_note_color
                      : response.data.data.DeliveryNoteColor;
                  this.chat_widget.bot_icon = response.data.data.BotImageUrl;
                  this.chat_widget.button_alignment = response.data.data.BotStyling;
                  this.chat_widget.button_vertical_spacing =
                    response.data.data.ButtonStyling.vertical;
                  this.chat_widget.button_horizontal_spacing =
                    response.data.data.ButtonStyling.horizontal;
                  this.chat_widget.background_image = response.data.data.BgImageUrl;
                  // console.log(response.data.data.BgImageUrl);
                  this.initial_bot_greetings = response.data.data.BotGreetings;
                  // let resp = response.data.data.BotGreetings.data.responses
                  // console.log("🚀 ~ file: BotChat.vue:2333 ~ .then ~ resp:", resp)
                  // let is_btns = resp[resp.length -1].buttons.length
                  // if(is_btns > 0){
                  //   let body = document.getElementById('widget_chat_body_id')
                  //   body.minHeight = '50vh'
                  //   body.maxHeight = '50vh'
                  //   body.height = '50vh'
                  // }else{
                  //   let body = document.getElementById('widget_chat_body_id')
                  //   body.minHeight = '70vh'
                  //   body.maxHeight = '70vh'
                  //   body.height = '70vh'
                  // }

                  this.chat_widget.header_background = response.data.data.HeaderTheme;
                  this.chat_widget.bot_font_style = response.data.data.BotFont.FontStyle;
                  this.chat_widget.bot_font_color_sender =
                    response.data.data.BotFont.FontColorSender;
                  this.chat_widget.bot_font_color_receiver =
                    response.data.data.BotFont.FontColorReceiver;
                  this.chat_widget.bot_font_color_buttons =
                    response.data.data.BotFont.FontColorButtons;
                  this.chat_widget.bot_open_by_default =
                    response.data.data.BotOpenByDefault || false;
                  this.chat_widget.bot_shown_on_website =
                    response.data.data.BotShownOnWebsite || true;
                  this.chat_widget.is_powered_by_cense =
                    response.data.data.IsPoweredByCense === undefined
                      ? this.chat_widget.is_powered_by_cense
                      : response.data.data.IsPoweredByCense;
                      this.chat_widget.is_wider_view =
                    response.data.data.is_wider_view === undefined
                      ? this.chat_widget.is_wider_view
                      : response.data.data.is_wider_view;   
                      this.isSpeechSubscribed =  response.data.data.enable_voice_bot  
                      this.autoAudioEnabled =  response.data.data.auto_audio_response
             this.chat_widget.show_bot_history =
                    response.data.data.ShowBotHistory === undefined
                      ? true
                      : response.data.data.ShowBotHistory;
                  this.chat_widget.WidgetIntegrationButtons = response.data.data.WidgetIntegrationButtons === [] 
                    ? [] : response.data.data.WidgetIntegrationButtons.filter(item => item.visible !== false)
                  // console.log(this.chat_widget.WidgetIntegrationButtons);
                  if (response.data.data.WidgetIconUrl != null) {
                      this.chat_widget.bot_inactive_button_img =
                      response.data.data.WidgetIconUrl;
                  }else{
                    this.chat_widget.bot_inactive_button_img = 'https://storage.cense.ai/CenseAI/new_widget_icon.png'
                  }
                  if (response.data.data.WidgetIconUrlOnClose != null ) {
                    this.chat_widget.bot_active_button_img =
                    response.data.data.WidgetIconUrlOnClose;
                  }else{
                     this.chat_widget.bot_active_button_img = 'https://storage.cense.ai/codearray/close-img.png'
                  }
                  if (response.data.data.Position) {
                    if (response.data.data.Position.align.value === "right") {
                      this.chat_widget.right_position =
                        response.data.data.Position.horizontal;
                      this.chat_widget.left_position = null;
                      this.chat_widget.bottom_position =
                        response.data.data.Position.vertical;
                    } else if (response.data.data.Position.align.value === "left") {
                      this.chat_widget.left_position =
                        response.data.data.Position.horizontal;
                      this.chat_widget.right_position = null;
                      this.chat_widget.bottom_position =
                        response.data.data.Position.vertical;
                    }
                  }
                  let styling_value = response.data.data.HeaderStyling.value;
                  let temp_string = "";
                  if (styling_value === "left") {
                    temp_string =
                      "margin-left: 2px !important;margin-right: auto !important;";
                  } else if (styling_value === "right") {
                    temp_string =
                      "margin-left: auto !important;margin-right: 10px !important;";
                  } else if (styling_value === "center") {
                    temp_string =
                      "margin-left: auto !important;margin-right: auto !importantload_widget;";
                  } else {
                    temp_string =
                      "margin-left: auto !important;margin-right: auto !important;";
                  }
                  this.chat_widget.style = temp_string;
                  this.$session.set("chat_widget_style", this.chat_widget);

                  if (
                    this.chat_widget.bot_open_by_default === true ||
                    this.user_data.is_app_open === true
                  ) {
                    this.load_widget();
                  }
                } else {
                  // console.log(response.data.message.MSG)
                }
                this.show_bot = true;
              })
              .catch((e) => {
                console.error(e);
                this.chat_widget.bot_shown_on_website = false;
                this.chat_widget.title = "Hi. How may I help you?";
                this.chat_widget.logo =
                  "https://storage.cense.ai/codearray/cense_image.png";
                this.chat_widget.bot_theme = "#283677";
                this.chat_widget.button_theme = "#283677";
                this.chat_widget.button_border_theme = "#283677";
                this.chat_widget.user_response_bg_color = "#283677";
                this.chat_widget.bot_response_bg_color = "#ffffff";
                this.chat_widget.delivery_note_color = "#283677";
                this.chat_widget.bot_icon = "https://portal.cense.ai/img/Bot_img.png";
                this.chat_widget.button_alignment = "flex-start";
                this.chat_widget.bot_font_style = "Roboto";
                this.chat_widget.bot_font_color_sender = "#ffffff";
                this.chat_widget.bot_font_color_receiver = "#000000";
                this.chat_widget.bot_font_color_buttons = "#000000";
                this.chat_widget.WidgetIntegrationButtons = [];
              });

      },
      methods_to_call_after_chat_widget_login() {
        
        // this.load_buttons();
        this.get_bot_channel();
        this.check_bot_templates_subscription();
        this.get_feedback_questions();
        this.initialize_time_zone();
        this.get_widget_theme(this.user_data.license_key);
      },
      get_feedback_questions() {
        this.rating = [];
        this.feedback_question_list = [];
        axios
          .post(api_calls.send_user_product_feedback(), {
            license_key: this.user_data.license_key,
            token: this.bot_token_response_value,
            username: this.user_data.username,
            is_get: true,
          })
          .then((response) => {
            this.feedback_question_list = [];
            if (response.data.message.MSG_CODE === 20000331) {
                this.feedback_question_list = response.data.data.data;
            } else {
              // console.log(response.data.message.MSG)
            }
          });
      },
      initialize_time_zone() {
        var d = new Date();
        axios
          .post(api_calls.bot_user_data_url(), {
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone,
            datetime: d.toISOString(),
            host: this.user_data.host,
            license_key: this.user_data.license_key,
            token: this.bot_token_response_value,
            username: this.user_data.username,
            source: this.user_data.source,
          })
          .then((response) => {});
      },
      getText( id , i , array , len){

      if(len + 1 > this.previous_length){
        setTimeout(()=>{
          const formattedText = document.getElementById(id);
          const text = array[len].received; // Your formatted text with HTML tags here
          let index = 0;
          let vm = this
          function typeWriter() {
            if (index < text.length) {
              formattedText.innerHTML =  text.slice(0, index + 1);
              index++;
              vm.scroll_down_bottom(id)
              setTimeout(typeWriter, 20); // Adjust the typing speed here (in milliseconds)
            } else {
              formattedText.innerHTML = text;;
              formattedText.removeAttribute('style'); 
            }
          }
         
          this.scroll_down_bottom()
          this.previous_length = array.length;
          typeWriter();
        },10)
      }
    },
    scroll_down_bottom(id) {
      setTimeout(() => {
        var container = document.getElementById('widget_chat_body_id');
        if (Boolean(container.scrollHeight)) {
          setTimeout(() => {
            // //console.log("here scr");
            container.scrollTop = container.scrollHeight - 50;
          }, 100);
          // this.initial_message_load = true;
        }
      }, 100);
    },
      load_widget(val) {
        if (!this.license_key_invalid) {
          if (
            !this.refreshed_or_closed &&
            Boolean(this.check_storefront_token) === true
          ) {
            this.spinnerOn = false;
            var master = document.getElementById("cense_app");
            var div = document.getElementById("button_class"); 
            var div1 = document.getElementById("widget_chat_body_id"); 
            let doc;
            setTimeout(()=>{

               doc = document.getElementById('custom-btn-subdiv')
               let doc1 = document.getElementsByClassName('bot-font-buttons')
              if(doc===null && doc1 === null){
    
                div1.style.minHeight = '68vh'
                div1.style.maxHeight = '68vh'
                div1.style.height = '68vh'
              }else if(doc!==null && doc.children.length === 0 ){
    

                div1.style.minHeight = '68vh'
                div1.style.maxHeight = '68vh'
                div1.style.height = '68vh'
               }else if(doc!==null && doc.children.length >0 && doc.children.length <=3 ){
    

                div1.style.minHeight = '58vh'
                div1.style.maxHeight = '58vh'
                div1.style.height = '58vh'
               }else if(doc1!==null && doc1.length === 0 ){

                div1.style.minHeight = '68vh'
                div1.style.maxHeight = '68vh'
                div1.style.height = '68vh'
               }else if( doc1!==null && doc1.length >0 && doc1.length <=3 ){
    

                div1.style.minHeight = '58vh'
                div1.style.maxHeight = '58vh'
                div1.style.height = '58vh'
               }else if(doc!==null &&  doc.children !== undefined && doc.children.length > 3 ){
    
                div1.style.minHeight = '52vh'
                div1.style.maxHeight = '52vh'
                div1.style.height = '52vh'
              }else if(doc1!==null && doc1.length > 3 ){
    

                div1.style.minHeight = '52vh'
                div1.style.maxHeight = '52vh'
                div1.style.height = '52vh'
              }else{

                div1.style.minHeight = '68vh'
                div1.style.maxHeight = '68vh'
                div1.style.height = '68vh'
              }
            },1000)
            if (
              div !==null &&
              !div.classList.contains("active") ||
              div.classList.contains("inactive") ||
              !this.data_loaded
            ) {
              this.data_loaded = true;
              this.load_chat_window = true;
              div.classList.remove("inactive");
              div.classList.add("active");
              master.classList.add("active");

              master.classList.remove("inactive");
              setTimeout(() => {
                if (this.chat.length == 0) {
                  /* ***************************************************
                  The following code is written to load the history of the chat back into the bot in case
                  the page reloads. 
                  Also, the chat history will be visible in the bot for 24 hrs. After that new chat session will be started
                  ********************************* */
                  var bot_history = this.$session.get("BotResponse_Conversation"),
                    bot_history_timeline = this.$session.get(
                      "BotResponse_Conversation_Timeline"
                    );
                  if (!Boolean(bot_history_timeline)) {
                    let new_time_for_timeline = new Date().toISOString();
                    this.$session.set(
                      "BotResponse_Conversation_Timeline",
                      new_time_for_timeline
                    );
                  } else {
                    let current_time = new Date().toISOString();
                    let time_difference = this.get_time_difference(
                      current_time,
                      bot_history_timeline
                    );
                    if (time_difference > 24) {
                      bot_history = null;
                      let new_bot_history_time_for_timeline =
                        new Date().toISOString();
                      this.$session.set(
                        "BotResponse_Conversation_Timeline",
                        new_bot_history_time_for_timeline
                      );
                    }
                  }
                  if (
                    Boolean(this.initial_bot_greetings) &&
                    Boolean(
                      this.initial_bot_greetings.data.responses.length > 0
                    ) === true
                  ) {
                    var is_visible_greetings =
                      this.initial_bot_greetings.data.responses.map((item) => {
                        return item.visible !== undefined ? item.visible : true;
                      });
                    if (is_visible_greetings.includes(true)) {
                      this.greeting_buttons_position =
                        this.initial_bot_greetings.data.responses.length - 1;
                      if (
                        Boolean(
                          this.initial_bot_greetings.data.responses[
                            this.greeting_buttons_position
                          ]["buttons"]
                        )
                      ) {
                        var visible_buttons =
                          this.initial_bot_greetings.data.responses[
                            this.greeting_buttons_position
                          ]["buttons"];
                        visible_buttons = visible_buttons.filter((button) => {
                          return button["visible"] === true;
                        });
                        this.initial_bot_greetings.data.responses[
                          this.greeting_buttons_position
                        ]["buttons"] = visible_buttons;
                      }
                      const visible_greetings_map = is_visible_greetings.reduce(
                        (acc, e) => acc.set(e, (acc.get(e) || 0) + 1),
                        new Map()
                      );
                      let splice_index = [];
                      if (
                        visible_greetings_map.get(true) !==
                        this.initial_bot_greetings.data.responses.length
                      ) {
                        if (
                          this.initial_bot_greetings.data.responses[
                            this.greeting_buttons_position
                          ].visible === false
                        ) {
                          try {
                            for (
                              let i =
                                this.initial_bot_greetings.data.responses.length -
                                1;
                              i >= 0;
                              i--
                            ) {
                              if (
                                this.initial_bot_greetings.data.responses[i]
                                  .visible === false
                              ) {
                                this.initial_bot_greetings.data.responses[
                                  i - 1 < 0 ? i : i - 1
                                ]["buttons"] = visible_buttons;
                                this.greeting_buttons_position =
                                  this.greeting_buttons_position - 1 < 0
                                    ? this.greeting_buttons_position
                                    : this.greeting_buttons_position - 1;
                                splice_index.push(i);
                              }
                            }
                          } catch (error) {
                            console.log(error);
                          }
                        } else {
                          for (
                            let i =
                              this.initial_bot_greetings.data.responses.length -
                              1;
                            i >= 0;
                            i--
                          ) {
                            if (
                              this.initial_bot_greetings.data.responses[i]
                                .visible === false
                            ) {
                              this.greeting_buttons_position =
                                this.greeting_buttons_position - 1 < 0
                                  ? this.greeting_buttons_position
                                  : this.greeting_buttons_position - 1;
                              splice_index.push(i);
                            }
                          }
                        }
                        for (
                          let index = 0;
                          index < splice_index.length;
                          index++
                        ) {
                          this.initial_bot_greetings.data.responses.splice(
                            splice_index[index],
                            1
                          );
                        }
                      }
                    }
                  }
                  if (!Boolean(bot_history)) {
                    if (
                      Boolean(this.initial_bot_greetings) &&
                      Boolean(
                        this.initial_bot_greetings.data.responses.length > 0
                      ) === true
                    ) {
                      if (is_visible_greetings.includes(true)) {
                        this.initial_bot_greetings.data.responses.map(
                          (item) => (item.is_greeting = true)
                        );
                        this.push_msg(
                          this.initial_bot_greetings,
                          this.initial_bot_greetings.data.responses
                        );
                      }
                    } else {
                      this.welcome_message_not_yet_received = false;
                      this.methods_to_call_after_greetings_when_not_bot_history();
                    }
                  } else {
                    if (
                      Boolean(this.initial_bot_greetings) &&
                      Boolean(
                        this.initial_bot_greetings.data.responses.length > 0
                      ) === true
                    ) {
                      if (is_visible_greetings.includes(true)) {
                        bot_history[this.greeting_buttons_position][
                          "buttons_list"
                        ] = visible_buttons;
                      }
                    }
                    const greetings_indices = bot_history
                      .map((greeting, i) =>
                        greeting.is_greeting === true ? i : -1
                      )
                      .filter((index) => index !== -1);
                    if (greetings_indices.length > 0) {
                      bot_history.splice(
                        greetings_indices[0],
                        greetings_indices.length
                      );
                      let initial_greeting_bot_structure =
                        this.get_greetings_structure(
                          this.initial_bot_greetings,
                          this.initial_bot_greetings.data.responses
                        );
                      for (
                        let i = initial_greeting_bot_structure.length - 1;
                        i >= 0;
                        i--
                      ) {
                        bot_history.unshift(initial_greeting_bot_structure[i]);
                      }
                    }
                    for (let i = 0; i < bot_history.length; i += 1) {
                      if (bot_history[i].is_products_list === true) {
                        this.show_custom_cart = true;
                        bot_history[i].new_products = true;
                        for (var j in bot_history[i].products_list) {
                          bot_history[i].products_list[j].order_qty = 1;
                          bot_history[i].products_list[j].buy_qty = 1;
                        }
                      }
                      if (Boolean(bot_history[i].is_review_rating_product)) {
                        bot_history.splice(i, 1);
                      }
                      if (
                        Boolean(!this.chat_widget.show_bot_history) &&
                        bot_history[i].sending === true
                      ) {
                        break;
                      }
                      this.chat.push(bot_history[i]);
                    }
                    this.methods_to_call_after_greetings_when_bot_history();
                    this.is_typing_indicator_on = false;
                    this.welcome_message_not_yet_received = false;
                    this.update_scrollbar("upto_end", "response");
                    this.get_all_buttons()
                    let indexes = []
                    let chatfiltered = this.chat.filter((ch,index)=>{
                      if(ch.receiving === true){
                        indexes.push(index)
                      }
                      return ch.receiving === true
                    })
                  
                    chatfiltered.forEach((ch , index)=>{
                      this.getText("recieved"+indexes[index],indexes.length,chatfiltered , index)
                    })
                  }
                }
              }, 500);
            } else if ( div !== null && div.classList.contains("active")) {
              if (val !== 'close') {
                this.send_message('clicked')
              } else {
                master.classList.add("inactive");
                master.classList.remove("active");
                div.classList.remove("active");
                div.classList.add("inactive");
                setTimeout(() => {
                this.$refs["chat-wrap"].style.display = "none";
                this.load_chat_window = false;
              }, 500);
              }
            }
          } else {
            let vm = this;
            setTimeout(function () {
              vm.load_widget();
            }, 100);
            var master = document.getElementById("cense_app");
            var div = document.getElementById("button_class");
            if (
              div !== null &&
              !div.classList.contains("active") ||
              div.classList.contains("inactive")
            ) {
              this.spinnerOn = true;
              this.load_chat_window = true;
              div.classList.remove("inactive");
              div.classList.add("active");
              master.classList.add("active");
              master.classList.remove("inactive"); 
            }
          }
        }     

      },
      get_all_buttons() {
        // console.log(this.greeting_buttons_position);
        // console.log(this.chat[this.greeting_buttons_position])
        if(Boolean(this.chat[this.greeting_buttons_position]) && Boolean(this.chat[this.greeting_buttons_position].greeting_button === true) && this.chat_widget.WidgetIntegrationButtons.length !== 0 ) {
          // console.log('runn')
          this.chat_widget.WidgetIntegrationButtons.map( item => this.chat[this.greeting_buttons_position].buttons_list.push(item))
        } 
        // this.chat_widget.WidgetIntegrationButtons.map( item => this.chat[this.greeting_buttons_position].buttons_list.push(item))
        // console.log(this.chat[this.greeting_buttons_position]);
        // console.log(this.chat_widget.WidgetIntegrationButtons);
      },
      check_bot_templates_subscription() {
        const vm = this;
        axios
          .post(api_calls.open_bot_templates_subscription_data(), {
            license_key: this.user_data.license_key,
            token: this.$session.get("bot_login_token"),
            username: this.user_data.username,
          })
          .then((response) => {
            // this.show_widget = true;
            if (response.data.message.MSG_CODE === 20000331) {
              this.company_name = response.data.data.company;
              this.bot_templates_details = response.data.data.templates;
              this.retail_web_framework =
                response.data.data.shop_details.retail_web_framework;
              this.show_custom_cart =
                this.retail_web_framework != null ? true : false;
              this.$session.set(
                "session_retail_web_framework",
                this.retail_web_framework
              );
              this.$session.set(
                "session_bot_templates_details",
                this.bot_templates_details
              );
              this.contact_help_email =
                response.data.data.shop_details.contact_help_email;
              this.$session.set(
                "session_contact_help_email",
                this.contact_help_email
              );
              this.review_message_first =
                response.data.data.shop_details.review_message_first;
              this.$session.set(
                "session_review_message_first",
                this.review_message_first
              );
              this.review_message_second =
                response.data.data.shop_details.review_message_second;
              this.$session.set(
                "session_review_message_second",
                this.review_message_second
              );
              this.review_response_message =
                response.data.data.shop_details.review_response_message;
              this.$session.set(
                "session_review_response_message",
                this.review_response_message
              );
              if (this.retail_web_framework == "woocommerce")
                this.shop_url = response.data.data.shop_details.woocommerce_shop_url;
              if (this.retail_web_framework == "magento")
                this.shop_url = response.data.data.shop_details.magento_shop_url;
              if (this.retail_web_framework == "bigcommerce")
                this.shop_url = response.data.data.shop_details.bigcommerce_shop_url;
              this.$session.set("session_shop_url", this.shop_url);
              this.custom_product_button_key =
                response.data.data.shop_details.custom_product_button_key ==
                  undefined || null
                  ? []
                  : response.data.data.shop_details.custom_product_button_key;
              this.$session.set(
                "session_custom_product_button_key",
                this.custom_product_button_key
              );
              if (this.retail_web_framework == "shopify") {
                this.shopify_retail_shop_name =
                  response.data.data.shop_details.shopify_shop_name;
                this.$session.set(
                  "session_shopify_retail_shop_name",
                  this.shopify_retail_shop_name
                );
                this.cart_custom_product_details =
                  response.data.data.shop_details.cart_custom_product_details;
                this.$session.set(
                  "session_cart_custom_product_details",
                  this.cart_custom_product_details
                );
                this.get_shopify_website_cart();
              }
              this.retail_shop_currency =
                response.data.data.shop_details.base_currency.value || "USD";
              this.$session.set(
                "session_retail_shop_currency",
                this.retail_shop_currency
              );
              this.retail_order_retreival_only_email_required =
                response.data.data.shop_details.order_retrieval_only_email_required ||
                false;
              this.$session.set(
                "session_retail_order_retreival_only_email_required",
                this.retail_order_retreival_only_email_required
              );
              this.display_products_on_landing =
                response.data.data.shop_details.display_products_on_landing;
              this.is_display_banner_on_landing =
                response.data.data.shop_details.is_display_banner_on_landing;
              this.$session.set(
                "session_is_display_banner_on_landing",
                this.is_display_banner_on_landing
              );
              check_retail();
            } else {
              this.refreshed_or_closed = false;
              // console.log(response.data.message.MSG);
            }
            this.check_storefront_token = true;
          })
          .catch((e) => {
            // this.show_widget = true;
            this.check_storefront_token = true;
            this.refreshed_or_closed = false;
          });
        const check_retail = () => {
          if (vm.bot_templates_details && vm.bot_templates_details.length > 0) {
            for (let i = 0; i < vm.bot_templates_details.length; i++) {
              if (
                vm.bot_templates_details[i].domain === "Retail" &&
                vm.bot_templates_details[i].subscribed === true
              ) {
                vm.is_retail_bot = true;
                break;
              }
            }
          }
          vm.refreshed_or_closed = false;
        };
      },
      // ************* Features *********************
  
      is_display_banner() {
        axios
          .get(api_calls.banner_template(), {
            params: {
              license_key: this.user_data.license_key,
              is_display_banner: true,
              visitors_current_location: this.visitors_current_location,
              token: this.$session.get("bot_login_token"),
              username: this.user_data.username,
            },
          })
          .then((response) => {
            if (response.data.message.MSG_CODE === 20000331) {
              if (Boolean(response.data.data.responses.length > 0)) {
                this.response_handling(response.data);
              }
            } else {
              // console.log(response.data.message.MSG);
            }
            this.is_display_products();
          })
          .catch((e) => {
            this.is_display_products();
            if (
              e.response.status === 410 ||
              e.response.status === 440 ||
              e.response.status === 409
            ) {
              this.$root.$emit("Session_Expired", e.response.data);
            }
          });
      },
      take_user_feedback() {
        let is_feedback_asked = Cookies.get(
          "cense_app_widget_user_feedback_asked"
        );
        if (!Boolean(is_feedback_asked)) {
          Cookies.set("cense_app_widget_user_feedback_asked", true, {
            expires: 90,
          });
          if (
            !Object.keys(this.feedback_question_list).every((o) => o === null)
          ) {
            $("#feedbackquestionsmodel").modal("show");
          }
        }
      },
      send_feedback() {
        let result = !Object.values(this.feedback_question_list).every(
          (o) => o === null
        );
        if (result) {
          axios
            .post(api_calls.send_user_product_feedback(), {
              license_key: this.user_data.license_key,
              user_feedback: this.feedback_data_list,
              is_save: true,
              token: this.bot_token_response_value,
              username: this.user_data.username,
            })
            .then((response) => {
              if(response.data.message.MSG_CODE === 20000261){
                swal({
                  text: "Thank You for your feedback.!",
                  type: "success",
                  showConfirmButton: false,
                  showCancelButton: false,
                  showCloseButton: true,
                  timer: 2500,
              });
              this.feedback_data_list = [],
              $("#feedbackquestionsmodel").modal("hide");
              this.get_feedback_questions();
              } else {
                // console.log(response.data.message.MSG);
              }
            });
        } else {
          $("#feedbackquestionsmodel").modal("hide");
          this.get_feedback_questions();
        }
      },
  
      // ************* Retail ***********************
      is_display_products() {
        if (this.display_products_on_landing) {
          axios
            .post(api_calls.display_landing_products(), {
              license_key: this.user_data.license_key,
              token: this.bot_token_response_value,
              username: this.user_data.username,
              source: "Web",
            })
            .then((response) => {
              if(response.data.message.MSG_CODE === 20000331) {
                var products_count =
                response.data.data.responses[0].products.products_list.length;
                if (products_count > 0) {
                  this.response_handling(response.data);
                }
                this.is_review_rating();
              }
            })
            .catch((e) => {
              this.is_review_rating();
              console.log(e);
            });
        } else {
          this.is_review_rating();
        }
      },
      addproduct(product) {
        this.total_products_qty = 0;
        this.over_qty_warning = false;
        var audio = new Audio(sound);
        audio.play();
        var cart = $("#cense-cart-btn");
        var cart_qty = $("#cart-qty-num");
        var cart_img = $("#cart-img");
        if (this.addtoCartdata.length != 0 && product.buy_qty !== "") {
          var check = false;
          for (var i in this.addtoCartdata) {
            if (this.addtoCartdata[i].id == product.id) {
              check = true;
              var buy = product.buy_qty + this.addtoCartdata[i].order_qty;
              if (
                product.stock_quantity > this.addtoCartdata[i].order_qty &&
                buy <= product.stock_quantity
              ) {
                this.addtoCartdata[i].order_qty = buy;
                product.order_qty = 1;
                setTimeout(function () {
                  cart_qty.addClass("cart-qty");
                  cart_img.addClass("cart-img");
                  cart.addClass("shake");
                  setTimeout(function () {
                    cart_qty.removeClass("cart-qty");
                    cart_img.removeClass("cart-img");
                    cart.removeClass("shake");
                  }, 500);
                }, 0);
              } else if (product.stock_quantity == null ||
              (product.stock_quantity == 0 && product.stock_status === "instock")) {
                this.addtoCartdata[i].order_qty = buy;
                product.order_qty = 1;
                setTimeout(function () {
                  cart_qty.addClass("cart-qty");
                  cart_img.addClass("cart-img");
                  cart.addClass("shake");
                  setTimeout(function () {
                    cart_qty.removeClass("cart-qty");
                    cart_img.removeClass("cart-img");
                    cart.removeClass("shake");
                  }, 500);
                }, 0);
              } else {
                product.order_qty = 2;
                let res =
                  product.stock_quantity - this.addtoCartdata[i].order_qty;
                product.buy_qty = res === 0 ? 1 : res;
                this.over_qty_warning = true;
              }
            }
          }
          if (
            check != true &&
            (product.buy_qty <= product.stock_quantity ||
              product.stock_quantity == null  ||
              (product.stock_quantity == 0 && product.stock_status === "instock"))
          ) {
            this.addtoCartdata.push({
              id: product.id,
              img_url: product.img_url,
              price: product.price,
              stock_quantity: product.stock_quantity,
              order_qty: product.buy_qty,
              sku: this.retail_web_framework == 'bigcommerce' ? product.sku : '',
              variant_title: product.variant_title,
              buy_qty: 1,
              stock_status: product.stock_status,
              title: product.title,
            });
            product.order_qty = 1;
            setTimeout(function () {
              cart_qty.addClass("cart-qty");
              cart_img.addClass("cart-img");
              cart.addClass("shake");
              setTimeout(function () {
                cart_qty.removeClass("cart-qty");
                cart_img.removeClass("cart-img");
                cart.removeClass("shake");
              }, 500);
            }, 0);
          } else if (check != true && product.buy_qty > product.stock_quantity) {
            this.over_qty_warning = true;
          }
        } else if (
          (product.buy_qty <= product.stock_quantity && product.buy_qty !== "") ||
          product.stock_quantity == null  ||
          (product.stock_quantity == 0 && product.stock_status === "instock")
        ) {
          this.addtoCartdata.push({
            id: product.id,
            img_url: product.img_url,
            price: product.price,
            stock_quantity: product.stock_quantity,
            order_qty: product.buy_qty,
            variant_title: product.variant_title,
            sku: this.retail_web_framework == 'bigcommerce' ? product.sku : '',
            buy_qty: 1,
            stock_status: product.stock_status,
            title: product.title,
          });
          setTimeout(function () {
            cart_qty.addClass("cart-qty");
            cart_img.addClass("cart-img");
            cart.addClass("shake");
            setTimeout(function () {
              cart_qty.removeClass("cart-qty");
              cart_img.removeClass("cart-img");
              cart.removeClass("shake");
            }, 500);
          }, 0);
        } else {
          this.over_qty_warning = true;
        }
        for (var j in this.addtoCartdata) {
          this.total_products_qty =
            this.total_products_qty + this.addtoCartdata[j].order_qty;
        }
        this.$session.set("store_cart_data", this.addtoCartdata);
        if (
          Boolean(this.addtoCartdata.length > 0) &&
          this.retail_web_framework === "shopify"
        ) {
          this.update_cense_cart_data_to_shopify_website_cart(this.addtoCartdata);
        }
      },
      update_variation(chat_id, product_index, title) {
        for (var i in this.chat[chat_id].products_list[product_index]
          .variations) {
          if (
            this.chat[chat_id].products_list[product_index].variations[i]
              .variant_title == title
          ) {
            this.chat[chat_id].products_list[product_index].img_url =
              this.chat[chat_id].products_list[product_index].variations[
                i
              ].img_url;
            if(this.retail_web_framework == 'bigcommerce') {
              this.chat[chat_id].products_list[product_index].sku =
                this.chat[chat_id].products_list[product_index].variations[i].sku;
            }
            this.chat[chat_id].products_list[product_index].id =
              this.chat[chat_id].products_list[product_index].variations[i].id;
            this.chat[chat_id].products_list[product_index].permalink =
              this.chat[chat_id].products_list[product_index].variations[
                i
              ].permalink;
            this.chat[chat_id].products_list[product_index].price =
              this.chat[chat_id].products_list[product_index].variations[i].price;
            this.chat[chat_id].products_list[product_index].regular_price =
              this.chat[chat_id].products_list[product_index].variations[
                i
              ].regular_price;
            this.chat[chat_id].products_list[product_index].stock_quantity =
              this.chat[chat_id].products_list[product_index].variations[
                i
              ].stock_quantity;
            this.chat[chat_id].products_list[product_index].stock_status =
              this.chat[chat_id].products_list[product_index].variations[
                i
              ].stock_status;
            this.chat[chat_id].products_list[product_index].variant_title =
              this.chat[chat_id].products_list[product_index].variations[
                i
              ].variant_title;
          }
        }
      },
      update_quantity(func, chat_id, index, product) {
        var vm = this;
        this.over_qty_warning = false;
        var buy = parseInt(this.chat[chat_id].products_list[index].buy_qty);
        var order = this.chat[chat_id].products_list[index].order_qty;
        var stock = this.chat[chat_id].products_list[index].stock_quantity;
        if (this.addtoCartdata.length !== 0 && stock != null) {
          for (var i in this.addtoCartdata) {
            if (this.addtoCartdata[i].id == product.id) {
              let cart_order_qty = this.addtoCartdata[i].order_qty;
              stock = stock - cart_order_qty;
            }
          }
        }
        var total = buy + order;
        if (func === "+") {
          this.chat[chat_id].products_list[index].order_qty = 1;
          if (total <= stock || (stock == null && !isNaN(total))) {
            this.chat[chat_id].products_list[index].buy_qty += 1;
            vm.$set(vm.chat, chat_id, vm.chat[chat_id]);
          } else {
            this.chat[chat_id].products_list[index].order_qty = 2;
            if (stock === 0) {
              this.chat[chat_id].products_list[index].buy_qty = 1;
            } else {
              this.chat[chat_id].products_list[index].buy_qty = stock;
            }
          }
        }
        if (func === "-") {
          if (buy > 1 && !isNaN(total)) {
            this.chat[chat_id].products_list[index].order_qty = 1;
            this.chat[chat_id].products_list[index].buy_qty -= 1;
            vm.$set(vm.chat, chat_id, vm.chat[chat_id]);
          } else {
            this.chat[chat_id].products_list[index].buy_qty = 1;
            this.chat[chat_id].products_list[index].order_qty = 1;
          }
        }
        if (func === "manual") {
          var quantity = Math.abs(parseInt(product.buy_qty));
          if (!isNaN(quantity)) {
            this.chat[chat_id].products_list[index].order_qty = 1;
            if (quantity) {
              if (quantity <= stock || stock == null) {
                this.chat[chat_id].products_list[index].buy_qty = quantity;
              } else {
                this.chat[chat_id].products_list[index].order_qty = 2;
                if (stock === 0) {
                  this.chat[chat_id].products_list[index].buy_qty = 1;
                } else {
                  this.chat[chat_id].products_list[index].buy_qty = stock;
                }
              }
              vm.$set(vm.chat, chat_id, vm.chat[chat_id]);
            }
          } else if (isNaN(total)) {
            this.chat[chat_id].products_list[index].buy_qty = 1;
            this.chat[chat_id].products_list[index].order_qty = 1;
            vm.$set(vm.chat, chat_id, vm.chat[chat_id]);
          }
        }
      },
      submit_review_rating(index) {
        if (Boolean(this.chat[index].product_rating) === false) {
          swal({
            text: "Please give rating",
            toast: true,
            showCancelButton: false,
            showConfirmButton: false,
            type: "warning",
            position: "top-end",
            timer: 5000,
          });
          return;
        }
        if (Boolean(this.chat[index].product_review) === false) {
          swal({
            text: "Please write review",
            toast: true,
            showCancelButton: false,
            showConfirmButton: false,
            type: "warning",
            position: "top-end",
            timer: 5000,
          });
          return;
        }
        var product_details = {
          product_id: this.chat[index].not_reviewed_product_details.product_id,
          name: this.chat[index].not_reviewed_product_details.name,
          image_src: this.chat[index].product_review_img_url,
          price: this.chat[index].not_reviewed_product_details.price,
          quantity: this.chat[index].not_reviewed_product_details.quantity,
          variant_id: this.chat[index].not_reviewed_product_details.variant_id,
        };
        if (this.retail_web_framework == "magento") {
          product_details.variant_id =
            this.chat[index].not_reviewed_product_details.sku;
        }
        axios
          .post(
            api_calls.product_review_rating(),
            {
              license_key: this.user_data.license_key,
              token: this.$session.get("bot_login_token"),
              username: this.user_data.username,
              is_save_review_rating: true,
              review_rating_customer_id: this.$session.get("shopify_customer_id"),
              product_rating: this.chat[index].product_rating,
              product_review: this.chat[index].product_review,
              product_details: product_details,
            },
            {
              headers: {
                Authorization: `Bearer ${this.$session.get("at")}`,
              },
            }
          )
          .then((response) => {
            if (response.data.message.MSG_CODE === 10001331) {
              this.chat[index].is_review_rating_product = false;
            // this.chat[index].received = this.review_response_message;
            // this.chat[index].time = this.generate_time();
            var msg = {
              received: this.review_response_message,
              conversation_only: true,
              receiving: true,
              time: this.generate_time(),
            };
            this.chat.push(msg);
            this.to_be_called = true

              swal({
                text: this.review_response_message,
                toast: true,
                showCancelButton: false,
                showConfirmButton: false,
                type: "success",
                position: "top-end",
                timer: 5000,
              });
            } else if (response.data.message.MSG_CODE === 10001470) {
              swal({
                text: "Some Error Occurred.",
                toast: true,
                showCancelButton: false,
                showConfirmButton: false,
                type: "error",
                position: "top-end",
                timer: 5000,
              });
            } else {
              // console.log(response.data.message.MSG);
            }
          })
          .catch((e) => {
            if (
              e.response.status === 410 ||
              e.response.status === 440 ||
              e.response.status === 409
            ) {
              this.$root.$emit("Session_Expired", e.response.data);
            }
          });
      },
      is_review_rating() {
        if (
          this.$session.has("shopify_customer_id") ||
          this.$session.has("shopify_customer_token")
        ) {
          let stringified_customer_payload = JSON.stringify({
            customerId: this.$session.get("shopify_customer_id"),
            email: this.$session.get("shopify_customer_token"),
          });
          axios
            .post(api_calls.chatbot_response(), {
              license_key: this.user_data.license_key,
              token: this.bot_token_response_value,
              username: this.user_data.username,
              source: "Web",
              chat: `/retrieve_customer_orders${stringified_customer_payload}`,
              token: this.bot_token_response_value,
              refreshed_or_closed: false,
            })
            .then((response) => {
              if (Boolean(response.data.responses[0].custom)) {
                if (
                  Boolean(
                    response.data.responses[0].custom.orders_list.length > 0
                  )
                ) {
                  this.call_review_rating(
                    response.data.responses[0].custom.orders_list
                  );
                }
              }
            });
        }
      },
      call_review_rating(data) {
        var fulfilled_orders = data.filter((fulfilled_order) => {
          return fulfilled_order.fulfillment_status === "fulfilled";
        });
        var sorted_fulfilled_orders = fulfilled_orders.map((item) => {
          return item.line_items.map((product) => {
            return product;
          });
        });
        axios
          .post(
            api_calls.product_review_rating(),
            {
              license_key: this.user_data.license_key,
              token: this.$session.get("bot_login_token"),
              username: this.user_data.username,
              web_framework: this.retail_web_framework,
              is_get_reviewed_product_id: true,
              review_rating_customer_id: this.$session.get("shopify_customer_id"),
              review_rating_customer_email: this.$session.get(
                "shopify_customer_token"
              ),
            },
            {
              headers: {
                Authorization: `Bearer ${this.$session.get("at")}`,
              },
            }
          )
          .then((response) => {
            if (10000001 === response.data.message.MSG_CODE) {
              if (response.data.data.is_review === true) {
              var reviewed_product_details = [];
              var not_reviewed_product_details = [];
              var reviewed_product_id_list = response.data.data.product_id_list;
              for (let i = 0; i < sorted_fulfilled_orders.length; i++) {
                for (let j = 0; j < sorted_fulfilled_orders[i].length; j++) {
                  if (
                    reviewed_product_id_list.includes(
                      sorted_fulfilled_orders[i][j].product_id
                    )
                  ) {
                    reviewed_product_details.push(sorted_fulfilled_orders[i][j]);
                  } else {
                    not_reviewed_product_details.push(
                      sorted_fulfilled_orders[i][j]
                    );
                  }
                }
              }
              if (
                not_reviewed_product_details.length > 0 &&
                Boolean(this.review_message_first) === true &&
                Boolean(this.review_message_second) === true &&
                Boolean(this.review_response_message) === true
              ) {
                axios
                  .post(
                    api_calls.product_review_rating(),
                    {
                      license_key: this.user_data.license_key,
                      token: this.$session.get("bot_login_token"),
                      username: this.user_data.username,
                      web_framework: this.retail_web_framework,
                      is_get_product_image: true,
                      product_id: not_reviewed_product_details[0].product_id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer ${this.$session.get("at")}`,
                      },
                    }
                  )
                  .then((response) => {
                    if (response.data.message.MSG_CODE === 10001311) {
                      var product_review_img_url = response.data.data.image_src;
                      var msg = {
                        is_review_rating_product: true,
                        not_reviewed_product_details:
                          not_reviewed_product_details[0],
                        review_message_first: this.review_message_first,
                        review_message_second: this.review_message_second,
                        product_review_img_url: product_review_img_url,
                        product_rating: null,
                        product_review: "",
                        time: this.generate_time(),
                      };
                      this.chat.push(msg);
                      this.update_scrollbar("upto_end", "response");
                    } else if(response.data.message.MSG_CODE === this.api_status_code.IMAGE_NOT_FOUND.MSG_CODE) {
                        // console.log(response.data.message.MSG);
                      }
                  });
              }
            }
            } else {
              // console.log(response.data.message.MSG);
            }
            
          })
          .catch((e) => {
            if (
              e.response.status === 410 ||
              e.response.status === 440 ||
              e.response.status === 409
            ) {
              this.$root.$emit("Session_Expired", e.response.data);
            }
          });
      },
      selected_product(value) {
        if (event.target.checked) {
          if (value == "all") {
            this.checked_list = [];
            for (var i in this.product_out_of_stock_list) {
              this.checked_list.push(this.product_out_of_stock_list[i]);
            }
            $("input:checkbox").prop("checked", false);
            for (let i = 0; i < this.checked_list.length; i++) {
              $(
                "#product_checkbox_" +
                  this.product_out_of_stock_list[i].product_id
              ).prop("checked", true);
              $("#selectall").prop("checked", true);
            }
          } else {
            this.checked_list.push(value);
          }
        } else if (!event.target.checked) {
          if (value != "all") {
            var selectall = document.querySelector("#selectall");
            if (selectall.checked) {
              selectall.checked = false;
            }
            var temp = this.checked_list.filter((product_id) => {
              return product_id != value;
            });
            this.checked_list = temp;
          } else {
            $("input:checkbox").prop("checked", false);
            for (let i = 0; i < this.checked_list.length; i++) {
              $(
                "#product_checkbox_" +
                  this.product_out_of_stock_list[i].product_id
              ).prop("checked", false);
              $("#selectall").prop("checked", false);
            }
            this.checked_list = [];
          }
        }
      },
      check_current_product_active(index) {
        if (Boolean(this.product_out_of_stock_list[index]) === true) {
          return this.product_out_of_stock_list[index].product_id ===
            this.current_product.product_id
            ? "current-active-template"
            : null;
        }
      },
      show_product(index) {
        this.current_product = this.product_out_of_stock_list[index];
      },
      add_to_out_of_stock_list(items) {
        this.product_out_of_stock_list = items;
        this.checked_list = [];
        $("input:checkbox").prop("checked", false);
      },
      submit_out_of_stock_products() {
        if (this.checked_list.length == 0) {
          swal({
            text: "Please select some products",
            toast: true,
            position: "top-end",
            type: "warning",
            showConfirmButton: false,
            timer: 2500,
          });
          return;
        } else if (
          this.customer_email == "" ||
          !this.reg_email.test(this.customer_email)
        ) {
          swal({
            text: "Please enter a valid email address",
            toast: true,
            position: "top-end",
            type: "warning",
            showConfirmButton: false,
            timer: 2500,
          });
          return;
        }
        swal({
          text: "Please wait while we are submitting your details...",
          type: "info",
          toast: true,
          showConfirmButton: false,
        });
        $("#notifyemail").modal("hide");
        this.spinnerOn = true;
        axios
          .post(
            api_calls.outofstockemailnotification(),
            {
              company_id: this.companyid,
              company_name: this.companyname,
              license_key: this.user_data.license_key,
              email: this.customer_email,
              product_list: this.checked_list,
              token: this.bot_token_response_value,
              username: this.user_data.username,
            },
            {
              headers: {
                Authorization: `Bearer ${this.$session.get("at")}`,
              },
            }
          )
          .then((response) => {
            this.spinnerOn = false;
            if (response.data.message.MSG_CODE === 20000001) {
              swal({
                type: "success",
                text: "You'll be notified through email when the products get back in stock",
                timer: 2500,
              }).then((result) => {
                this.customer_email = "";
                this.checked_list = [];
                $("input:checkbox").prop("checked", false);
                $("#notifyemail").modal("hide");
              });
            } else {
              // console.log(response.data.message.MSG);
            }
          })
          .catch((e) => {
            this.spinnerOn = false;
            if (
              e.response.status === 410 ||
              e.response.status === 440 ||
              e.response.status === 409
            ) {
              this.$root.$emit("Session_Expired", e.response.data);
            }
          });
      },
      ratingAdd(value,question) {
        for (var i in this.feedback_question_list) {
          if(question === this.feedback_question_list[i].question){
              if (this.feedback_data_list.length == 0) {
                this.feedback_data_list.push({
                  question: this.feedback_question_list[i].question,
                  answer: value 
                });
              }else {
                for(var k in this.feedback_data_list){
                  if(this.feedback_data_list[k].question === question){
                      this.feedback_data_list[k].answer = value;
                      return;
                  }
                  // else{
                  //   var check = this.feedback_data_list.filter(abc => abc.question == question)
                  //   console.log(check);
                  //   if(check.length != 0) {
                  //     return;
                  //   }else {
                      
                  //   }
                  // }
                }
                this.feedback_data_list.push({
                    question: question,
                    answer: value 
                  });
                  return;
              }
          }
        }
      },
      load_refunds() {
        var vm = this;
        let msg = {
          conversation_only: true,
          is_refund: true,
          receiving: true,
          received: this.retail_order_retreival_only_email_required
            ? "Please enter email to fetch your orders."
            : "Please login to fetch your recent orders",
          time: this.generate_time(),
        };
        setTimeout(() => {
          let latestChatIndex = this.chat.push(msg);
          latestChatIndex -= 1;
          this.chat[latestChatIndex].fetch_shopify_details = true;
          this.scroll_down_bottom();
          vm.$session.set("BotResponse_Conversation", vm.chat);
          this.to_be_called = true
        }, 1000);
      },
      refresh_or_remove_order_details(chatIndex, val) {
        if (val == "remove") {
          this.chat[chatIndex].refund_order_view_details = {};
        }
        this.$set(this.chat, chatIndex, this.chat[chatIndex]);
      },
      view_order_details(chatIndex, orderIndex, orderLineItems, orderRefunds) {
        this.chat[chatIndex].refund_selected_items = [];
        this.chat[chatIndex].shopify_reason_for_refund_list = null;
        this.chat[chatIndex].shopify_reason_for_refund = null;
        this.chat[chatIndex].refund_order_view_details = Object(
          this.chat[chatIndex].refund_orders_list[orderIndex]
        );
        this.chat[chatIndex].refund_order_view_details.line_items =
          orderLineItems.map((lineItem) => {
            let lineItemNode = lineItem;
            lineItemNode.selected_quantity =
              this.retail_web_framework == "magento"
                ? lineItem.qty_ordered
                : lineItem.quantity;
            lineItemNode.already_refunded_qty =
              this.retail_web_framework == "magento" ? lineItem.qty_refunded : 0;
            lineItemNode.qty_available_for_refund =
              this.retail_web_framework == "magento"
                ? lineItem.qty_ordered
                : lineItem.quantity;
            lineItemNode.product_id = lineItem.product_id;
            lineItemNode.variant_id =
              this.retail_web_framework == "magento"
                ? lineItem.sku
                : lineItem.variant_id;
            return lineItemNode;
          });
        if (Boolean(orderRefunds) === true) {
          orderRefunds = orderRefunds.map((lineItem) => {
            return lineItem.refund_line_items[0];
          });
          var AlreadyRefundedQty = [];
          orderRefunds.reduce(function (res, value) {
            if (!res[value.line_item_id]) {
              res[value.line_item_id] = {
                line_item_id: value.line_item_id,
                quantity: 0,
              };
              AlreadyRefundedQty.push(res[value.line_item_id]);
            }
            res[value.line_item_id].quantity += value.quantity;
            return res;
          }, {});
          let temp = this.chat[chatIndex].refund_order_view_details.line_items;
          this.chat[chatIndex].refund_order_view_details.line_items = temp.map(
            (lineItem) => {
              let lineItemNode = lineItem;
              for (var i in AlreadyRefundedQty) {
                if (lineItem.id == AlreadyRefundedQty[i].line_item_id) {
                  lineItemNode.already_refunded_qty =
                    AlreadyRefundedQty[i]["quantity"];
                  lineItemNode.qty_available_for_refund =
                    lineItem.quantity - AlreadyRefundedQty[i]["quantity"];
                  lineItemNode.selected_quantity =
                    lineItem.quantity - AlreadyRefundedQty[i]["quantity"];
                  lineItemNode.product_id = lineItem.product_id;
                  lineItemNode.variant_id =
                    this.retail_web_framework == "magento"
                      ? lineItem.sku
                      : lineItem.variant_id;
                }
              }
              return lineItemNode;
            }
          );
        }
        this.$set(this.chat, chatIndex, this.chat[chatIndex]);
      },
      load_customer_orders(ordersData, index, order_type) {
        this.is_typing_indicator_on = true;
        if (order_type === "refund") {
          this.chat[index].received = "Fetching your orders ...";
          this.chat[index].refund_orders_list = ordersData.map((order) => {
            let orderObject = order;
            orderObject.created_at = moment(orderObject.created_at, [
              "YYYY-MM-DDTh:m:sZ",
            ]).format("Do MMM YYYY");
            orderObject.name =
              this.retail_web_framework == "shopify"
                ? orderObject.name
                : orderObject.id;
            orderObject.processedAt = moment(orderObject.created_at, [
              "YYYY-MM-DDThh:mm:ssZ",
            ]).format("Do MMM YYYY");
            orderObject.fulfillment_status =
              Boolean(orderObject.fulfillment_status) === true
                ? orderObject.fulfillment_status
                : "Unfulfilled";
            // // Set only first character to uppercase while remaining characters set to lowercase
            orderObject.fulfillment_status =
              orderObject.fulfillment_status.charAt(0).toUpperCase() +
              orderObject.fulfillment_status.slice(1).toLowerCase();
  
            if (this.retail_web_framework != "shopify") {
              for (let i = 0; i < orderObject.order_notes.length; i++) {
                orderObject.order_notes[i].date_created = moment(
                  orderObject.order_notes[i].date_created,
                  ["YYYY-MM-DDThh:mm:ssZ"]
                ).format("Do MMM YYYY HH:mm a");
              }
            }
            return orderObject;
          });
          if (this.chat[index].refund_orders_list.length === 0) {
            this.chat[index].received = "There are no orders to be displayed";
          } else {
            this.chat[index].received =
              "Select the order that you wish to return";
          }
          this.chat[index].refund_order_view_details = {};
          this.chat[index].refund_selected_items = [];
          this.chat[index].shopify_reason_for_refund_list = null;
          this.chat[index].shopify_reason_for_refund = null;
          this.chat[index].conversation_only = false;
          this.chat[index].conversation_only = true;
        } else if (order_type === "all_orders") {
          let orders_data = ordersData,
            complete_orders_data = [];
          for (let i = 0; i < orders_data.length; i++) {
            var cur_node = orders_data[i];
            cur_node.processedAt = moment(cur_node.created_at, [
              "YYYY-MM-DDThh:mm:ssZ",
            ]).format("Do MMM YYYY");
            cur_node.fulfillment_status =
              Boolean(cur_node.fulfillment_status) === true
                ? cur_node.fulfillment_status
                : "Unfulfilled";
            // // Set only first character to uppercase while remaining characters set to lowercase
            cur_node.fulfillment_status =
              cur_node.fulfillment_status.charAt(0).toUpperCase() +
              cur_node.fulfillment_status.slice(1).toLowerCase();
            if (this.retail_web_framework != "shopify") {
              for (let i = 0; i < cur_node.order_notes.length; i++) {
                cur_node.order_notes[i].date_created = moment(
                  cur_node.order_notes[i].date_created,
                  ["YYYY-MM-DDThh:mm:ssZ"]
                ).format("Do MMM YYYY HH:mm a");
              }
            }
            complete_orders_data.push({
              name:
                this.retail_web_framework == "shopify"
                  ? cur_node.name
                  : cur_node.id,
              processed_date: cur_node.processedAt,
              fulfillment_status: cur_node.fulfillment_status,
              status_url: cur_node.order_status_url,
              order_notes:
                this.retail_web_framework == "shopify"
                  ? null
                  : cur_node.order_notes,
            });
          }
          this.chat[index].show_all_orders_list = true;
          this.chat[index].all_orders_list = complete_orders_data;
          if (this.chat[index].all_orders_list.length === 0) {
            this.chat[index].received = "There are no orders to be displayed";
            this.chat[index].show_all_orders_list = false;
          } else {
            this.chat[index].received = "Please Find Below Your Latest Orders:";
          }
          this.chat[index].recent_orders_list = complete_orders_data;
          setTimeout(() => {
            this.chat[index].conversation_only = true;
          }, 500);
        }
        this.is_typing_indicator_on = false;
          let indexes = []
          let chatfiltered = this.chat.filter((ch,index)=>{
            if(ch.receiving === true){
              indexes.push(index)
            }
            return ch.receiving === true
          })
          length = chatfiltered.length
          this.getText("recieved"+indexes[indexes.length - 1],indexes.length - 1,chatfiltered , length -1)
      },
      submit_refund_request(refund_order_view_details, index) {
        if (this.chat[index].shopify_reason_for_refund_list === null) {
          this.show_shopify_reason_for_refund_error = true;
          return;
        }
        if (this.chat[index].shopify_reason_for_refund_list === "Others") {
          if (
            this.chat[index].shopify_reason_for_refund === null ||
            this.chat[index].shopify_reason_for_refund === ""
          ) {
            this.show_shopify_reason_for_refund_error = true;
            return;
          }
        } else {
          this.chat[index].shopify_reason_for_refund =
            this.chat[index].shopify_reason_for_refund_list;
        }
        this.show_shopify_reason_for_refund_error = false;
        let items_to_refund = refund_order_view_details.line_items.filter(
          (lineItem) => {
            if (this.retail_web_framework == "magento") {
              return this.chat[index].refund_selected_items.includes(
                lineItem.product_id
              );
            } else {
              return this.chat[index].refund_selected_items.includes(lineItem.id);
            }
          }
        );
        for (let i = 0; i < items_to_refund.length; i++) {
          if (
            items_to_refund[i].selected_quantity === null ||
            Boolean(items_to_refund[i].selected_quantity) === false
          ) {
            swal({
              text: "Please enter the product quantity in numeric only",
              toast: true,
              position: "top-end",
              type: "warning",
              showConfirmButton: false,
              timer: 5000,
            });
            return;
          }
        }
        let refund_payload_string = JSON.stringify({
          orderId: refund_order_view_details.id,
          order_name: refund_order_view_details.name,
          customer_id: this.shopify_customer_id,
          fulfillment_status: refund_order_view_details.fulfillment_status,
          payment_status: refund_order_view_details.payment_status,
          currency: this.retail_shop_currency,
          lineItems: items_to_refund.map((item) => {
            return {
              line_item_id:
                this.retail_web_framework == "magento"
                  ? item.product_id
                  : item.id,
              quantity: item.selected_quantity,
              product_id: item.product_id,
              variant_id: item.variant_id,
            };
          }),
          refundReason: this.chat[index].shopify_reason_for_refund,
        });
        let refund_intent = `/process_refund_request${refund_payload_string}`;
        let refund_object = {
          type: "postback",
          title: "Proceed",
          value: refund_intent,
        };
        this.custom_button_click(refund_object);
        this.chat[index].refund_order_view_details = {};
        this.chat[index].refund_selected_items = [];
        this.chat[index].shopify_reason_for_refund_list = null;
        this.chat[index].shopify_reason_for_refund = null;
        this.chat.splice(index, 1);
        // }
      },
      retail_form_focus(index) {
        if (
          this.chat[index].received ===
          "We are sorry, your credentials are invalid. Please try again :)"
        ) {
          this.chat[index].received =
            "Please login with your credentials for better experience :)";
        }
      },
      retail_create_customer_token(index, isRefund) {
        this.is_typing_indicator_on = true;
        isRefund = isRefund || false;
        let shop_endpoint = this.shopify_retail_shop_name + ".myshopify.com";
        let queryString = $("#shopify_login_form").serializeArray();
        let form_payload = {};
        form_payload["shopify_is_only_email"] =
          this.retail_order_retreival_only_email_required;
        form_payload["email"] = this.chat[index].customer_email_id;
        form_payload["password"] = this.chat[index].customer_password;
        form_payload = JSON.stringify(form_payload);
        axios
          .post(api_calls.chatbot_response(), {
            license_key: this.user_data.license_key,
            token: this.bot_token_response_value,
            username: this.user_data.username,
            source: "Web",
            chat: `/shopify_create_customer_token${form_payload}`,
            token: this.bot_token_response_value,
            refreshed_or_closed: this.refreshed_or_closed,
          })
          .then(({ data }) => {
            if (data.responses[0].token_type === "customer_token") {
              if (Boolean(data.responses[0].Data)) {
                if (this.retail_web_framework == "shopify") {
                  let customerToken = data.responses[0].Data.accessToken;
                  let tokenExpiry = data.responses[0].Data.expiresAt;
                  this.$session.set("shopify_customer_token", customerToken);
                  this.$session.set("shopify_customer_token_expiry", tokenExpiry);
                  this.retail_fetch_orders(
                    customerToken,
                    index,
                    isRefund,
                    "shopify_customer_token"
                  );
                } else if (this.retail_web_framework == "woocommerce") {
                  let customerToken =
                    data.responses[0].customer_data.customer_email;
                  let tokenExpiry = data.responses[0].Data.expiresAt;
                  let customerId = data.responses[0].customer_data.customer_id;
                  this.shopify_customer_id = customerId;
                  this.$session.set("shopify_customer_token", customerToken);
                  this.$session.set("shopify_customer_token_expiry", tokenExpiry);
                  this.$session.set("shopify_customer_id", customerId);
                  this.retail_fetch_orders(
                    customerToken,
                    index,
                    isRefund,
                    "shopify_customer_token"
                  );
                } else if (this.retail_web_framework == "magento") {
                  let customerToken =
                    data.responses[0].customer_data.customer_email;
                  let tokenExpiry = data.responses[0].Data.expiresAt;
                  let customerId = data.responses[0].customer_data.customer_id;
                  this.shopify_customer_id = customerId;
                  this.$session.set("shopify_customer_token", customerToken);
                  this.$session.set("shopify_customer_token_expiry", tokenExpiry);
                  this.$session.set("shopify_customer_id", customerId);
                  this.retail_fetch_orders(
                    customerToken,
                    index,
                    isRefund,
                    "shopify_customer_token"
                  );
                }
              } else {
                this.chat[index].received =
                  "We are sorry, your credentials are invalid. Please try again :)";
                this.is_typing_indicator_on = false;
              }
            } else if (data.responses[0].token_type === "customer_id") {
              if (this.retail_web_framework == "shopify") {
                let customerId = data.responses[0].customer_id;
                let customerEmail = data.responses[0].customer_email;
                this.shopify_customer_id = customerId;
                this.$session.set("shopify_customer_id", customerId);
                this.$session.set("shopify_customer_token", customerEmail);
                this.retail_fetch_orders(
                  customerId,
                  index,
                  isRefund,
                  "shopify_customer_id"
                );
              } else if (this.retail_web_framework == "woocommerce") {
                let customerId = data.responses[0].customer_id;
                let customerEmail = data.responses[0].customer_email;
                this.$session.set("shopify_customer_id", customerId);
                this.$session.set("shopify_customer_token", customerEmail);
                this.retail_fetch_orders(
                  customerId,
                  index,
                  isRefund,
                  "shopify_customer_id"
                );
              } else if (this.retail_web_framework == "magento") {
                let customerId = data.responses[0].customer_id;
                let customerEmail = data.responses[0].customer_email;
                this.$session.set("shopify_customer_id", customerId);
                this.$session.set("shopify_customer_token", customerEmail);
                this.retail_fetch_orders(
                  customerId,
                  index,
                  isRefund,
                  "shopify_customer_id"
                );
              } else {
                this.chat[index].received =
                  "We are sorry, could not find any records with this email. Please try again :)";
                this.is_typing_indicator_on = false;
              }
            } else {
              this.chat[index].received =
                "We are sorry, your credentials are invalid. Please try again :)";
              this.is_typing_indicator_on = false;
            }
          });
      },
      retail_fetch_customer_id(customerAccessToken, index, token_type) {
        let shop_endpoint = this.shopify_retail_shop_name + ".myshopify.com";
        this.is_typing_indicator_on = true;
        if (!Boolean(this.chat[index].refund_orders_list)) {
          if (token_type === "shopify_customer_token") {
            let stringified_customer_payload = JSON.stringify({
              customer_access_token: customerAccessToken,
            });
            axios
              .post(api_calls.chatbot_response(), {
                license_key: this.user_data.license_key,
                token: this.bot_token_response_value,
                username: this.user_data.username,
                source: "Web",
                chat: `/shopify_get_customer_id_and_orders${stringified_customer_payload}`,
                token: this.bot_token_response_value,
                refreshed_or_closed: this.refreshed_or_closed,
                host: this.user_data.host,
              })
              .then(({ data }) => {
                if (Boolean(data.responses[0].custom)) {
                  this.load_customer_orders(
                    data.responses[0].custom.orders_list,
                    index,
                    "refund"
                  );
                } else {
                  this.chat[index].received =
                    "Some Error Occurred. Please Try Again";
                  this.is_typing_indicator_on = false;
                }
              });
          } else if (token_type == "shopify_customer_id") {
            let stringified_customer_payload = JSON.stringify({
              customerId: customerAccessToken,
            });
            this.chat[index].received = "Fetching your order details ...";
            axios
              .post(api_calls.chatbot_response(), {
                license_key: this.user_data.license_key,
                token: this.bot_token_response_value,
                username: this.user_data.username,
                source: "Web",
                chat: `/retrieve_customer_orders${stringified_customer_payload}`,
                token: this.bot_token_response_value,
                refreshed_or_closed: this.refreshed_or_closed,
              })
              .then((response) => {
                this.load_customer_orders(
                  response.data.responses[0].custom.orders_list,
                  index,
                  "refund"
                );
              })
              .catch((e) => {
                this.is_typing_indicator_on = false;
                this.update_scrollbar("upto_end", "response");
              });
          }
        }
      },
      retail_fetch_orders(customerAccessToken, index, isRefund, token_type) {
        this.is_typing_indicator_on = true;
        isRefund = isRefund || false;
        this.chat[index].conversation_only = false;
        let shop_endpoint = this.shopify_retail_shop_name + ".myshopify.com";
        if (token_type === "shopify_customer_token") {
          let stringified_customer_payload = JSON.stringify({
            customer_access_token: customerAccessToken,
          });
          axios
            .post(api_calls.chatbot_response(), {
              license_key: this.user_data.license_key,
              token: this.bot_token_response_value,
              username: this.user_data.username,
              source: "Web",
              chat: `/shopify_get_customer_orders${stringified_customer_payload}`,
              token: this.bot_token_response_value,
              refreshed_or_closed: this.refreshed_or_closed,
            })
            .then(({ data }) => {
              this.is_typing_indicator_on = false;
              try {
                let customerId = atob(data.responses[0].data.customer.id);
                this.shopify_customer_id = customerId.slice(
                  customerId.lastIndexOf("/") + 1
                );
                this.$session.set(
                  "shopify_customer_id",
                  this.shopify_customer_id
                );
              } catch (e) {}
              if (this.retail_web_framework == "shopify") {
                let ordersData = data.responses[0].data.customer.orders.edges;
                if (typeof index == "number" && ordersData.length > 0) {
                  if (isRefund) {
                    this.retail_fetch_customer_id(
                      customerAccessToken,
                      index,
                      "shopify_customer_token"
                    );
                  } else {
                    this.is_typing_indicator_on = false;
                    let orders_data =
                        data.responses[0].data.customer.orders.edges,
                      complete_orders_data = [];
                    for (let i = 0; i < orders_data.length; i++) {
                      var cur_node = orders_data[i].node;
                      cur_node.processedAt = moment(cur_node.processedAt, [
                        "YYYY-MM-DDThh:mm:ssZ",
                      ]).format("Do MMM YYYY");
                      cur_node.fulfillmentStatus =
                        cur_node.fulfillmentStatus.replace("_", " ");
                      // Set only first character to uppercase while remaining characters set to lowercase
                      cur_node.fulfillmentStatus =
                        cur_node.fulfillmentStatus.charAt(0).toUpperCase() +
                        cur_node.fulfillmentStatus.slice(1).toLowerCase();
                      complete_orders_data.push({
                        name: cur_node.name,
                        processed_date: cur_node.processedAt,
                        fulfillment_status: cur_node.fulfillmentStatus,
                        status_url: cur_node.statusUrl,
                      });
                    }
                    this.chat[index].show_all_orders_list = true;
                    this.chat[index].all_orders_list = complete_orders_data;
                    this.chat[index].received =
                      "Please Find Below Your Latest Orders:";
  
                    this.chat[index].recent_orders_list =
                      data.responses[0].data.customer.orders.edges;
                  }
                  setTimeout(() => {
                    this.chat[index].conversation_only = true;
                  }, 500);
                }
              } else if (this.retail_web_framework == "woocommerce") {
                if (isRefund) {
                  this.load_customer_orders(
                    data.responses[0].data.customer.orders,
                    index,
                    isRefund ? "refund" : "all_orders"
                  );
                } else {
                  this.is_typing_indicator_on = false;
                  let orders_data = data.responses[0].data.customer.orders,
                    complete_orders_data = [];
                  for (let i = 0; i < orders_data.length; i++) {
                    var cur_node = orders_data[i];
                    cur_node.processedAt = moment(cur_node.created_at, [
                      "YYYY-MM-DDThh:mm:ssZ",
                    ]).format("Do MMM YYYY");
                    cur_node.fulfillment_status =
                      Boolean(cur_node.fulfillment_status) === true
                        ? cur_node.fulfillment_status
                        : "Unfulfilled";
                    // Set only first character to uppercase while remaining characters set to lowercase
                    cur_node.fulfillment_status =
                      cur_node.fulfillment_status.charAt(0).toUpperCase() +
                      cur_node.fulfillment_status.slice(1).toLowerCase();
                    for (let i = 0; i < cur_node.order_notes.length; i++) {
                      cur_node.order_notes[i].date_created = moment(
                        cur_node.order_notes[i].date_created,
                        ["YYYY-MM-DDThh:mm:ssZ"]
                      ).format("Do MMM YYYY HH:mm a");
                    }
                    complete_orders_data.push({
                      name: cur_node.id,
                      processed_date: cur_node.processedAt,
                      fulfillment_status: cur_node.fulfillment_status,
                      status_url: cur_node.order_status_url,
                      order_notes: cur_node.order_notes,
                    });
                  }
                  this.chat[index].show_all_orders_list = true;
                  this.chat[index].all_orders_list = complete_orders_data;
                  this.chat[index].received =
                    "Please Find Below Your Latest Orders:";
                  this.chat[index].show_all_orders_list = true;
                  this.chat[index].recent_orders_list = complete_orders_data;
                }
                setTimeout(() => {
                  this.chat[index].conversation_only = true;
                }, 500);
              } else if (this.retail_web_framework == "magento") {
                if (isRefund) {
                  this.load_customer_orders(
                    data.responses[0].data.customer.orders,
                    index,
                    isRefund ? "refund" : "all_orders"
                  );
                } else {
                  this.is_typing_indicator_on = false;
                  let orders_data = data.responses[0].data.customer.orders,
                    complete_orders_data = [];
                  for (let i = 0; i < orders_data.length; i++) {
                    var cur_node = orders_data[i];
                    cur_node.processedAt = moment(cur_node.created_at, [
                      "YYYY-MM-DDThh:mm:ssZ",
                    ]).format("Do MMM YYYY");
                    cur_node.fulfillment_status =
                      Boolean(cur_node.fulfillment_status) === true
                        ? cur_node.fulfillment_status
                        : "Unfulfilled";
                    // Set only first character to uppercase while remaining characters set to lowercase
                    cur_node.fulfillment_status =
                      cur_node.fulfillment_status.charAt(0).toUpperCase() +
                      cur_node.fulfillment_status.slice(1).toLowerCase();
                    for (let i = 0; i < cur_node.order_notes.length; i++) {
                      cur_node.order_notes[i].date_created = moment(
                        cur_node.order_notes[i].date_created,
                        ["YYYY-MM-DDThh:mm:ssZ"]
                      ).format("Do MMM YYYY HH:mm a");
                    }
                    complete_orders_data.push({
                      name: cur_node.id,
                      processed_date: cur_node.processedAt,
                      fulfillment_status: cur_node.fulfillment_status,
                      status_url: cur_node.order_status_url,
                      order_notes: cur_node.order_notes,
                    });
                  }
                  this.chat[index].show_all_orders_list = true;
                  this.chat[index].all_orders_list = complete_orders_data;
                  this.chat[index].received =
                    "Please Find Below Your Latest Orders:";
                  this.chat[index].show_all_orders_list = true;
                  this.chat[index].recent_orders_list = complete_orders_data;
                }
                setTimeout(() => {
                  this.chat[index].conversation_only = true;
                }, 500);
              }
            });
        } else if (token_type === "shopify_customer_id") {
          if (Boolean(this.shopify_customer_id) === false) {
            this.shopify_customer_id = this.$session.get("shopify_customer_id");
          }
          var shopify_customer_email = this.$session.get(
            "shopify_customer_token"
          );
          let stringified_customer_payload = JSON.stringify({
            customerId: this.shopify_customer_id,
            email: shopify_customer_email,
          });
          this.chat[index].received = "Fetching your order details ...";
          axios
            .post(api_calls.chatbot_response(), {
              license_key: this.user_data.license_key,
              token: this.bot_token_response_value,
              username: this.user_data.username,
              source: "Web",
              chat: `/retrieve_customer_orders${stringified_customer_payload}`,
              token: this.bot_token_response_value,
              refreshed_or_closed: this.refreshed_or_closed,
            })
            .then((response) => {
              if (
                response.data.responses[0].custom.orders_list.length == 0 &&
                this.retail_order_retreival_only_email_required
              ) {
                this.is_typing_indicator_on = false;
                this.chat[index].conversation_only = true;
                this.chat[index].received =
                  "There are no orders to be displayed for the given Email or Phone number";
                this.shopify_customer_id = null;
              } else {
                this.load_customer_orders(
                  response.data.responses[0].custom.orders_list,
                  index,
                  isRefund ? "refund" : "all_orders"
                );
              }
            })
            .catch((e) => {
              this.is_typing_indicator_on = false;
              // console.log(e);
              this.update_scrollbar("upto_end", "response");
            });
        }
      },
      retail_is_token_valid(index, isRefund) {
        isRefund = isRefund || false;
        if (this.retail_web_framework == "shopify") {
          if (
            (this.chat[index].fetch_shopify_details &&
              !Boolean(this.chat[index].is_refund) &&
              !Boolean(this.chat[index].recent_orders_list)) ||
            (this.chat[index].fetch_shopify_details &&
              Boolean(this.chat[index].is_refund) &&
              !Boolean(this.chat[index].refund_orders_list))
          ) {
            if (this.retail_order_retreival_only_email_required) {
              if (Boolean(this.shopify_customer_id)) {
                let customerToken = this.$session.get("shopify_customer_id");
                if (this.chat[index].is_refund) {
                  this.retail_fetch_customer_id(
                    customerToken,
                    index,
                    "shopify_customer_id"
                  );
                } else {
                  this.retail_fetch_orders(
                    customerToken,
                    index,
                    isRefund,
                    "shopify_customer_id"
                  );
                }
                return true;
              }
              return false;
            } else {
              let shopify_store_cid = this.get_shopify_store_window_customer_id();
              if (Boolean(shopify_store_cid) === false) {
                return false;
              } else {
                this.shopify_customer_id = shopify_store_cid;
                this.$session.set(
                  "shopify_customer_id",
                  this.shopify_customer_id
                );
                if (this.chat[index].is_refund) {
                  this.retail_fetch_customer_id(
                    shopify_store_cid,
                    index,
                    "shopify_customer_id"
                  );
                } else {
                  this.retail_fetch_orders(
                    shopify_store_cid,
                    index,
                    isRefund,
                    "shopify_customer_id"
                  );
                }
                return true;
              }
            }
          } else {
            return true;
          }
        } else if (this.retail_web_framework == "woocommerce") {
          if (
            (this.chat[index].fetch_shopify_details &&
              !Boolean(this.chat[index].is_refund) &&
              !Boolean(this.chat[index].recent_orders_list)) ||
            (this.chat[index].fetch_shopify_details &&
              Boolean(this.chat[index].is_refund) &&
              !Boolean(this.chat[index].refund_orders_list)) ||
            (this.chat[index].fetch_shopify_details &&
              Boolean(this.chat[index].return_shopify_email))
          ) {
            if (this.retail_order_retreival_only_email_required) {
              this.shopify_customer_id = this.$session.get("shopify_customer_id");
              if (Boolean(this.shopify_customer_id)) {
                let customerToken = this.$session.get("shopify_customer_id");
                this.retail_fetch_orders(
                  customerToken,
                  index,
                  isRefund,
                  "shopify_customer_id"
                );
                return true;
              } else {
                return false;
              }
            } else {
              let woocommerce_store_login_detail =
                this.get_woocommerce_store_window_customer_email();
              if (Boolean(woocommerce_store_login_detail) == false) {
                return false;
              } else {
                this.$session.set(
                  "shopify_customer_id",
                  woocommerce_store_login_detail[0]
                );
                this.$session.set(
                  "shopify_customer_token",
                  woocommerce_store_login_detail[1]
                );
                if (this.chat[index].is_refund) {
                  this.retail_fetch_customer_id(
                    woocommerce_store_login_detail[0],
                    index,
                    "shopify_customer_id"
                  );
                } else {
                  this.retail_fetch_orders(
                    woocommerce_store_login_detail[0],
                    index,
                    isRefund,
                    "shopify_customer_id"
                  );
                }
                return true;
              }
            }
          } else {
            return true;
          }
        } else if (this.retail_web_framework == "magento") {
          if (
            (this.chat[index].fetch_shopify_details &&
              !Boolean(this.chat[index].is_refund) &&
              !Boolean(this.chat[index].recent_orders_list)) ||
            (this.chat[index].fetch_shopify_details &&
              Boolean(this.chat[index].is_refund) &&
              !Boolean(this.chat[index].refund_orders_list)) ||
            (this.chat[index].fetch_shopify_details &&
              Boolean(this.chat[index].return_shopify_email))
          ) {
            if (this.retail_order_retreival_only_email_required) {
              this.shopify_customer_id = this.$session.get("shopify_customer_id");
              if (Boolean(this.shopify_customer_id)) {
                let customerToken = this.$session.get("shopify_customer_id");
                this.retail_fetch_orders(
                  customerToken,
                  index,
                  isRefund,
                  "shopify_customer_id"
                );
                return true;
              } else {
                return false;
              }
            } else {
              let magento_store_login_detail =
                this.get_magento_store_window_customer_email();
              if (Boolean(magento_store_login_detail) == false) {
                return false;
              } else {
                this.$session.set(
                  "shopify_customer_id",
                  magento_store_login_detail[0]
                );
                this.$session.set(
                  "shopify_customer_token",
                  magento_store_login_detail[1]
                );
                if (this.chat[index].is_refund) {
                  this.retail_fetch_customer_id(
                    magento_store_login_detail[0],
                    index,
                    "shopify_customer_id"
                  );
                } else {
                  this.retail_fetch_orders(
                    magento_store_login_detail[0],
                    index,
                    isRefund,
                    "shopify_customer_id"
                  );
                }
                return true;
              }
            }
          } else {
            return true;
          }
        }
      },
      display_products_chat(message, index) {
        var msg = {
          conversation_only: true,
          time: this.generate_time(),
        };
        if (message.products.is_products_list) {
          msg.is_products_list = message.products.is_products_list;
          msg.products_list = message.products.products_list;
          if (message.products.products_list.length === 0) {
            msg.received = "Sorry, we could not find what you searched for";
            msg.receiving = true;
          }
          this.product_out_of_stock_list = [];
          if (this.retail_web_framework == "shopify") {
            msg.products_list.forEach((product) => {
              for (let i = 0; i < product.variations.length; i++) {
                this.product_list.push({
                  product_id: product.variations[i].id,
                  product_title: product.title,
                  variant_title: product.variations[i].variant_title,
                  variant_id: product.variations[i].id,
                  stock_status: product.variations[i].stock_status || "instock",
                  back_in_stock: false,
                  productImage:
                    product.variations[i].img_url == null
                      ? product.img_url
                      : product.variations[i].img_url,
                  onlineStoreURL: product.variations[i].permalink,
                });
              }
            });
          } else if (this.retail_web_framework == "woocommerce") {
            msg.products_list.forEach((product) => {
              if (product.variations && product.variations.length > 0) {
                for (let i = 0; i < product.variations.length; i++) {
                  this.product_list.push({
                    product_id: product.id,
                    product_title: product.title,
                    variant_title: product.variations[i].variant_title,
                    variant_id: product.variations[i].id,
                    stock_status: product.variations[i].stock_status || "instock",
                    back_in_stock: false,
                    productImage:
                      product.variations[i].img_url == null
                        ? product.img_url
                        : product.variations[i].img_url,
                    onlineStoreURL: product.permalink,
                  });
                }
              } else {
                this.product_list.push({
                  product_id: product.id,
                  product_title: product.title,
                  variant_title: null,
                  variant_id: null,
                  stock_status: product.stock_status || "instock",
                  back_in_stock: false,
                  productImage: product.img_url,
                  onlineStoreURL: product.permalink,
                });
              }
            });
          } else if (this.retail_web_framework == "magento") {
            msg.products_list.forEach((product) => {
              if (product.variations && product.variations.length > 0) {
                for (let i = 0; i < product.variations.length; i++) {
                  this.product_list.push({
                    product_id: product.id,
                    product_title: product.title,
                    variant_title: product.variations[i].variant_title,
                    variant_id: product.variations[i].id,
                    stock_status: product.stock_status || "instock",
                    back_in_stock: false,
                    productImage:
                      product.variations[i].img_url == null
                        ? product.img_url
                        : product.variations[i].img_url,
                    onlineStoreURL: product.variations[i].permalink,
                  });
                }
              } else {
                this.product_list.push({
                  product_id: product.id,
                  product_title: product.title,
                  variant_title: null,
                  variant_id: null,
                  stock_status: product.stock_status || "instock",
                  back_in_stock: false,
                  productImage: product.img_url,
                  onlineStoreURL: product.permalink,
                });
              }
            });
          } else if (this.retail_web_framework == "bigcommerce") {
            msg.products_list.forEach((product) => {
              if (product.variations && product.variations.length > 0) {
                for (let i = 0; i < product.variations.length; i++) {
                  this.product_list.push({
                    product_id: product.id,
                    product_title: product.title,
                    variant_title: product.variations[i].variant_title,
                    variant_id: product.variations[i].id,
                    sku: product.variations[i].sku,
                    stock_status: product.stock_status || "instock",
                    back_in_stock: false,
                    productImage:
                      product.variations[i].img_url == null
                        ? product.img_url
                        : product.variations[i].img_url,
                    onlineStoreURL: product.variations[i].permalink,
                  });
                }
              } else {
                this.product_list.push({
                  product_id: product.id,
                  product_title: product.title,
                  variant_title: null,
                  variant_id: null,
                  sku: product.sku,
                  stock_status: product.stock_status || "instock",
                  back_in_stock: false,
                  productImage: product.img_url,
                  onlineStoreURL: product.permalink,
                });
              }
            });
          }
  
          for (var i in msg.products_list) {
            msg.products_list[i].buy_qty = 1;
            msg.products_list[i].order_qty = 1;
            if (
              msg.products_list[i].variations &&
              msg.products_list[i].variations.length > 0
            ) {
              msg.products_list[i].id = msg.products_list[i].variations[0].id;
              msg.products_list[i].img_url =
                msg.products_list[i].variations[0].img_url === null
                  ? msg.products_list[i].img_url
                  : msg.products_list[i].variations[0].img_url;
              msg.products_list[i].permalink =
                msg.products_list[i].variations[0].permalink;
              msg.products_list[i].variant_title =
                msg.products_list[i].variations[0].variant_title;
              msg.products_list[i].stock_quantity =
                msg.products_list[i].variations[0].stock_quantity;
              msg.products_list[i].stock_status =
                msg.products_list[i].variations[0].stock_status;
              msg.products_list[i].price =
                msg.products_list[i].variations[0].price;
              msg.products_list[i].regular_price =
                msg.products_list[i].variations[0].regular_price;
            }
          }
          msg.new_products = true;
          this.show_custom_cart = true;
          var newlist = this.product_list.filter(
            (product) => product["stock_status"] === "outofstock"
          );
  
          msg.product_out_of_stock_list = newlist;
          if (
            msg.product_out_of_stock_list.length > 0 &&
            !this.display_products_on_landing
          ) {
            msg.product_out_of_stock = true;
          }
          this.display_products_on_landing = false;
        } else if (message.products.length) {
          msg.isproduct = true;
          msg.elements = message;
        }
        this.chat.push(msg);
        this.$session.set("BotResponse_Conversation", this.chat);
        this.new_update_response(index);
        this.product_list = [];
      },
      retail_check_customer_logged_in() {
        let customer_data = {
          is_customer_logged_in: false,
          customer_id: null,
        };
  
        if (Boolean(this.shopify_customer_id)) {
          customer_data.is_customer_logged_in = true;
          customer_data.customer_id = this.shopify_customer_id;
        }
        let stringified_customer_payload = JSON.stringify({
          customer_data,
        });
        this.is_typing_indicator_on = true;
        axios
          .post(api_calls.chatbot_response(), {
            license_key: this.user_data.license_key,
            token: this.bot_token_response_value,
            username: this.user_data.username,
            source: "Web",
            chat: `/shopify_retreive_customer_specific_offers${stringified_customer_payload}`,
            refreshed_or_closed: this.refreshed_or_closed,
          })
          .then((response) => {
            this.response_handling(response, null);
          })
          .catch((e) => {
            this.update_scrollbar("upto_end", "response");
          });
      },
      get_magento_store_window_customer_email() {
        try {
          let curr = JSON.parse(this.user_data.magento_customer_data);
          if (Boolean(curr.id) == true && Boolean(curr.email) == true) {
            var customer = [curr.id, curr.email];
            return customer;
          }
        } catch (e) {}
        return null;
      },
      get_woocommerce_store_window_customer_email() {
        try {
          let curr = this.user_data.woo_current_user;
          if (curr.ID !== 0 && curr.allcaps.customer == true) {
            var customer = [curr.data.ID, curr.data.user_email];
            return customer;
          }
        } catch (e) {}
        return null;
      },
      get_shopify_store_window_customer_id() {
        try {
          let curr = window.ShopifyAnalytics.meta.page.customerId;
          if (curr !== undefined && curr !== null && curr !== "") {
            return curr;
          }
        } catch (e) {}
        try {
          let curr = window.meta.page.customerId;
          if (curr !== undefined && curr !== null && curr !== "") {
            return curr;
          }
        } catch (e) {}
        try {
          let curr = __st.cid;
          if (curr !== undefined && curr !== null && curr !== "") {
            return curr;
          }
        } catch (e) {}
        return null;
      },
      change_chat_text_to_login_redirect(index) {
        if (this.retail_web_framework == "shopify") {
          this.chat[index].received =
            '<p style="width:auto;margin-bottom:0;"> Please login on the store by clicking <a href="/account/login" style= "text-decoration: underline !important; font-weight: 500 !important;"> here </a></p>';
        } else if (this.retail_web_framework == "woocommerce") {
          this.chat[index].received =
            '<p style="width:auto;margin-bottom:0;"> Please login on the store by clicking <a href="/my-account/" style="text-decoration: underline !important; font-weight: 500 !important;"> here </a></p>';
        } else if (this.retail_web_framework == "magento") {
          this.chat[index].received =
            '<p style="width:auto;margin-bottom:0;"> Please login on the store by clicking <a href="/customer/account/login/" style="text-decoration: underline !important; font-weight: 500 !important;"> here </a></p>';
        }
      },
      available_qty(stock_qty, id) {
        if (this.addtoCartdata.length != 0) {
          for (var i in this.addtoCartdata) {
            if (this.addtoCartdata[i].id === id) {
              let res = stock_qty - this.addtoCartdata[i].order_qty;
              return res == 0 ? "reached" : res;
            }
          }
          return stock_qty;
        } else {
          return stock_qty;
        }
      },
      async get_shopify_website_cart() {
        var cartContents = await fetch("/cart.js")
          .then((response) => response.json())
          .then((data) => {
            return data;
          });
        // console.log(cartContents);
        if (Boolean(cartContents.items)) {
          this.add_shopify_website_cart_data_to_cense_cart(cartContents.items);
        }
      },
      add_shopify_website_cart_data_to_cense_cart(items) {
        this.total_products_qty = 0;
        this.addtoCartdata = [];
        var vm = this;
        vm.addtoCartdata = items.map(function (item) {
          var varient_title = "";
          for (var j in vm.cart_custom_product_details) {
            var title = vm.variation_key_title(
              item,
              vm.cart_custom_product_details[j]
            );
            if (
              !(
                title === undefined ||
                title === null ||
                title === "" ||
                vm.cart_custom_product_details.length === j - 1 ||
                j == 0
              )
            ) {
              varient_title = varient_title + "-";
            }
            varient_title = varient_title + title;
          }
          var product_data = {
            buy_qty: 1,
            id: item.id,
            img_url: item.image,
            order_qty: item.quantity,
            price: (item.final_price / 100).toString(),
            stock_quantity: null,
            stock_status: "instock",
            title: item.product_title,
            variant_title: Boolean(varient_title)
              ? varient_title
              : item.variant_title,
          };
          for (var j in vm.custom_product_button_key) {
            product_data[vm.custom_product_button_key[j].on_key] =
              item[vm.custom_product_button_key[j].on_key];
            if (Boolean(vm.custom_product_button_key[j].disable)) {
              product_data[vm.custom_product_button_key[j].disable] = false;
            }
          }
          return product_data;
        });
        // console.log(vm.addtoCartdata);
        for (var j in vm.addtoCartdata) {
          vm.total_products_qty =
            this.total_products_qty + this.addtoCartdata[j].order_qty;
        }
      },
      update_cense_cart_data_to_shopify_website_cart(cense_cart_data) {
        var data_dict = Object.assign(
          {},
          ...cense_cart_data.map((x) => ({ [x.id]: x.order_qty }))
        );
        (async () => {
          var rawResponse = await fetch("/cart/update.js", {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              updates: data_dict,
              sections:
                "cart-items,cart-icon-bubble,cart-live-region-text,cart-footer",
            }),
          });
          // var updatecartContents = await rawResponse.json();
          // console.log('update content' , updatecartContents);
          // if(Boolean(updatecartContents.items)){
          //   this.add_shopify_website_cart_data_to_cense_cart(updatecartContents.items);
          // }
        })();
      },
      update_cense_cart() {
        this.is_cense_cart = !this.is_cense_cart;
        if (this.retail_web_framework === "shopify") {
          this.get_shopify_website_cart();
        }
      },
      variation_key_title(item, variation_key) {
        var title = item[variation_key[0]];
        if (Boolean(title) && variation_key.length > 1) {
          for (var i = 1; i < variation_key.length; i++) {
            if (Boolean(title[variation_key[i]])) {
              title = title[variation_key[i]];
            } else {
              return "";
            }
          }
        } else {
          return "";
        }
        return title;
      },
      // ************* Cense Cart *******************
  
      closecart(close_cart) {
        this.is_cense_cart = close_cart;
      },
      cart_communication(cart_data) {
        this.addtoCartdata = [];
        this.total_products_qty = 0;
        for (var i in cart_data) {
          this.addtoCartdata.push(cart_data[i]);
        }
        for (var j in this.addtoCartdata) {
          this.total_products_qty =
            this.total_products_qty + this.addtoCartdata[j].order_qty;
        }
      },
  
      // ************* Live Chat ********************
  
      start_live_chat() {
        axios.get(api_calls.chat_group_api()).then((response) => {
          this.chat_group_name = response.data.chat_group_name;
          this.live_chat_token = response.data.token;
          this.chat_socket = new Socket(
            process.env.VUE_APP_LIVE_CHAT_WEBSOCKET_ENDPOINT,
            {
              params: {
                company: this.company_name,
                token: this.live_chat_token,
              },
            }
          );
          this.chat_socket.connect();
          this.support_channel = this.chat_socket.channel(
            "companies:" + this.company_name
          );
          this.support_channel.join();
          const vm = this;
          this.support_channel.push("customer_wants_presence");
          this.support_channel.on("customer_gets_presence", (res) => {
            if (Boolean(res[vm.company_name]) === true) {
              var company_presence = res[vm.company_name]["metas"];
              let self_user_index = company_presence
                .map(function (e) {
                  return e.user_info.username;
                })
                .indexOf(vm.user_data.username);
              if (self_user_index !== -1) {
                company_presence.splice(self_user_index, 1);
              }
              let present_role_list = [];
              company_presence.filter((each) =>
                present_role_list.push(each.user_info.role)
              );
              let unique_role_list = present_role_list.filter(
                (x, i, a) => a.indexOf(x) == i
              );
              if (unique_role_list.includes("support agent")) {
                vm.is_support_agent_present = true;
              } else {
                vm.is_support_agent_present = false;
              }
            } else {
              vm.is_support_agent_present = false;
            }
            vm.connect_support_agent();
          });
        });
      },
      connect_support_agent() {
        var vm = this;
        this.support_channel.leave();
        this.chat_socket.disconnect();
        this.chat_socket = null;
        if (this.is_support_agent_present === true) {
          var msg = {};
          msg.receiving = true;
          msg.received =
            "I am connecting you to one of our support agents. Please wait for a while.";
          msg.conversation_only = true;
          msg.time = this.generate_time();
          this.update_scrollbar("upto_end", "response");
          this.chat.push(msg);
          this.chat.push({
            receiving: true,
            received: "What's your name?",
            conversation_only: true,
            time: this.generate_time(),
          });
          this.to_be_called = true

          this.new_update_response(0);
          this.scroll_down_bottom();
          axios.get(api_calls.chat_group_api()).then((response) => {
            this.chat_group_name = response.data.chat_group_name;
            this.live_chat_token = response.data.token;
            this.live_chat_on = true;
            this.chat_socket = new Socket(
              process.env.VUE_APP_LIVE_CHAT_WEBSOCKET_ENDPOINT,
              {
                params: {
                  company: this.company_name,
                  chat_group_name: this.chat_group_name,
                  token: this.live_chat_token,
                },
              }
            );
            this.chat_socket.connect();
            this.channel = this.chat_socket.channel(
              "customers_groups:" + this.chat_group_name
            );
            this.channel.join();
            this.channel.push("customer_needs_support_agent");
  
            setInterval(function () {
              vm.channel.push("customer_needs_support_agent");
            }, 2000);
            setInterval(function () {
              vm.channel.push("customer_group_self_presence");
            }, 2000);
            this.channel.on("customer_group_self_presence", (res) => {
              var meta = res.metas;
              for (var i in meta) {
                if (!meta[i].user_info.is_customer) {
                  this.support_agent_info = meta[i].user_info;
                }
              }
            });
  
            this.channel.on("new_chat_message", (res) => {
              if (res.sender != "customer") {
                this.chat.push({
                  receiving: true,
                  received: res.message,
                  time: this.generate_time(),
                  conversation_only: true,
                });
                this.to_be_called = true

                setTimeout(() => {
                  this.scroll_down_bottom();
                  if (
                    $(".chat-body").children().last().height() /
                      $(".chat-body").height() <
                    0.5
                  ) {
                    this.animate_chat_body_scroll(1, 1500);
                  } else if (
                    $(".receiver").last().parent().height() /
                      $(".chat-body").height() >
                      0.5 &&
                    $(".receiver").last().parent().height() /
                      $(".chat-body").height() <
                      0.6
                  ) {
                    this.animate_chat_body_scroll(0.9, 1500);
                  } else {
                    this.animate_chat_body_scroll(0.75, 1500);
                  }
                }, 1000);
                if (
                  $(".chat-body").height() -
                    $(".chat-body").prop("scrollHeight") !=
                  0
                ) {
                  this.scroll_down_bottom();
                }
              }
            });
            this.channel.on("stopped_chat", (res) => {
              this.chat.push({
                conversation_only: true,
                receiving: true,
                received: "Support agent " + res.message,
                time: this.generate_time(),
              });
              this.to_be_called = true

              this.live_chat_on = false;
              this.support_agent_info = null;
              this.channel.leave();
              this.live_chat_token = null;
              this.chat_group_name = null;
              this.user_name = "";
              this.chat_socket.disconnect();
              this.update_scrollbar("upto_end", "response");
            });
            this.channel.on("started_typing", (res) => {
              this.is_typing = true;
              this.is_typing_indicator_on = true;
            });
            this.channel.on("stopped_typing", (res) => {
              this.is_typing = false;
              this.is_typing_indicator_on = false;
            });
          });
        } else {
          this.support_subscription_data();
        }
      },
      disconnect_support_chat() {
        this.channel.push("stopped_chat", {
          name: this.user_name,
          message: " has ended the conversation.",
        });
        this.channel.leave();
        this.live_chat_token = null;
        this.chat_group_name = null;
        this.support_agent_info = null;
        this.user_name = "";
        this.chat_socket.disconnect();
        this.live_chat_on = false;
        this.is_typing_indicator_on = false;
      },
      // ************* ChatBot Response Methods **************
      chat_response_error() {
        this.chat.push({
          conversation_only: true,
          receiving: true,
          received: "Sorry I'm not getting your question",
          time: this.generate_time(),
        });
        this.to_be_called = true
        this.$session.set("BotResponse_Conversation", this.chat);
        this.update_scrollbar("upto_end", "response");
      },
      response_handling(response) {
        var i = 0;
        var delay = 50;
        var vm = this;
        vm.is_typing_indicator_on = true;
        setTimeout(() => {
          handle_response();
        }, delay);
  
        function handle_response() {
          setTimeout(() => {
            if (response.data == null) {
              vm.chat_response_error();
            } else if (response.data.responses.length == 0) {
              vm.chat_response_error();
            } else if (
              response.data.responses[i].intent == "support_subscription_data"
            ) {
              vm.support_subscription_data();
            } else if (response.data.responses[i].intent == "product_by_date") {
              vm.is_typing_indicator_on = true;
              let cutoff = new Date();
              var current_date_time = moment(cutoff).format(
                "YYYY-MM-DD HH:mm:ss"
              );
  
              let form_payload = JSON.stringify({
                usr_msg: response.data.responses[i].usr_msg,
                current_user_date: current_date_time,
              });
  
              axios
                .post(api_calls.chatbot_response(), {
                  license_key: vm.user_data.license_key,
                  token: vm.bot_token_response_value,
                  username: vm.user_data.username,
                  refreshed_or_closed: vm.refreshed_or_closed,
                  chat: `/product_by_date${form_payload}`,
                  source: "Web",
                })
                .then((response) => {
                  vm.response_handling(response);
                });
            } else if (
              Boolean(response.data.responses[i].messaging_platforms) &&
              response.data.responses[i].messaging_platforms.length > 0
            ) {
              var msg = {
                time:
                  i == response.data.responses.length - 1
                    ? vm.generate_time()
                    : null,
                ask_feedback: Boolean(response.data.responses[i].ask_feedback),
                show_messaging_platforms: true,
                messaging_platforms_data:
                  response.data.responses[i].messaging_platforms,
              };
              vm.chat.push(msg);
            } else if (Boolean(response.data.responses[i].is_display_banner)) {
              vm.display_banner(response.data.responses, i);
            } else {
              var msg = {
                conversation_only: true,
                time:
                  i == response.data.responses.length - 1
                    ? vm.generate_time()
                    : undefined,
                image:
                  response.data.responses[i].img == ""
                    ? null
                    : response.data.responses[i].img,
                videos:
                  response.data.responses[i].video == null
                    ? false
                    : response.data.responses[i].video,
              };
              if (
                response.data.responses[i].buttons != undefined &&
                response.data.responses[i].buttons.length > 0
              ) {
                vm.load_buttons(response.data.responses[i], i);
              } else if (
                response.data.responses[i].get_company_details === "True" &&
                response.data.responses[i].intent === "cense_support_ticket"
              ) {
                let c_id = vm.return_document_cookies("company_id");
                let c_name = vm.return_document_cookies("company_name");
                let c_email = vm.return_document_cookies("company_email");
                axios
                  .post(api_calls.chatbot_response(), {
                    license_key: vm.user_data.license_key,
                    token: vm.bot_token_response_value,
                    username: vm.user_data.username,
                    role: vm.user_data.role,
                    refreshed_or_closed: vm.refreshed_or_closed,
                    chat: JSON.stringify({
                      company_id: c_id,
                      company_name: c_name,
                      email: c_email,
                    }),
                    source: "Web",
                  })
                  .then((response) => {
                    vm.response_handling(response);
                  });
              } else if (
                response.data.responses[i].template === "utter_default"
              ) {
                msg.receiving = true;
                msg.received = response.data.responses[i].message
                  .split("{email}")
                  .join(vm.contact_help_email);
              } else if (
                Boolean(response.data.responses[i].custom) &&
                response.data.responses[i].custom.type
              ) {
                if (
                  response.data.responses[i].custom.type != undefined &&
                  response.data.responses[i].custom.type == "retail"
                ) {
                  vm.display_products_chat(response.data.responses[i], i);
                }
              } else if (response.data.responses[i].intent != undefined) {
                if (response.data.responses[i].intent == "chat_support") {
                  vm.start_live_chat();
                } else if (
                  i <= response.data.responses.length - 1 ||
                  (i < response.data.responses.length - 1 &&
                    response.data.responses[i + 1].intent == "confusion" &&
                    response.data.responses[i - 1].intent == "confusion") ||
                  response.data.responses[i].intent == "confusion"
                ) {
                  vm.chat.push({
                    receiving: true,
                    received: response.data.responses[i].text,
                    conversation_only: true,
                    time: vm.generate_time(),
                  });

                  i += 1;
                  vm.$session.set("BotResponse_Conversation", vm.chat);
                } else {
                  vm.send_message(
                    "custom_message",
                    "/" + response.data.responses[i].intent
                  );
                }
              } else if (
                response.data.responses[i].text != undefined &&
                (response.data.responses[i].text.includes(
                  "Download your manifest here"
                ) 
                // ||
                //   response.data.responses[i].text.includes(".pdf")
                  )
              ) {
                vm.display_pdf_chat(response.data.responses[i]);
              } else if (
                response.data.responses[i].text != undefined &&
                !response.data.responses[i].text.includes(
                  "Download your manifest here"
                ) &&
                response.data.responses[i].text !=
                  "Sorry I am not getting your question"
              ) {
                msg.received = response.data.responses[i].text;
                msg.receiving = true;
                msg.image = response.data.responses[i].img;
                msg.image = response.data.responses[i].image;
                msg.videos = response.data.responses[i].video || false;
                msg.order_status = response.data.responses[i].order_status;
                msg.is_order_status =
                  response.data.responses[i].is_order_status || false;
                msg.offers = response.data.responses[i].offers || false;
                msg.fetch_shopify_details =
                  response.data.responses[i].fetch_shopify_details || false;
                msg.order_items = response.data.responses[i].order_items;
                msg.shopify_fetch_customer_id_for_offers =
                  response.data.responses[i]
                    .shopify_fetch_customer_id_for_offers || false;
  
                if (msg.offers.length < 1) {
                  msg.offers = false;
                  msg.received =
                    "Sorry we do not have any offers currently, please check back later.";
                }
              }
              // else if (response.data.responses[i].products != undefined) {
              //   vm.handle_products(msg, response.data.responses[i]);
              // }
              else if (
                response.data.responses[i].text != undefined &&
                response.data.responses[i].text ==
                  "Sorry I am not getting your question"
              ) {
                if (
                  this.user_data.license_key ==
                    ".eJxTcssvSs1Mz6tIzkjMS081NDexNIQKKUDFMkpKCoqt9PXLy8v10lBU6yXn5-olluobGRha6hqY6xqYKBgaWBkZW5ma6hmbm5gYmSoBAGa9Hp0.XR3Tuw.X77F_7LDtOLp2Vt9snFDSo31nTw" ||
                  this.user_data.license_key ==
                    ".eJxTci4tLsnPTS3ySCwoyMxLLS62NDW0MIWJKsCFM0pKCoqt9PXLy8v1kjOKE_USU_WNDAwtdQ3MdQ2NFAwNrYDIxFzPxMTS0tJICQBjZxux.XShq8w.Pgo5cmKwMQFnYUsscpojwEqDXQw"
                ) {
                  msg.receiving = true;
                  msg.received = "Would you like to talk with support team?";
                  msg.conversation_only = true;
                  msg.show_buttons = true;
                  vm.chat.push({
                    receiving: true,
                    received: "Sorry I am not getting your question",
                    conversation_only: true,
                    time: vm.generate_time()
                  });
                  vm.to_be_called = true
                  this.to_be_called = true
                  vm.$session.set("BotResponse_Conversation", vm.chat);
                } else {
                  msg.receiving = true;
                  msg.received = "Sorry I am not getting your question";
                }
              } else if (
                Boolean(response.data.responses[i].products) &&
                (response.data.responses[i].products.length > 0 ||
                  Object.keys(response.data.responses[i].products).length)
              ) {
                vm.display_products_chat(response.data.responses[i], i);
              } else if (response.data.responses[i].is_refund == "True") {
                vm.load_refunds(response.data.responses[i]);
              } else if (
                response.data.responses[i].shopify_fetch_customer_id === true
              ) {
                vm.retail_check_customer_logged_in();
              } else if (response.data.responses[i].image != undefined) {
                msg.image = response.data.responses[i].image;
                msg.receiving = true;
              } else if (response.data.responses[i].video != undefined) {
                msg.videos = response.data.responses[i].video || false;
                msg.receiving = true;
              } else if (
                response.data.responses[i].card != undefined &&
                response.data.responses[i].card.length !== 0
              ) {
                msg.carousel_card_list = response.data.responses[i].card;
              }
              if (
                i > response.data.responses.length - 1 ||
                (i <= response.data.responses.length - 1 &&
                  response.data.responses[i].products == undefined)
              ) {
              if(vm.autoAudioEnabled === true && msg.receiving === true){
                let index = vm.chat.length
                vm.text_to_speech(msg.received , index , 'widgetTextStop' , 'widgetTextPlay','widgetTextAudio', msg)
              }else{

                vm.chat.push(msg);
              }
                vm.$session.set("BotResponse_Conversation", vm.chat);
  
                if (i == 0 || i > response.data.responses.length - 1) {
                  setTimeout(() => {
                    vm.scroll_down();
                    if (
                      $(".chat-body").children().last().height() /
                        $(".chat-body").height() <
                      0.5
                    ) {
                      vm.animate_chat_body_scroll(1, 1500);
                    } else if (
                      $(".receiver").last().parent().height() /
                        $(".chat-body").height() >
                        0.5 &&
                      $(".receiver").last().parent().height() /
                        $(".chat-body").height() <
                        0.6
                    ) {
                      vm.animate_chat_body_scroll(0.9, 1500);
                    } else {
                      vm.animate_chat_body_scroll(0.75, 1500);
                    }
                  }, 1000);
                }
                if (
                  $(".chat-body").height() -
                    $(".chat-body").prop("scrollHeight") !=
                  0
                ) {
                  vm.to_scroll = true;
                } else {
                  vm.to_scroll = false;
                }
                vm.$session.set("BotResponse_Conversation", vm.chat);
              }
            }
            if (i < response.data.responses.length - 1) {
              i += 1;
              vm.is_typing_indicator_on = true;
              delay = 500;
              handle_response();
            }
          }, delay);
        }
          this.to_be_called = true
          vm.is_typing_indicator_on = false;
          this.scroll_down_bottom()

      },
      return_document_cookies(name) {
        let cookie = {};
        document.cookie.split(";").forEach(function (el) {
          let [k, v] = el.split("=");
        });
        return cookie[name];
      },
      support_subscription_data() {
        axios
          .post(api_calls.chatbot_response(), {
            license_key: this.user_data.license_key,
            username: this.user_data.username,
            token: this.bot_token_response_value,
            refreshed_or_closed: this.refreshed_or_closed,
            chat: "/support_subscription_data",
            source: "Web",
          })
          .then((response) => {
            this.response_handling(response);
          });
      },
      display_banner(response, i) {
        var vm = this;
        var position_1 = response[i].banner_content_position[0]["id"];
        var position_2 = response[i].banner_content_position[1]["id"];
        var position_3 = response[i].banner_content_position[2]["id"];
        var is_data_position_1 = response[i][position_1];
        var is_data_position_2 = response[i][position_2];
        var is_data_position_3 = response[i][position_3];
        if (Boolean(is_data_position_1)) {
          var msg = {
            conversation_only: true,
            time:
              i == response.length - 1 &&
              !(Boolean(is_data_position_2) || Boolean(is_data_position_3))
                ? vm.generate_time()
                : null,
            ask_feedback: Boolean(response[i].ask_feedback),
            banner_img_link: response[i].banner_img_link,
          };
          msg.show_bot_img = true;
          msg["show_" + position_1] = true;
          msg[position_1] = is_data_position_1;
          vm.chat.push(msg);
          vm.new_update_response(i);
          if (Boolean(is_data_position_2)) {
            var msg = {
              conversation_only: true,
              time:
                i == response.length - 1 && !Boolean(is_data_position_3)
                  ? vm.generate_time()
                  : null,
              ask_feedback: Boolean(response[i].ask_feedback),
              banner_img_link: response[i].banner_img_link,
            };
            msg.show_bot_img = false;
            msg["show_" + position_2] = true;
            msg[position_2] = is_data_position_2;
            vm.chat.push(msg);
            vm.new_update_response(i);
          }
          if (Boolean(is_data_position_3)) {
            var msg = {
              conversation_only: true,
              time: i == response.length - 1 ? vm.generate_time() : null,
              ask_feedback: Boolean(response[i].ask_feedback),
              banner_img_link: response[i].banner_img_link,
            };
            msg.show_bot_img = false;
            msg["show_" + position_3] = true;
            msg[position_3] = is_data_position_3;
            vm.chat.push(msg);
            vm.new_update_response(i);
          }
        } else if (Boolean(is_data_position_2)) {
          var msg = {
            conversation_only: true,
            time:
              i == response.length - 1 && !Boolean(is_data_position_3)
                ? vm.generate_time()
                : null,
            ask_feedback: Boolean(response[i].ask_feedback),
            banner_img_link: response[i].banner_img_link,
          };
          msg.show_bot_img = true;
          msg["show_" + position_2] = true;
          msg[position_2] = is_data_position_2;
          vm.chat.push(msg);
          vm.new_update_response(i);
          if (Boolean(is_data_position_3)) {
            var msg = {
              conversation_only: true,
              time: i == response.length - 1 ? vm.generate_time() : null,
              ask_feedback: Boolean(response[i].ask_feedback),
              banner_img_link: response[i].banner_img_link,
            };
            msg.show_bot_img = false;
            msg["show_" + position_3] = true;
            msg[position_3] = is_data_position_3;
            vm.chat.push(msg);
            vm.new_update_response(i);
          }
        } else if (Boolean(is_data_position_3)) {
          var msg = {
            conversation_only: true,
            time: i == response.length - 1 ? vm.generate_time() : null,
            ask_feedback: Boolean(response[i].ask_feedback),
            banner_img_link: response[i].banner_img_link,
          };
          msg.show_bot_img = true;
          msg["show_" + position_3] = true;
          msg[position_3] = is_data_position_3;
          vm.chat.push(msg);
          vm.new_update_response(i);
        }
      },
      load_buttons(message, index, type, greeting_button) {
        // console.log(message, index, type, greeting_button);
        var msg = {
          conversation_only: true,
          buttons_list: [],
          button_prefix: message.prefix,
          receiving: true,
          received: message.text,
          time: this.generate_time(),
          removable: type == "welcome_message" ? false : true,
          is_greeting: message.is_greeting || false,
        };
        this.to_be_called = true

        if (Boolean(greeting_button)) {
          msg.greeting_button = true;
        } else {
          msg.custom_buttons = true;
        }
        if (message.buttons.length == 3) {
          if (
            message.buttons[0].title == "Exchange Rate" &&
            message.buttons[1].title == "Track My Parcel" &&
            message.buttons[2].title == "Call Helpdesk"
          ) {
            msg.removable = false;
          }
        }
        for (var i in message.buttons) {
          msg.buttons_list.push({
            title: message.buttons[i].title,
            value: message.buttons[i].value,
            type: Boolean(message.buttons[i].type) ? message.buttons[i].type : undefined,
          });
        }
        if (index == "is_button" || index == 0) {
          setTimeout(() => {
            if (
              $(".chat-body").children().last().height() /
                $(".chat-body").height() <
              0.5
            ) {
              this.animate_chat_body_scroll(1, 1500);
            } else {
              this.animate_chat_body_scroll(50, 1500, true);
            }
          }, 1000);
        } else {
          this.scroll_down_bottom();
        }
        this.chat.push(msg);
        this.$session.set("BotResponse_Conversation", this.chat);
        if (Boolean(greeting_button)) {
          this.get_all_buttons();
        }
      },
      // ************* Reusable methods *************
      remove_custom_button(index){
        var vm = this;
        if (
          vm.chat[index] &&
          vm.chat[index].removable == true
        ) {
          document
            .querySelector("#chat" + index)
            .classList.add("--delete");
          setTimeout(() => {
            vm.chat.splice(index, 1);
          }, 850);
        }
      },
      scroll_div() {
        var doc = document.querySelector(".buy-products");
        if (event.deltaX > event.deltaY) {
          doc.scrollLeft += 10;
        } else if (event.deltaX < event.deltaY) {
          doc.scrollLeft -= 10;
        }
      },
      scroll_div1(item) {
        var doc = document.querySelector(`#buy_product_id_${item}`);
        if (event.deltaX > event.deltaY) {
          doc.scrollLeft += 10;
        } else if (event.deltaX < event.deltaY) {
          doc.scrollLeft -= 10;
        }
      },
      scroll_div_right(item) {
        var doc = document.querySelector(`#buy_product_id_${item}`);
        doc.scrollLeft += 100;
      },
      scroll_div_left(item) {
        var doc = document.querySelector(`#buy_product_id_${item}`);
        doc.scrollLeft -= 100;
      },
      scroll_down() {
        let timeElementHeight = Boolean($(".chat-his").last().height())
          ? $(".chat-his").last().height()
          : 0;
        if (
          document.querySelector(".chat-body").scrollHeight -
            document.querySelector(".chat-body").scrollTop -
            timeElementHeight <=
          document.querySelector(".chat-body").clientHeight
        ) {
          this.to_scroll = false;
        } else {
          this.to_scroll = true;
        }
      },
      display_pdf_chat(message) {
        var msg = {
          conversation_only: true,
          time: this.generate_time(),
        };
        if (message.text.includes("Download your manifest here ")) {
          var temp = message.text.split("Download your manifest here ");
          msg.url = temp[1];
          msg.receiving = false;
          msg.file_name = message.text.split(/^.*[\\\/]/)[1];
          this.chat.push(msg);
          this.$session.set("BotResponse_Conversation", this.chat);
          this.update_scrollbar("upto_end", "response");
        } else {
          let url = message.text.match(
            /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/g
          )[0];
          msg.url = url;
          msg.receiving = false;
          msg.file_name = url.split(/^.*[\\\/]/)[1];
          this.chat.push(msg);
          this.$session.set("BotResponse_Conversation", this.chat);
          this.update_scrollbar("upto_end", "response");
        }
      },
      generate_time() {
        var d = new Date();
        var h, m, type;
        if (d.getHours() > 12) {
          h = d.getHours() % 12;
          type = " pm";
        } else if (d.getHours() < 12) {
          h = d.getHours();
          type = " am";
        } else if (d.getHours() == 12) {
          h = d.getHours();
          type = " pm";
        }
        m = d.getMinutes();
        if (m < 10) {
          m = 0 + String(m);
        }
        return h + ":" + m + type;
      },
      parse(string) {
        return string.replace(/{{.*?}}/g, (match) => {
          var today = new Date();
          var dd = today.getDate();
  
          var mm = today.getMonth() + 1;
          var yyyy = today.getFullYear();
          if (dd < 10) {
            dd = "0" + dd;
          }
  
          if (mm < 10) {
            mm = "0" + mm;
          }
          today = dd + "-" + mm + "-" + yyyy;
          var expression = match.slice(2, -2);
          this.$data[expression] = today;
          return this.$data[expression];
        });
      },
      click_prompt(type) {
        var divclickpopup = event.target.innerText;
        setTimeout(() => {
          this.is_typing_indicator_on = false;
        }, 500);
        this.is_typing_indicator_on = true;
        if (type != "collapsible") {
          axios
            .post(api_calls.bot_prompt_url(), {
              uid: "cense",
              license_key: this.user_data.license_key,
              chat: event.target.innerText,
              level: event.target.attributes.value.nodeValue,
            })
            .then((response) => {
              if (response.data.collapsible == true) {
                this.create_chat(response.data);
                this.update_scrollbar();
                this.is_typing_indicator_on = false;
              } else if (
                response.data.extras != undefined &&
                response.data.extras[0].Type == "TABLE"
              ) {
                this.create_chat(response.data, "istable");
                this.update_scrollbar();
                this.is_typing_indicator_on = false;
              } else {
                if (response.data != "Oops! Something went wrong!") {
                  this.chat.push(response.data);
                  this.$session.set("BotResponse_Conversation", this.chat);
                  this.level = response.data.level;
                  this.update_scrollbar();
                  this.is_typing_indicator_on = false;
                } else if (response.data == "Oops! Something went wrong!") {
                  this.chat.push(response.data);
                  this.$session.set("BotResponse_Conversation", this.chat);
                  this.update_scrollbar();
                  this.is_typing_indicator_on = false;
                }
              }
            });
        }
      },
      download_pdf(url, file_name) {
        event.preventDefault();
        var link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", file_name);
        document.body.appendChild(link);
        link.click();
      },
      // button_fill() {
      //   if (this.to_send == "") {
      //     document.querySelector("#send_button").classList.remove("filled");
      //   } else if (this.to_send != "") {
      //     if (this.live_chat_on) {
      //       this.channel.push("started_typing");
      //       this.stop_typing(this);
      //     }
      //     document.querySelector("#send_button").classList.add("filled");
      //   }
      // },
  
      // ###karthik### 
      button_fill() {
        var div = document.getElementById("button_class");
        if (this.to_send == "" && div.classList.contains('active')) {
          document.querySelector("#button_class").disabled = true;
        } else if (this.to_send != "") {
          if (this.live_chat_on) {
            this.channel.push("started_typing");
            this.stop_typing(this);
          }
          document.querySelector("#button_class").disabled = true;
        }
      },
      // ###karthik###
      animate_chat_body_scroll(scale, delay, offset) {
        if (Boolean(offset)) {
          $(".chat-body").animate(
            {
              scrollTop: $(".chat-body").prop("scrollTop") + scale,
            },
            delay
          );
        } else {
          $(".chat-body").animate(
            {
              scrollTop: scale * $(".chat-body").prop("scrollHeight"),
            },
            delay
          );
        }
      },
      update_scrollbar(type, msgType, iswelcome, delay_value) {
        var scroll_delay = iswelcome == "is_welcome" ? delay_value : 2000;
        this.is_typing_indicator_on = true;
        if (msgType == "response") {
          setTimeout(() => {
            this.is_typing_indicator_on = false;
          }, scroll_delay);
        } else if (msgType == "sender") {
          this.animate_chat_body_scroll(1, 750);
        }
        if (msgType == "response") {
          if (type == "upto_end") {
            setTimeout(() => {
              if (
                $(".chat-body").prop("scrollTop") <
                $(".chat-body").prop("scrollHeight")
              ) {
                if (
                  $(".receiver").last().parent().height() /
                    $(".chat-body").height() >
                  0.5
                ) {
                  this.animate_chat_body_scroll(0.75, 1500);
                  this.scroll_down_bottom();
                }
              } else if (
                $(".receiver").last().parent().height() /
                  $(".chat-body").height() <
                0.5
              ) {
                this.animate_chat_body_scroll(1, scroll_delay);
              }
            }, scroll_delay);
          } else {
            setTimeout(() => {
              document.querySelector(".chat-body").scrollTop =
                document.querySelector(".chat-body").scrollHeight -
                document.querySelector(".chat-body").lastChild.scrollHeight;
            }, 1000);
          }
        }
      },
      new_update_response(index) {
        if (index == 0) {
          setTimeout(() => {
            this.scroll_down_bottom();
            if (
              $(".chat-body").children().last().height() /
                $(".chat-body").height() <
              0.5
            ) {
              this.animate_chat_body_scroll(1, 1500);
            } else if (
              $(".receiver").last().parent().height() / $(".chat-body").height() >
                0.5 &&
              $(".receiver").last().parent().height() / $(".chat-body").height() <
                0.6
            ) {
              this.animate_chat_body_scroll(1, 1500);
            } else {
              this.animate_chat_body_scroll(1, 1500);
            }
          }, 1000);
        }
        if (
          $(".chat-body").height() - $(".chat-body").prop("scrollHeight") !=
          0
        ) {
          this.scroll_down_bottom();
        }
        if (
          $(".chat-body").height() - $(".chat-body").prop("scrollHeight") ===
          0
        ) {
          this.to_scroll = false;
        }
      },
      open_messaging_framework_integration(url) {
        var link = document.createElement("a");
        link.href = url;
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
      get_time_difference(dt1, dt2) {
        dt1 = new Date(dt1);
        dt2 = new Date(dt2);
        let diff = (dt2.getTime() - dt1.getTime()) / 1000;
        diff /= 60 * 60;
        return Math.abs(Math.round(diff));
      },
      // ************* Scheduler Methods ************
  
      fix_appointment() {
        let date = new Date();
        let dateString =
          date.getDate() +
          " " +
          this.months[date.getMonth()] +
          " " +
          date.getFullYear().toString();
        let startTime, endTime;
        if (this.appointment_start.split(":")[0] < 12) {
          startTime = this.appointment_start + " AM";
        } else {
          startTime = (Number(this.appointment_start.split(":")[0]) % 12) + " PM";
        }
        if (this.appointment_end.split(":")[0] < 12) {
          endTime = this.appointment_end + " AM";
        } else {
          endTime = (Number(this.appointment_end.split(":")[0]) % 12) + " PM";
        }
        this.events.push({
          start: dateString + " " + this.appointment_start,
          end: dateString + " " + this.appointment_end,
          title: this.appointment_name,
          reason: this.appointment_reason,
        });
        this.is_appointment = false;
        this.chat.push({
          receiving: true,
          received:
            "Your appointment has been successfully created at " +
            startTime +
            " on " +
            date.getDate() +
            " " +
            this.months[date.getMonth()] +
            " " +
            date.getFullYear(),
          conversation_only: true,
          time: this.generate_time(),
        });
        this.update_scrollbar("upto_end", "response");
        this.to_be_called = true

      },
      create_calendar_event(startTime, endTime) {
        this.appointment_start = startTime;
        this.appointment_end = endTime;
        this.is_appointment = true;
        this.chat.push({
          receiving: true,
          received: "Please enter your name",
          time: this.generate_time(),
          conversation_only: true,
        });
        event.target.style.backgroundColor = "green !important";
        this.update_scrollbar("upto_end", "response");
        this.to_be_called = true

      },
  
      // ************** Others **********************
      custom_button_click(button, index){
        if(Boolean(button.type) && Boolean(button.type === 'url')){
          // this.remove_custom_button(index);
          window.open(button.value, "_blank");
        }else if(Boolean(button.type) && Boolean(button.type === 'web_url')){
          this.remove_custom_button(index);
          window.open(button.value, "_blank");
        }else{
          this.send_message('is_button', button, index);
        }
      },
      send_message(type, message, index) {
        var vm = this;
        if (type == "is_button") {
          vm.is_typing_indicator_on = true;
          this.update_scrollbar("upto_end", "sender");
          if (message.title == "Live Chat" && message.value == "/live_chat") {
            this.start_live_chat();
            this.update_scrollbar("upto_end", "response");
            if (this.chat[index].removable == true) {
              document.querySelector("#chat" + index).classList.add("--delete");
              setTimeout(() => {
                vm.chat.splice(index, 1);
              }, 850);
              this.welcome_message_not_yet_received = false;
            } else {
              this.welcome_message_not_yet_received = false;
            }
          } else if (message.value == "Websocket_Yes") {
            this.is_typing_indicator_on = false;
            if (this.chat[index].removable == true) {
              document.querySelector("#chat" + index).classList.add("--delete");
              setTimeout(() => {
                vm.chat.splice(index, 1);
                vm.chat.push({
                  sent: "Yes",
                  sending: true,
                  time: vm.generate_time(),
                  conversation_only: true,
                  delivered: true,
                });
              }, 850);
            }
          } else if (message.value == "Websocket_No") {
            this.disconnect_support_chat();
          } else {
            this.update_scrollbar("upto_end", "sender");
            if (this.chat[index] && this.chat[index].removable == true) {
              document.querySelector("#chat" + index).classList.add("--delete");
              setTimeout(() => {
                vm.chat.splice(index, 1);
                vm.chat.push({
                  sent: message.title,
                  sending: true,
                  time: vm.generate_time(),
                  conversation_only: true,
                  delivered: true,
                  dropdown: "",
                });
              }, 850);
              this.welcome_message_not_yet_received = false;
            } else {
              this.welcome_message_not_yet_received = false;
              this.chat.push({
                sent: message.title,
                sending: true,
                time: this.generate_time(),
                conversation_only: true,
                delivered: true,
                dropdown: "",
              });
            }
            this.to_be_called = false

            axios
              .post(api_calls.chatbot_response(), {
                host: this.user_data.host,
                chat: message.value,
                token: this.bot_token_response_value,
                username: this.user_data.username,
                source: "Web",
                license_key: this.user_data.license_key,
                refreshed_or_closed: this.refreshed_or_closed,
              })
              .then((response) => {
                this.response_handling(response);
              });
          }
        } else if (type == "custom_message") {
          this.is_typing_indicator_on = true;
          axios
            .post(api_calls.chatbot_response(), {
              host: this.user_data.host,
              license_key: this.user_data.license_key,
              token: this.bot_token_response_value,
              username: this.user_data.username,
              source: "Web",
              chat: message,
              refreshed_or_closed: this.refreshed_or_closed,
            })
            .then((response) => {
              this.response_handling(response);
            });
        } else if (
          this.to_send != "" &&
          this.to_send != null &&
          this.live_chat_on
        ) {
          if (Boolean(this.user_name) === false) {
            this.channel.push("new_name", { name: this.to_send });
            this.user_name = this.to_send;
            this.chat.push({
              sent: this.to_send,
              sending: true,
              conversation_only: true,
              time: this.generate_time(),
            });
            this.$session.set("BotResponse_Conversation", this.chat);
            this.to_send = "";
            this.update_scrollbar("upto_end", "sender");
            this.is_typing_indicator_on = false;
          } else {
            this.channel.push("new_name", { name: this.user_name });
            this.channel.push("new_chat_message", { message: this.to_send });
            this.chat.push({
              sent: this.to_send,
              sending: true,
              conversation_only: true,
              time: this.generate_time(),
            });
            this.to_send = "";
            this.$session.set("BotResponse_Conversation", this.chat);
            this.update_scrollbar("upto_end", "response");
          }
        } else if (
          this.to_send != "" &&
          this.to_send != null &&
          !/^\s*$/.test(this.to_send) &&
          this.is_appointment
        ) {
          this.is_typing_indicator_on = true;
          var send_msg = {
            sent: this.to_send,
            conversation_only: true,
            sending: true,
            time: this.generate_time(),
          };
          this.chat.push(send_msg);
          this.$session.set("BotResponse_Conversation", this.chat);
          this.update_scrollbar("upto_end", "response");
          var len = this.chat.length;
          if (
            this.chat[len - 2].receiving &&
            this.chat[len - 2].received ==
              "Please enter the reason for the appointment"
          ) {
            this.appointment_reason = this.to_send;
            this.fix_appointment();
          } else {
            this.chat.push({
              received: "Please enter the reason for the appointment",
              conversation_only: true,
              receiving: true,
              time: this.generate_time(),
            });
            this.appointment_name = this.to_send;
            this.update_scrollbar("upto_end", "response");
          }
          this.to_send = "";
          this.to_be_called = true

        } else if (
          this.to_send != "" &&
          this.to_send != null &&
          !/^\s*$/.test(this.to_send)
        ) {
          this.is_typing_indicator_on = true;
          let input = document.getElementById('response_bot_text')
          let input_type = input.getAttribute('type')
          let astriks = this.to_send;
          if(input_type ==='password'){
            astriks = ''
            input.setAttribute("type","text")
            let sent_len = this.to_send.length;
            for(let i = 0; i < sent_len; i++){
              astriks += "*"
            }
          }
          var send_msg = {
            sent: astriks,
            conversation_only: true,
            sending: true,
            time: this.generate_time(),
          };
          this.chat.push(send_msg);
          this.$session.set("BotResponse_Conversation", this.chat);
          this.update_scrollbar("upto_end", "sender");
          axios
            .post(api_calls.chatbot_response(), {
              host: this.user_data.host,
              license_key: this.user_data.license_key,
              token: this.bot_token_response_value,
              username: this.user_data.username,
              source: "Web",
              chat: this.to_send,
              refreshed_or_closed: this.refreshed_or_closed,
            })
            .then((response) => {
              let input_type = response.data.responses[0].input_type
              if(input_type){
                let input = document.getElementById('response_bot_text')
                input.setAttribute("type",input_type)
              }
              this.response_handling(response);
            });
          this.to_send = "";
        }
        this.button_fill();
      },
      custom_greetings_buttom_alignment(show_custom_cart) {
        if (show_custom_cart) {
          return "col-sm-11 px-0";
        } else {
          return "col-sm-12 px-0";
        }
      },
      methods_to_call_after_greetings_when_not_bot_history() {
        this.get_visitors_current_location();
      },
      methods_to_call_after_greetings_when_bot_history() {
        this.is_review_rating();
        this.display_products_on_landing = false;
      },
      get_visitors_current_location() {
        if (this.is_display_banner_on_landing === true) {
          let cutoff = new Date();
          var date_time = moment
            .utc(cutoff, ["YYYY-MM-DD HH:mm:ss"])
            .format("YYYY-MM-DD HH:mm:ss");
          var vm = this;
          $.getJSON(vm.visitors_current_location_url, function (data) {
            if (data) {
              vm.visitors_current_location = {
                ip_address: data.ip_address,
                city: data.city,
                city_geoname_id: data.city_geoname_id,
                region: data.region,
                region_iso_code: data.region_iso_code,
                region_geoname_id: data.region_geoname_id,
                postal_code: data.postal_code,
                country: data.country,
                country_code: data.country_code,
                country_geoname_id: data.country_geoname_id,
                country_is_eu: data.country_is_eu,
                continent: data.continent,
                continent_code: data.continent_code,
                continent_geoname_id: data.continent_geoname_id,
                longitude: data.longitude,
                latitude: data.latitude,
                security: data.security,
                date_time: date_time,
              };
              vm.is_display_banner();
            } else {
              vm.is_display_products();
            }
          })
        } else {
          this.is_display_products();
        }
      },
      push_msg(responses, response) {
        var index = 0;
        var vm = this;
        for (let i = 0; i < response.length; i++) {
          vm.is_typing_indicator_on = true;
          setTimeout(() => {
            var msg = response[i];
            vm.is_typing_indicator_on = false;
            if (
              responses.data.responses[i].buttons != undefined &&
              Boolean(responses.data.responses[i].buttons.length > 0)
            ) {
              vm.load_buttons(
                responses.data.responses[i],
                "is_button",
                "welcome_message",
                true
              );
            } else {
              vm.chat.push({
                conversation_only: true,
                receiving: true,
                received: msg.text,
                offers: msg.offers || false,
                time: i == response.length - 1 ? vm.generate_time() : undefined,
                is_custom_form: msg.is_custom_form == true ? true : null,
                fetch_shopify_details: msg.fetch_shopify_details,
                is_greeting: msg.is_greeting || false,
              });
              vm.$session.set("BotResponse_Conversation", vm.chat);
              this.to_be_called = true

            }
            if (this.greeting_buttons_position == i) {
              this.methods_to_call_after_greetings_when_not_bot_history();
            }
          }, i * 1050);
          $(".time").last().css("display", "none");
          setTimeout(() => {
            $(".time").last().css("display", "block");
            if (i < response.length - 1) {
            } else {
              vm.refreshed_or_closed = false;
              vm.welcome_message_not_yet_received = false;
            }
          }, 500);
        }
      },
      check_carousel_length(length_carousel) {
        if (length_carousel > 1) {
          return "d-flex";
        } else {
          return "carousel_card_for_single";
        }
      },
      scroll_card_right(item) {
        var doc = document.querySelector(`#carousel_cards_id_${item}`);
        doc.scrollLeft += 230;
        var maxScrollLeft = doc.scrollWidth - doc.clientWidth;
        maxScrollLeft = maxScrollLeft - 230;
        let second_bttn = document.querySelector(`#carousel_scroll_btn2_${item}`);
        let first_bttn = document.querySelector(`#carousel_scroll_btn1_${item}`);
        first_bttn.style.display = "block";
        if (doc.scrollLeft >= maxScrollLeft) {
          second_bttn.style.display = "none";
        }
      },
      scroll_card_left(item) {
        var doc = document.querySelector(`#carousel_cards_id_${item}`);
        doc.scrollLeft -= 230;
        let first_bttn = document.querySelector(`#carousel_scroll_btn1_${item}`);
        let second_bttn = document.querySelector(`#carousel_scroll_btn2_${item}`);
        second_bttn.style.display = "block";
        if (doc.scrollLeft <= 250) {
          first_bttn.style.display = "none";
        }
      },
      stop_typing: debounce(function (vm) {
        vm.channel.push("stopped_typing");
        vm.waiting_for_response = false;
      }, 3000),
      create_chat(message, type) {
        var currency_list = [];
        if (type == "istable") {
          axios
            .get("https://api.exchangeratesapi.io/latest", {
              params: {
                base: "AUD",
              },
            })
            .then((response) => {
              for (var i in message.extras[0].TABLE) {
                if (message.extras[0].TABLE[i].PROP == "TR") {
                  currency_list.push({
                    currency_title: message.extras[0].TABLE[i].TD,
                    currency_value: parseFloat(
                      Math.round(
                        response.data.rates[message.extras[0].TABLE[i]["TD_1"]] *
                          100
                      ) / 100
                    ).toFixed(4),
                    type: "TR",
                  });
                } else if (message.extras[0].TABLE[i].PROP == "THEAD") {
                  currency_list.push({
                    country_name: message.extras[0].TABLE[i].TD,
                    currency_name: message.extras[0].TABLE[i].TD_1,
                    type: "THEAD",
                  });
                }
              }
              this.chat.push({
                is_table: true,
                table_title: this.parse(message.title),
                table_list: currency_list,
              });
            });
        } else {
          this.chat.push(message);
        }
        this.$session.set("BotResponse_Conversation", this.chat);
      },
      sub_leaf_node_call(type) {
        if (type == "isdropdown") {
          event.target.disabled = true;
          var currency_value = event.target.value;
          axios
            .get("https://api.exchangeratesapi.io/latest", {
              params: {
                base: "AUD",
              },
            })
            .then((response) => {
              this.chat.push({
                conversation_only: true,
                receiving: true,
                received:
                  "Exchange rate of 1 AUD" +
                  " is " +
                  parseFloat(
                    Math.round(response.data.rates[currency_value] * 100) / 100
                  ).toFixed(4) +
                  " " +
                  currency_value,
                time: this.generate_time(),
              });
              this.$session.set("BotResponse_Conversation", this.chat);
              this.update_scrollbar("upto_end", "response");
              this.to_be_called = true
            });
        } else {
        }
      },
      get_greetings_structure(responses, response) {
        let result = [],
          vm = this;
        for (let i = 0; i < response.length; i++) {
          vm.is_typing_indicator_on = true;
          var msg = response[i];
          vm.is_typing_indicator_on = false;
          if (
            responses.data.responses[i].buttons != undefined &&
            Boolean(responses.data.responses[i].buttons.length > 0)
          ) {
            let message = responses.data.responses[i];
            var msg = {
              conversation_only: true,
              buttons_list: [],
              button_prefix: message.prefix,
              greeting_button: true,
              receiving: true,
              received: message.text,
              time: this.generate_time(),
              removable: false,
              is_greeting: message.is_greeting || false,
            };


            for (var j in message.buttons) {
              msg.buttons_list.push({
                title: message.buttons[j].title,
                value: message.buttons[j].value,
              });
            }
            result.push(msg);
          } else {
            result.push({
              conversation_only: true,
              receiving: true,
              received: msg.text,
              offers: msg.offers || false,
              time: i == response.length - 1 ? vm.generate_time() : undefined,
              is_custom_form: msg.is_custom_form == true ? true : null,
              fetch_shopify_details: msg.fetch_shopify_details,
              is_greeting: msg.is_greeting || false,
            });

          }
        }
        this.to_be_called = true
        return result;
      },
      // *********** Custom methods currently used in specific company ********************
  
      call_support(type) {
        if (type == "Yes") {
          axios
            .post(api_calls.chatbot_response(), {
              license_key: this.user_data.license_key,
              token: this.bot_token_response_value,
              username: this.user_data.username,
              source: "Web",
              chat: "/call_support",
              host: this.user_data.host,
              refreshed_or_closed: this.refreshed_or_closed,
            })
            .then((resp) => {
              this.chat.push({
                conversation_only: true,
                receiving: true,
                received: resp.data.responses[0].text,
                time: this.generate_time(),
              });
              this.$session.set("BotResponse_Conversation", this.chat);
              this.update_scrollbar("upto_end", "response");
              this.to_be_called = true
            });
        } else if (type == "No") {
          this.refreshed_or_closed = true;
          axios
            .post(api_calls.chatbot_response(), {
              license_key: this.user_data.license_key,
              token: this.bot_token_response_value,
              username: this.user_data.username,
              source: "Web",
              chat: "",
              host: this.user_data.host,
              refreshed_or_closed: this.refreshed_or_closed,
            })
            .then((resp) => {
              this.refreshed_or_closed = false;
              this.chat.push({
                receiving: true,
                received: "Is there anything else I can help you with?",
                conversation_only: true,
                time: this.generate_time(),
              });
              this.update_scrollbar("upto_end", "response");
              this.to_be_called = true
            });
        }
      },
  
      // *********** Kept for future development reference ********************************
  
      generate_payment(product, price) {
        var transfer_account;
        axios
          .post(api_calls.payment_details_url(), {
            license_key: this.user_data.license_key,
          })
          .then((response) => {
            transfer_account = response.data.AccountId;
          });
        var vm = this;
        var options = {
          key: "rzp_test_SnDTaPnncfliDt",
          amount: price * 100,
          name: "Cense AI",
          currency: "INR",
          description: "Instill Intelligence",
          image: "https://storage.cense.ai/codearray/cense_image.png", // COMPANY LOGO
          handler: function (response) {
            var contact = $('#contact[type="tel"]').value;
            var email = $('#email[type="email"]').value;
            vm.paymentid = response.razorpay_payment_id;
            if (transfer_account != null) {
              vm.transfer_payment(transfer_account, price * 100, "INR");
            }
          },
          prefill: {
            name: "", // pass customer name
            email: "", // customer email
            contact: "", //customer phone no.
          },
          notes: {
            address: "address", //customer address
          },
          theme: {
            color: "#283777", // screen color
          },
        };
        if (window.Shopify != undefined) {
          this.shopify(product);
        } else {
          var rzp1 = new Razorpay(options);
          rzp1.open();
          event.preventDefault();
        }
      },
      transfer_payment(account, amount, currency) {
        axios
          .post("https://api.razorpay.com/1/transfers", {
            auth: {
              rzp_test_SnDTaPnncfliDt: "llxEKbgABH8Lx4XApex9cGzj",
            },
            data: {
              account: account,
              amount: amount,
              currency: currency,
            },
          })
          .then((response) => {});
      },
      shopify(product) {
        var url = window.location.origin;
        var product_id;
        var quantity = 1;
        axios
          .get(url + "/products/" + product.Handle + ".js")
          .then((response) => {
            for (var i in response.data.variants) {
              if (
                response.data.variants[i].option1 == product.Option1Value &&
                response.data.variants[i].option2 == product.Option2Value &&
                response.data.variants[i].option3 == product.Option3Value
              ) {
                //check if all the options match
                product_id = response.data.variants[i].id;
              }
            }
            axios.get(url + "/cart.js").then((response1) => {
              // this.token=response.data.token;
              if (response1.data.items.length != 0) {
                for (let i = 0; i < response1.data.items.length; i++) {
                  if (response1.data.items[i].id == product_id) {
                    quantity = response1.data.items[i].quantity + 1;
                  }
                }
              }
            });
            if (window.Shopify != undefined) {
              window.Shopify.addItem(product_id, 1);
            }
          });
      },
      handle_products(msg, responses) {
        msg.isproduct = true;
        msg.receiving = false;
  
        var i = 0;
        var vm = this;
        let products = {};
  
        if (window.Shopify != undefined) {
          msg.isshopify = true;
          msg.elements = {
            products: [],
          };
          push_availablity(i);
        } else {
          msg.isshopify = false;
          msg.elements = responses;
          this.chat.push(msg);
          this.$session.set("BotResponse_Conversation", this.chat);
          this.update_scrollbar("upto_end", "response");
        }
        function push_availablity(index) {
          axios
            .get(
              window.location.origin +
                "/products/" +
                responses.products[index].Handle +
                ".js"
            )
            .then((response) => {
              products = responses.products[index];
              if (response.data.available == false) {
                products.available = false;
                msg.elements.products.push(products);
              } else {
                products.available = false;
                msg.elements.products.push(products);
              }
              if (i < responses.products.length - 1) {
                i += 1;
                push_availablity(i);
              } else if (i == responses.products.length - 1) {
                vm.chat.push(msg);
                vm.$session.set("BotResponse_Conversation", vm.chat);
                vm.update_scrollbar("upto_end", "response");
              }
            });
        }
      },
    },
  };
  </script>
  <style scoped>
  .speaker{
  position: absolute;
    top: 2px;
    cursor: pointer;
}
.speaker-send{
  right: 15px;
}
.speaker-receive{
  right: 1.5%;

}
.chat{
  position:relative;
}
@keyframes blink {
  50% {
    opacity: 0.0;
  }
}
.receiver .chat, .receiver img {
    background: #fff;
    transform: scale(1) !important;
    transform-origin: 0 0;
    color: #fff;
}
.blink {
  /* transform:gre */
  filter: grayscale(1);
    opacity: 0.5;
    pointer-events: none;
  animation: blink 0.5s step-start 0s infinite;
}
  .microphone:hover{
    color:#003080;
  }
  .fa.fa-microphone :hover{
    color:#003080;
  }
  .button1{
  border-radius: 50% !important;
    text-align: center;
    height: 40px;
    line-height: 45px;
    width: 40px;
    text-decoration: none;
    display: inline-block;
    color: rgba(255,255,255,.4);
    /* background: #06257e; */
    /* border: 1px solid #333947; */
    /* margin: 20px; */
        display: flex !important;
    justify-content: center;
    align-items: center;
    padding:0 !important;
}

.active{
  color:#fff;
}

.pushed {
  position: relative;
  box-shadow: 0 0 0 0 #000e8d;
  cursor: pointer;
  animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
  
 
}
.pushed:hover {
    animation: none;
  }
@keyframes pulse {to {box-shadow: 0 0 0 20px rgba(0, 14, 141, 0);}}
  .input-group-append{
    background:white;
  }
  #send_message{
    border-radius:0 10px 10px 0 !important;
  }
  .disable_mic{
  pointer-events:none;
  color:#a0a0a0;
}
.box {
  width: 100%;
    max-width: fit-content;
    /* border: 1px solid black; */
    padding:5px 20px;
    /* margin: 20px; */
    box-shadow: 0 1px 8px rgb(0 0 0 / 80%);
    position: absolute;
    text-align: center;
    top: -87%;
    right: 37px;
    font-size: 20px;
    font-family: sans-serif;
    background-color: #d51e1e;
    color: #f4f4f4;
    transition: all 0.3s ease-in;
    font-weight: 600;
}
#cense_app .chat-wrap .chat-txt{
  overflow:visible;
}

.box--concept {
  background: blueviolet;
  font-size: 18px;
  max-width: 100%;
  font-weight: 300;
  position: absolute;
  margin: 30px;
}
.box--concept::after {
  content: "";
  position: absolute;
  bottom: -30px;
  left: calc(50% - 20px);
  border-top: 20px solid blue;
  border-bottom: 20px solid green;
  border-right: 20px solid orange;
  border-left: 20px solid pink;
}
.box--bottom::after {
  bottom: -20px;
    right: 15px;
    border-top: 10px solid #bf2020;
    border-bottom: 10px solid transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
}

.box--bottom::after {
    content: "";
    position: absolute;
    transition: all 0.3s ease-in;
}
.chat-wrap .chat-txt{
  padding: 0px 20px 20px 20px;
  overflow:visible;
    width: 100%;
}
.timer-container {
  text-align: center;
}

.timer {
  position: relative;
  display: inline-block;
}

.progress-ring {
  transform: rotate(-90deg);
}

.progress-ring-circle {
  transition: stroke-dashoffset 1s linear;
}

.progress-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
}
.speech-error{
  position: absolute;
}
.voice-button{
  position:relative;
}
.stop_img{
  width: 25px;
    height: 25px;
    /* padding: 12px; */
    cursor: pointer;
}
.input-group-append{
  display: flex;
    justify-content: center;
    align-items: center;
}
.timer{
    width: 40px;
    height: 40px;
    display: flex;
    font-weight: 900;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    color: #0f327b;
    background: white;
    opacity:0.8;
    margin-right:5px;
}
.fa.fa-microphone {
  color: #273679;
  font-size: 24px;
}
.fas.fa-video {
  color: #8a8a8a63;
  font-size: 18px;
}
.voice-button{
  padding:0!important;
  margin:5px !important;
}
#microphone.voice-on {
  animation-name: flicker;
  animation-duration: 1s;
  animation-iteration-count: infinite;

  -webkit-animation-name: flicker;
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
}

@keyframes flicker {
  from {
    color: red;
  }
  to {
    color: #8a8a8a63;
  }
}
@-webkit-keyframes flicker {
  from {
    color: red;
  }
  to {
    color: #8a8a8a63;
  }
}
@-moz-keyframes flicker {
  from {
    color: red;
  }
  to {
    color: #8a8a8a63;
  }
}
@-o-keyframes flicker {
  from {
    color: red;
  }
  to {
    color: #8a8a8a63;
  }
}
  #cense_app.chat-master .modal-content {
      position: relative;
      display: -ms-flexbox;
      display: -webkit-box;
      display: flex;
      -ms-flex-direction: column;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
      flex-direction: column;
      width: 32vw ;
      /* min-height: 59vh; */
      pointer-events: auto;
      background-color: #fff;
      background-clip: padding-box;
      border-radius: 0.3rem;
      outline: 0;
  }
  .wider_view{
    width:80%;
  }
  .narrow_view{
    width:30%;
  }
  #cense_app.chat-master .modal-body {
      min-height: 75vh;
      /* min-width: 40vw; */
      position: absolute;
      overflow-y: auto;
  }
  #cense_app .chat-master .btn{
        font-weight: 600 !important;

    }
    .chat-act a.active{
        display: none;
    }
  
  </style>