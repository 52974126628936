// var endpoint = process.env.VUE_APP_BASE_URL
var cense_portal_endpoint = process.env.VUE_APP_CENSE_PORTAL_BASE_URL
var cense_core_endpoint = process.env.VUE_APP_CENSE_CORE_BASE_URL
var cense_models_endpoint = process.env.VUE_APP_CENSE_MODELS_BASE_URL
exports.chat_widget_login = function () {
    return cense_core_endpoint + process.env.VUE_APP_CHAT_WIDGET_LOGIN
}
exports.chat_group_initialize = function () {
    return process.env.VUE_APP_CHAT_GROUP_INITIALIZE
}
exports.chat_group_api = function () {
    return process.env.VUE_APP_LIVE_CHAT_END_POINT + process.env.VUE_APP_CHAT_GROUP_API
}
exports.chatbot_response = function () {
    return cense_core_endpoint + process.env.VUE_APP_CHATBOT_RESPONSE
}
exports.get_widget_theme = function () {
    return cense_portal_endpoint + process.env.VUE_APP_WIDGET_THEME
}
exports.validate_chat_widget = function () {
    return cense_portal_endpoint + process.env.VUE_APP_VALIDATE_CHAT_WIDGET
}
exports.bot_prompt_url = function () {
    return cense_portal_endpoint + process.env.VUE_APP_BOT_PROMPT_URL
}
exports.payment_details_url = function () {
    return cense_portal_endpoint + process.env.VUE_APP_PAYMENT_DETAILS_URL
}
exports.bot_user_data_url = function() {
    return cense_core_endpoint + process.env.VUE_APP_BOT_USER_DATA
}
exports.open_bot_templates_subscription_data = function() {
    return cense_models_endpoint + process.env.VUE_APP_OPEN_BOT_TEMPLATES_SUBSCRIPTION_DATA
}
exports.send_user_product_feedback = function() {
    return cense_models_endpoint + process.env.VUE_APP_USER_PRODUCT_FEEDBACK
}
exports.product_settings = function(){
    return cense_portal_endpoint + process.env.VUE_APP_PRODUCT_SETTINGS
}
exports.outofstockemailnotification = function(){
    return cense_models_endpoint + process.env.VUE_APP_OUT_OF_STOCK_EMAIL_NOTIFICATION
}
exports.product_review_rating = function(){
    return cense_portal_endpoint + process.env.VUE_APP_PRODUCT_REVIEW_RATING
}
exports.display_landing_products = function () {
    return cense_models_endpoint + process.env.VUE_APP_DISPLAY_LANDING_PRODUCTS
}
exports.banner_template = function (){
    return cense_models_endpoint + process.env.VUE_APP_BANNER_TEMPLATE
}
exports.maintainence_check_endpoint = function () {
    return cense_portal_endpoint + process.env.VUE_APP_MAINTENANCE_CHECK
}
exports.save_retail_cart_info = function () {
    return cense_models_endpoint + process.env.VUE_APP_SAVE_CART_INFO
}
exports.get_bot_channel = function () {
    return cense_portal_endpoint + process.env.VUE_APP_BOT_CHANNEL
}
exports.speech_recognition = function  (){
    return cense_portal_endpoint +  process.env.VUE_APP_SPEECH_RECOGNITION
  }

  exports.speech_synthesis = function  (){
    return cense_portal_endpoint + process.env.VUE_APP_SPEECH_SYNTHESIS
  }
// exports.company_info_url = function() {
//     return endpoint + process.env.VUE_APP_COMPANY_INFO
// }