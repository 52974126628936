<template>
  <div
    id="cart-box"
    class="cart-box"
    style="font-family: Helvetica Neue, Helvetica, Arial, sans-serif"
  >
    <div class="cense-cart-header d-flex">
      <p class="bot-font-style" style="margin-top: 10px;">Cart</p>
      <button
        type="button"
        @click="cart_close"
        class="cart-close ml-auto"
        style="padding: 3px !important;"
      >
      <span>&times;</span>
        <!-- <img src="@/assets/images/close-icn.png" alt /> -->
      </button>
    </div>
    <div
      class="cense-cart-body-items bot-font-style"
      style="text-align: center"
      v-if="cart_items.length == 0"
    >
      Your cart is empty.
    </div>
    <div class="cense-cart-body-parent" v-else>
    <div class="cense-cart-body-items">
      <div
        class="col-sm-12 box-item d-flex"
        style="margin-bottom: 20px;"
        v-for="(product, index) in cart_items"
        :key="index"
      >
        <div class="col-sm-3 p-0 product-image" style="width: 100%;">
          <img :src="product.img_url" alt="" style="width: 65px; height: 65px" />
        </div>
        <div class="col-sm-9 ml-1" style="font-size: 14px; padding-right: 0px !important;
    padding-left: 0px !important;">
          <div class="product-name" style="margin-bottom: 10px; width: 100% !important;">
            <div class="d-flex">
              <p class="textMaxLine bot-font-style m-0" :title="product.title">{{ product.title }}</p>
              <span class="delete-cart-item" @click="product.order_qty = 0, update_quantity('manual', index, 0)">
                <svg xmlns="http://www.w3.org/2000/svg" width="13.682" height="15.392" viewBox="0 0 13.682 15.392"><path data-v-0f909d54="" d="M8.276,3v.855H4v1.71h.855V16.682a1.71,1.71,0,0,0,1.71,1.71h8.551a1.71,1.71,0,0,0,1.71-1.71V5.565h.855V3.855H13.406V3H8.276M6.565,5.565h8.551V16.682H6.565V5.565m1.71,1.71v7.7h1.71v-7.7H8.276m3.42,0v7.7h1.71v-7.7Z" transform="translate(-4 -3)" fill="#f23d3d"></path></svg>
              </span>
            </div>
            <p class="textMaxLine bot-font-style m-0" :title="product.variant_title">{{ product.variant_title }}</p>
          </div>
          <div class="bot-font-style" v-if="product.buy_qty === 2 && product.stock_quantity != null" style="color: red">
            Maximum limit {{product.stock_quantity}}
          </div>
          <div
            class="col-sm-12 p-0 row m-0"
            style="height: 30px;"
          >
            <div class="col-sm-6 p-0 product-qty-cart" v-if="Boolean(custom_product_button_key.length)">
              <div v-if="product[custom_product_button_key[0].on_key] == custom_product_button_key[0].on_value">
                <div
                  class="px-0 product-price item-price"
                  style="font-weight: bold; padding-top: 10px; padding-right: 0px !important;
        padding-left: 0px !important;"
                >
                  Quantity : {{product.order_qty}}
                </div>
              </div>
              <div class="product-qty-cart" v-else>
                <button
                  type="button"
                  @click="update_quantity('-', index)"
                  class="quantity-btn minus"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style="height: 14px"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4 7h8v2H4z"></path>
                  </svg>
                </button>
                <input
                  type="number"
                  min="1"
                  class="quantity-btn bot-font-style"
                  style="text-align: center; 
                        width: 40%;
                        border-right: 0; 
                        border-left: 0;"
                  v-model.number="product.order_qty"
                  @focusout="update_quantity('manual', index, product.order_qty)"
                  name="quantity"
                  title="Qty"
                />
                <button
                  type="button"
                  @click="update_quantity('+', index)"
                  class="quantity-btn plus"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    style="height: 14px"
                    viewBox="0 0 16 16"
                  >
                    <path d="M12 7H9V4H7v3H4v2h3v3h2V9h3z"></path>
                  </svg>
                </button>
              </div>
            </div>
            <div v-else class="col-sm-6 p-0 product-qty-cart">
              <button
                type="button"
                @click="update_quantity('-', index)"
                class="quantity-btn minus"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="height: 14px"
                  viewBox="0 0 16 16"
                >
                  <path d="M4 7h8v2H4z"></path>
                </svg>
              </button>
              <input
                type="number"
                min="1"
                class="quantity-btn bot-font-style"
                style="text-align: center; 
                       width: 40%;
                       border-right: 0; 
                       border-left: 0;"
                v-model.number="product.order_qty"
                @focusout="update_quantity('manual', index, product.order_qty)"
                name="quantity"
                title="Qty"
              />
              <button
                type="button"
                @click="update_quantity('+', index)"
                class="quantity-btn plus"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  style="height: 14px"
                  viewBox="0 0 16 16"
                >
                  <path d="M12 7H9V4H7v3H4v2h3v3h2V9h3z"></path>
                </svg>
              </button>
            </div>
            <div
              class="col-sm-6 px-0 product-price item-price"
              style="font-weight: bold; padding-top: 10px; padding-right: 0px !important;
    padding-left: 0px !important;"
            >
              {{retail_currency}} {{(parseInt(product.price) * product.order_qty).toLocaleString()}}.00
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>
    <div v-if="cart_items.length != 0" class="cense-cart-footer">
      <p class="bot-font-style" style="float: left; height: 20px; padding-top: 2px;">SUBTOTAL :</p>
      <p class="bot-font-style ml-auto" style="font-size: 14px; float: right;">{{retail_currency}} {{cart_total.toLocaleString()}}.00</p>
      <p class="bot-font-style" 
      style="font-size: 11px;
                clear: both;
                padding-top: 10px;
                text-align: center;
                color: #4c4c4c;">
        Shipping and discount codes are added at checkout.</p>
        <button
          type="button"
          class="checkout-btn bot-font-style"
          @click="push_to_checkout"
        >
          CHECKOUT
        </button>
    </div>
  </div>
</template>

<script>
import jQuery from "jquery";
const $ = jQuery;
export default {
  name: "CenseCart",
  data() {
    return {
      empty_cart: false,
      cart_items: [],
      cart_total: "",
      over_qty_warning: false,
      custom_product_button_key: this.$session.get("session_custom_product_button_key"),
    };
  },
  props: ["addtoCart", "retail_currency", "retail_framework", "shop_url"],
  watch: {
    addtoCart: {
      immediate: true,
      handler(newValue) {
        var total = 0;
        this.cart_items = [];
        for (var i in newValue) {
          this.cart_items.push(newValue[i]);
          //below logic might be changed
          this.cart_items[i].order_qty == 0
            ? (this.cart_items[i].order_qty = 1)
            : this.cart_items[i].order_qty;
          var j = this.cart_items[i].order_qty;
          total = total + j * this.cart_items[i].price;
        }
        this.cart_total = total;
      },
    },
  },
  mounted() {
    const vm = this;
  },
  methods: {
    trim_product_name(val1) {
      if (val1.length > 65) {
        let str1 = val1.substring(0, 65) + "...";
        return str1;
      }
      return val1;
    },
    push_to_checkout() {
      // Make Domain name dynamic
      if(this.retail_framework === "woocommerce") {
        let temp_str = '';
        for (var i in this.cart_items) {
          var p_id = this.cart_items[i].id;
          var customqty = this.cart_items[i].order_qty;
          // $.get(this.shop_url +
          //   "/?add-to-cart=" +
          //     p_id +
          //     "&quantity=" +
          //     customqty
          //     // , function(response){
          //     //   
          //     //   // console.log(count, this.cart_items.length);
          //     // }
          // );
          while (customqty != 0) {
            temp_str += `${p_id},`;
            customqty -= 1;
          }
        }
        window.open(`${this.shop_url}cart/?add-to-cart=${temp_str}`);
      } else if(this.retail_framework === "shopify") {
        let temp_str = ''
        for (var i in this.cart_items) {
          var p_id = this.cart_items[i].id;
          var customqty = this.cart_items[i].order_qty;
          temp_str += `${p_id}:${customqty},`
        }
        window.open(`https://${this.shop_url}.myshopify.com/cart/${temp_str}`);
      } else if(this.retail_framework === "magento") {
        let temp_str = ''
        for (var i in this.cart_items) {
          var p_id = this.cart_items[i].id;
          var customqty = this.cart_items[i].order_qty;
          while (customqty != 0) {
              if ((this.cart_items.length - 1) == i && customqty == 1){
                temp_str += `${p_id}`;
              }else{
                temp_str += `${p_id},`;
              }
              customqty -= 1;
            }
        }
        window.open(`${this.shop_url}censeaiinccense/index/save/s_id/${temp_str}`);
      } else if (this.retail_framework === "bigcommerce") {
        for(var i in this.cart_items){
        // add product id 123
          var sku = this.cart_items[i].sku;
          var customqty = this.cart_items[i].order_qty;
          var temp = this.shop_url + '/cart.php?action=add&sku=' + sku;
          while (customqty != 0) {
            console.log(temp);
            $.get(`${this.shop_url}/cart.php?action=add&sku=` + sku, function(data, status){
              // console.log(data,status);
            });
            customqty -= 1;
          }
        }
        return window.location = "/cart.php";
      }
    },
    update_quantity(func, index, value) {
      if (func == "+") {
        if (this.cart_items[index].stock_quantity > this.cart_items[index].order_qty){
          this.cart_items[index].buy_qty = 1;
          this.cart_items[index].order_qty += 1;
          this.change_shopify_website_cart_product_qty(this.cart_items[index].id, this.cart_items[index].order_qty);
        }
        else if(this.cart_items[index].stock_quantity == null || (this.cart_items[index].stock_quantity === 0 && this.cart_items[index].stock_status === 'instock')){
          this.cart_items[index].order_qty += 1;
          this.change_shopify_website_cart_product_qty(this.cart_items[index].id, this.cart_items[index].order_qty);
        }else {
          this.cart_items[index].buy_qty = 2;
        }  
      }
      if (func == "-") {
        this.cart_items[index].buy_qty = 1;
        if (this.cart_items[index].order_qty > 0) {
          if (this.cart_items[index].order_qty > this.cart_items[index].stock_quantity && this.cart_items[index].stock_quantity != null &&
          (this.cart_items[index].stock_quantity !== 0 && this.cart_items[index].stock_status === 'instock')){
            this.cart_items[index].order_qty = 1;
            this.change_shopify_website_cart_product_qty(this.cart_items[index].id, this.cart_items[index].order_qty);
          }else{
            this.cart_items[index].order_qty -= 1;
            this.change_shopify_website_cart_product_qty(this.cart_items[index].id, this.cart_items[index].order_qty);
          }
          if (this.cart_items[index].order_qty == 0) {
            this.change_shopify_website_cart_product_qty(this.cart_items[index].id, this.cart_items[index].order_qty);
            this.cart_items.splice(index, 1);
          }
        } else {
          this.change_shopify_website_cart_product_qty(this.cart_items[index].id, this.cart_items[index].order_qty);
          this.cart_items.splice(index, 1);
        }     
      }
      if (func == "manual") {
        var quantity = Math.abs(parseInt(value));
        this.cart_items[index].buy_qty = 1;
        if (quantity){
          if (
            this.cart_items[index].stock_quantity >= quantity  || (this.cart_items[index].stock_quantity === 0 && this.cart_items[index].stock_status === 'instock')
            ){
            this.cart_items[index].order_qty = quantity;
            this.change_shopify_website_cart_product_qty(this.cart_items[index].id , this.cart_items[index].order_qty);
          }
          else if(this.cart_items[index].stock_quantity == null){
            this.cart_items[index].order_qty = quantity;
            this.change_shopify_website_cart_product_qty(this.cart_items[index].id , this.cart_items[index].order_qty);
          }
          else{
            this.cart_items[index].buy_qty = 2;
            this.cart_items[index].order_qty = this.cart_items[index].stock_quantity;
            this.change_shopify_website_cart_product_qty(this.cart_items[index].id , this.cart_items[index].order_qty);
          }
        }
      }
      for (var i in this.cart_items) {
        if (this.cart_items[i].order_qty == 0) {
          this.change_shopify_website_cart_product_qty(this.cart_items[i].id , this.cart_items[i].order_qty);
          this.cart_items.splice(i, 1);
        }
      }
      
      if(!this.over_qty_warning){
        var total = 0;
        for (var i in this.cart_items) {
          var j = this.cart_items[i].order_qty;
          total = total + j * this.cart_items[i].price;
        }
        this.cart_total = total;
        this.cart_communication();
      }
    },
    cart_close() {
      this.$emit("close_cart", false);
    },
    cart_communication() {
      this.$emit("cart_data", this.cart_items);
      this.$session.set("store_cart_data", this.cart_items);
    },
    change_shopify_website_cart_product_qty(variant_id, qty){
      if(this.retail_framework === "shopify"){
        (async () => { 
          var change_cart_data = await jQuery.ajax({
            url:'/cart/change.js',
            type:'post',
            dataType:'json',
            data:{
              id: variant_id,
              quantity: qty,
              sections: "cart-items,cart-icon-bubble,cart-live-region-text,cart-footer",
              sections_url: "/cart"
            }
          });
          // var cartContents = await change_cart_data;
          // console.log('change_content', cartContents);
          // if(Boolean(cartContents.items)){
          //   console.log('cart_items', this.cart_items);
          //   this.cart_items = [];
          //   this.cart_items =  cartContents.items.map(function(item){
          //     return {
          //       buy_qty:1, 
          //       id:item.id, 
          //       img_url:item.image, 
          //       order_qty:item.quantity, 
          //       price:(item.final_price/100).toString(), 
          //       stock_quantity:null, 
          //       stock_status: "instock", 
          //       title:item.title, 
          //       variant_title:item.variant_title
          //     }
          //   });
          //   console.log('cart_items1', this.cart_items);
          //   this.cart_communication();
          // }
        })();
      }
    },
  },
};
</script>

<style scoped>
.delete-cart-item {
  margin-left: auto !important;
  cursor: pointer;
}
.delete-cart-item svg :hover {
  color: #f23d3d !important;
  fill: #f23d3d !important;
}
.product-name {
  display: inline-block;
  box-sizing: border-box;
  white-space: pre-line;
  word-wrap: break-word;
}
.product-name .textMaxLine {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
}
/* .cart-box {
    height: 30px;
    width: 30px;
    background-color: blue;
} */
.cart-close {
  font: 400 35px Arial;
  border: none;
  color: inherit;
  background-color: #fff;
  cursor:pointer !important
}
.cart-close:focus {
  outline: none;
}
.cart-close:hover {
  transform: scale(1.2);
  color: #4c4c4c !important;
}
p {
  margin: 0;
  padding: 0;
}
.checkout-btn {
  background-color: var(--bot-button-background) !important;
  border-color: var(--bot-button-border) !important;
  color: var(--bot-font-color-buttons) !important;
  border-radius: 0.25rem;
  padding: 10px 5px;
  letter-spacing: 0.3px;
  font: 400 16px sans-serif;
  border-style: solid;
  border-width: 1px;
  /* border: 0; */
  clear: both;
  margin-top: 15px;
  width: 100%;
  cursor: pointer !important;
}
.checkout-btn:hover {
  background-color: var(--bot-button-background) !important;
  border-color: var(--bot-button-border) !important;
  cursor: pointer;
}
.plus {
  border-radius: 0px 3px 3px 0px;
  border: 1px solid #767676;
  border-left: 0px;
}
.minus {
  border-radius: 3px 0px 0px 3px;
  border: 1px solid #767676;
  border-right: 0px;
}
.plus,
.minus {
  width: 25%;
  background-color: #ffffff;
  cursor: pointer;
}
.product-qty-cart{
  display: inline-flex !important;
}
.quantity-btn {
  font-size: 18px;
  border: 1px solid #767676;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  vertical-align: bottom;
}
.cense-cart-header {
  /* height: 10%; */
  padding: 10px;
  font-size: 18px;
  color: #767676;
  font-weight: normal;
  z-index: 2147483647;
  position: relative;
}
.cense-cart-body-parent {
  padding: 70px 0 135px 0;
  position: absolute;
  top: 0;
  height: -webkit-fill-available;
  width: 100%;
}
.cense-cart-body-items {
  font-size: 14px;
  line-height: 1.2;
  padding: 0px 18px;
  color: #4c4c4c;
  font-weight: normal;
  height: 100%;
  position: relative;
  /* overflow-x: auto; */
  overflow: hidden;
  overflow-y: auto;
}
.cense-cart-footer {
  bottom: 0;
  position: absolute;
  width: -webkit-fill-available;
  padding: 15px 20px 20px 20px;
  font-size: 11px;
  justify-content: space-between;
  text-align: center;
}
.cart-box {
  top: 0;
  width: 76%;
  margin-left: 24%;
  height: 100%;
  background-color: #fff;
  /* box-shadow: 0px 0px 10px rgba(73, 74, 78, 0.1); */
  border-radius: 5px;
  -webkit-box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
  box-shadow: -5px 0 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  position: absolute;
  z-index: 2147483647;
  display: block !important;
}
.box-item {
  width: 100%;
  height: 100px;
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 0 0;
  margin-top: 0.3em;
}
</style>