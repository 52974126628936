import Vue from 'vue'
import App from './App.vue'
import 'bootstrap'
import axios from 'axios'
import api_calls from './api_calls'
import vueCustomElement from 'vue-custom-element';
import VueSession from 'vue-session'
import('./assets/bootstrap.min.css')
Vue.use(vueCustomElement)
Vue.use(VueSession, {
  persist: true
})
export const eventBus = new Vue({})
Vue.config.productionTip = false
Vue.customElement('bot-chat', App)

if (process.env.NODE_ENV == 'development') {
  let license_key = 'Indvb3Rlc3QxMjc2NzJXb29UZXN0MXdvb3Rlc3QxLmNvbTIwMjEtMDgtMDkgMTQ6MjM6MjEuODA5MTg2Ig.YRE6WQ.0-WJnBBkKPyyfK5Ujgkjs8nXSgk';
  if (Boolean(license_key) === true){
    eventBus.$emit('New_Widget_parameters', {
      license_key: license_key,
      username: 'lawuser',
      source: 'Web',
      is_app_open: false
    })
  } else {
    eventBus.$emit('Wrong_Widget_parameters', {})
  }
} else if (process.env.NODE_ENV == 'production') {
  window.GenerateWidget = function (company_details) {
    axios.post(api_calls.validate_chat_widget(), {
      license_key: company_details.license_key
    }).then(response => {
      if (response.data.message.MSG_CODE === 10000631) {
        eventBus.$emit('New_Widget_parameters', company_details)
      } else {
        console.log("Event Emit");
        eventBus.$emit('Wrong_Widget_parameters', company_details)
        console.log(response.data.message.MSG)
      }
    })
  }


  /* New Function is added for specifically apps coming from the Shopify App Store, and the main difference 
  is that it returns a boolean true value. */
  window.GenerateShopifyWidget = function (company_details) {
    axios.post(api_calls.validate_chat_widget(), {
      license_key: company_details.license_key
    }).then(response => {
      if (response.data.message.MSG_CODE === 10000631) {
        eventBus.$emit('New_Widget_parameters', company_details)
      } else {
        eventBus.$emit('Wrong_Widget_parameters', company_details)
        console.log(response.data.message.MSG)
      }
    })
    return true;
  }
}
